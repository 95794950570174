import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { LoginState, loginReducer as login } from "./loginReducer";
import { UserData } from "../actions/authentificationActions";
import { userReducer as user } from "./userReducer";
import { sharepointSettingsReducer as sharepointSettings } from "./sharepointSettingsReducer";
import { toastMessageReducer as toastMessage, ToastMessageState } from "./toastMessageReducer";
import {
    nationalitiesReducer as nationalities,
    CountriesAndNationalitiesState,
} from "./nationalitiesReducer";
import {
    unreadMessagesReducer as unreadMessages,
    UnreadMessagesState,
} from "./unreadMessagesReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/root";
import { ISharepointSettings } from "../models/sharepointSettings";

export interface AppState {
    login: LoginState;
    user: UserData;
    toastMessage: ToastMessageState;
    nationalities: CountriesAndNationalitiesState;
    unreadMessages: UnreadMessagesState;
    sharepointSettings: ISharepointSettings;
}

export default function configureStore(initialState?: AppState) {
    const rootReducer = combineReducers<AppState>({
        login,
        user,
        toastMessage,
        nationalities,
        unreadMessages,
        sharepointSettings
    });

    const sagaMiddleware = createSagaMiddleware();

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const result = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(sagaMiddleware), ...enhancers)
    );

    sagaMiddleware.run(rootSaga);

    return result;
}
