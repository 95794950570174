import { ChangeEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Icon, Input, InputOnChangeData, Label, Modal, Select } from "semantic-ui-react";
import { toast } from "../..";
import { agent } from "../../api/agent";
import { TranslateProps } from "../../i18n/TranslateProps";
import {
    AuthorizationType,
    authorizationTypeOptions,
    IAuthorization,
    newAuthorizationInit,
} from "../../models/authorization";
import { ICertificate } from "../../models/certificate";
import { ICrewMember } from "../../models/crewMember";
import { AppState } from "../../store/configureStore";
import { ucqRiverRangeProps } from "../../models/riverRanges";

type Props = {
    open: boolean;
    close: () => void;
    activeAuthorizations: IAuthorization[];
    targetCertificate: ICertificate;
    crewMember: ICrewMember;
    fetchCrewMember: () => Promise<void>;
};

export const AddAuthorizationsForm = ({
    open,
    close,
    activeAuthorizations,
    targetCertificate,
    crewMember,
    fetchCrewMember,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [newAuth, setNewAuth] = useState(newAuthorizationInit);
    const [loading, setLoading] = useState(false);
    const stretchRegex: RegExp = /^[A-Z][A-Z-]-\d\d\d\d-\d\d\d\d$/;
    const [invalidStretch, setInvalidStretch] = useState(false);
    const [manualStretches, setManualStretches] = useState<string>("");

    const availableAuths = authorizationTypeOptions.filter(
        x => !activeAuthorizations.some(a => a.type === x.value)
    );
    const availableRiverKilometers = ucqRiverRangeProps.filter(
        r =>
            !targetCertificate.stretchesRiverKilometers.includes(r.value as number) &&
            !activeAuthorizations.flatMap(a => a.ucqRiverKilometers).includes(r.value as number)
    );
    if (
        availableRiverKilometers.length > 0 &&
        !availableAuths.some(a => a.value === AuthorizationType.S)
    )
        availableAuths.push({
            key: AuthorizationType.S,
            text: "sailingStretches",
            value: AuthorizationType.S,
            code: "S",
        });

    const handleAddAuthorization = async () => {
        setLoading(true);
        try {
            newAuth.linkedQualificationID = targetCertificate.qualificationID;
            newAuth.issuingAuthority = targetCertificate.issuingAuthority;
            newAuth.startDate = targetCertificate.startDate;
            newAuth.endDate = targetCertificate.endDate;
            newAuth.status = targetCertificate.status;
            newAuth.crewMemberGuid = crewMember.guid;
            newAuth.ucqManualRiverKilometers = manualStretches;
            await agent.Certificates.addAuth(newAuth);
            toast(intl.formatMessage({ id: "authorizationAddedMsg" }), true);
            fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "authorizationAddFail" }), false);
        } finally {
            setLoading(false);
            close();
        }
    };

    useEffect(() => {
        setNewAuth(a => ({ ...a, issuingAuthority: user.authority }));
    }, [user.authority]);

    const validateStretch = (stretch: string) => {
        if (stretchRegex.exec(stretch)) {
            setInvalidStretch(false);
        }
    };

    const handleAddManualRanges = (e: any) => {
        if (e.key === "Enter" || e.charCode === 13) {
            setInvalidStretch(false);
            if (stretchRegex.exec(e.target.value)) {
                setManualStretches(`${manualStretches}${e.target.value};`);
                setNewAuth({
                    ...newAuth,
                    ucqManualRiverKilometers:
                        newAuth.type === AuthorizationType.S
                            ? `${manualStretches}${e.target.value};`
                            : "",
                });
            } else {
                setInvalidStretch(true);
            }
        }
    };

    const handleRemoveStretch = (s: string) => {
        setManualStretches(manualStretches.replace(`${s};`, ""));
        setNewAuth({
            ...newAuth,
            ucqManualRiverKilometers:
                newAuth.type === AuthorizationType.S ? manualStretches.replace(`${s};`, "") : "",
        });
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                close();
                setNewAuth(newAuthorizationInit);
            }}
            dimmer="blurring"
            size="small"
            closeOnDimmerClick={!loading}
        >
            <Modal.Header>{intl.formatMessage({ id: "addAuthorization" })}</Modal.Header>
            <Modal.Content>
                <form className="add-authorization-form">
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "authorizationType" })}</label>
                        <Select
                            search
                            options={TranslateProps(intl, availableAuths)}
                            value={newAuth.type}
                            onChange={(e, data: any) =>
                                setNewAuth(a => ({ ...a, type: +data.value }))
                            }
                        />
                    </section>
                    {newAuth.type === AuthorizationType.S && (
                        <>
                            <section className="input-container">
                                <label>{intl.formatMessage({ id: "range" })}</label>
                                <Select
                                    search
                                    multiple
                                    options={availableRiverKilometers}
                                    value={newAuth.ucqRiverKilometers}
                                    onChange={(e, data: any) =>
                                        setNewAuth({
                                            ...newAuth,
                                            ucqRiverKilometers: data.value,
                                        })
                                    }
                                />
                            </section>

                            <section className="input-container">
                                <label>{intl.formatMessage({ id: "manualAddSections" })}</label>
                                <Input
                                    className={invalidStretch ? "invalidStretch" : ""}
                                    onChange={(
                                        _e: ChangeEvent<HTMLInputElement>,
                                        data: InputOnChangeData
                                    ) => {
                                        validateStretch(data.value);
                                    }}
                                    onKeyPress={(e: any) => {
                                        handleAddManualRanges(e);
                                    }}
                                />
                                {manualStretches
                                    .split(";")
                                    // remove last element (empty)
                                    .slice(0, -1)
                                    .map((s, i) => (
                                        <Label as="a" key={i}>
                                            {s}
                                            <Icon
                                                name="delete"
                                                onClick={() => handleRemoveStretch(s)}
                                            />
                                        </Label>
                                    ))}
                                {invalidStretch && (
                                    <label className="invalidLabel">
                                        {intl.formatMessage({ id: "wrongStretchCodeFormat" })}
                                    </label>
                                )}
                            </section>
                        </>
                    )}
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={() => handleAddAuthorization()}
                    content={intl.formatMessage({ id: "add" })}
                    fluid
                    loading={loading}
                    disabled={
                        loading ||
                        !targetCertificate ||
                        newAuth.type < 0 ||
                        (newAuth.type === AuthorizationType.S &&
                            manualStretches.length === 0 &&
                            newAuth.ucqRiverKilometers.length === 0)
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
