import { useState } from "react";
import { Placeholder } from "semantic-ui-react";
import defaultUser from "../../images/default_user.png";

type Props = {
    imageBase64: string;
};

export const CrewMemberImage = ({ imageBase64 }: Props) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            <Placeholder style={loaded ? { display: "none" } : {}}>
                <Placeholder.Image />
            </Placeholder>
            <img
                src={`data:image/jpg;base64,${imageBase64}`}
                alt="User"
                style={loaded ? {} : { display: "none" }}
                onLoad={() => setLoaded(true)}
                onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = defaultUser;
                }}
            />
        </>
    );
};
