import { Icon, Popup } from "semantic-ui-react";
import { MessageStatus } from "../../models/common";
import { IRequestFeedback } from "../../models/requestFeedback";

type Props = {
    feedbacks: IRequestFeedback[];
    left?: boolean;
};

export const RequestFeedbackIndicator = ({ feedbacks, left }: Props) => {
    const sorted = feedbacks.sort(
        (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );
    const lastFeedback = sorted[0];
    const message =
        `Last request: ${lastFeedback.type}; RequestId: ${lastFeedback.requestId}` +
        (!!lastFeedback.feedback ? `; Feedback: ${lastFeedback.feedback}` : "");

    return (
        <div className="request-indicator">
            <Popup
                content={message}
                position={left ? "left center" : "top right"}
                flowing
                trigger={<Icon name={getIcon(lastFeedback)} size="big" />}
                hoverable
            />
        </div>
    );
};

const getIcon = (lastFeedback: IRequestFeedback) => {
    if (lastFeedback.status === MessageStatus.Failure) return "times circle";
    else if (lastFeedback.status === MessageStatus.Unprocessed) return "clock";
    else return "check circle";
};
