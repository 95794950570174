import {
    boatmasterLicenceOptions,
    BoatmasterLicenceType,
    CertificateType,
    ICertificate,
} from "../../../models/certificate";
import { getLatinNonLatinCombined, IIDDataSet } from "../../../models/crewMember";
import euFlag from "../../../images/Flag_of_Europe.svg.png";
import ccnrLogo from "../../../images/CCNR-logo.png";
import { CrewMemberImage } from "../../CrewMember/CrewMemberImage";
import { AuthorizationType, authorizationTypeOptions } from "../../../models/authorization";
import { TranslateProps } from "../../../i18n/TranslateProps";
import { formatDate, Gender } from "../../../models/common";
import { ucqRiverRangeProps } from "../../../models/riverRanges";
import { useIntl } from "react-intl";
import "./EuropeanCertificateView.css";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    modifyable: boolean;
    qrBase64: string;
};

export const EuropeanCertificateView = ({
    certificate,
    idDataSet,
    modifyable,
    qrBase64,
}: Props) => {
    const intl = useIntl();
    const lngOrPassenger =
        certificate.type === CertificateType.UcqLngExpert ||
        certificate.type === CertificateType.UcqPassengerNavigationExpert;
    const issuingCountry = certificate.issuingAuthority.includes("AT")
        ? "REPUBLIK ÖSTERREICH"
        : certificate.issuingAuthority.includes("CH")
        ? "SCHWEIZERISCHE EIDGENOSSENSCHAFT"
        : certificate.issuingAuthority;

    const getCertificateType = () => {
        switch (certificate.type) {
            case CertificateType.UcqBoatmaster:
                return `${
                    boatmasterLicenceOptions.find(
                        x => x.value === certificate.boatmasterLicenceType
                    )?.content ?? ""
                }${certificate.gender === Gender.Female ? "in" : ""}`;
            case CertificateType.UcqLngExpert:
                return "LNG-Sachkundige";
            default:
                return "Sachkundige für die Fahrgastschifffahrt";
        }
    };

    return (
        <div className={`ucq-frame ${lngOrPassenger ? "LNG-frame" : ""}`}>
            <p style={{ paddingLeft: "5px", fontSize: "11px", fontWeight: "bold" }}>
                {issuingCountry}
            </p>
            <div className="headlineWithFlag">
                <div>
                    {certificate.boatmasterLicenceType ===
                        BoatmasterLicenceType.BoatmasterLicence && (
                        <p
                            style={{
                                fontSize: "12px",
                                marginBottom: "0px",
                                fontWeight: "bold",
                                paddingTop: "3px",
                            }}
                        >
                            BEFÄHIGUNGSZEUGNIS{" "}
                            {certificate.issuingAuthority !== "CH01" && "DER EUROPÄISCHEN UNION"}{" "}
                            FÜR DIE BINNENSCHIFFFAHRT
                        </p>
                    )}
                    <p style={{ fontWeight: "bold" }}>{getCertificateType()}</p>
                </div>
                <img
                    className={`${
                        lngOrPassenger
                            ? "LNG-img"
                            : certificate.issuingAuthority === "CH01"
                            ? "CCNR-img"
                            : "EU-img"
                    }`}
                    alt="Flag"
                    src={certificate.issuingAuthority === "CH01" ? ccnrLogo : euFlag}
                    style={{
                        marginLeft: "auto",
                        maxWidth: "100px",
                        maxHeight: "100px",
                        width: "auto",
                        height: "auto",
                    }}
                ></img>
            </div>
            <div className="middle">
                <div className="middleLeft">
                    <p style={{ paddingTop: "50px", marginBottom: "0px" }}>
                        1. &nbsp;&nbsp;{" "}
                        {getLatinNonLatinCombined(idDataSet.nonLatinLastName, idDataSet.lastName)}
                    </p>
                    <p className="position">
                        2. &nbsp;&nbsp;{" "}
                        {getLatinNonLatinCombined(idDataSet.nonLatinFirstName, idDataSet.firstName)}
                    </p>
                    <div className={`${lngOrPassenger ? "LNG-flex" : ""}`}>
                        <p className="position">3a. &nbsp; {formatDate(idDataSet.dateOfBirth)}</p>
                        <p className={`position ${lngOrPassenger ? "move-right" : ""}`}>
                            3b. &nbsp;{" "}
                            {getLatinNonLatinCombined(
                                idDataSet.nonLatinPlaceOfBirth,
                                idDataSet.placeOfBirth
                            )}
                        </p>
                    </div>
                    <p style={{ paddingBottom: "40px" }}>4. &nbsp;&nbsp; {idDataSet.cid}</p>
                </div>
                <div className={`photo ${lngOrPassenger ? "LNG-photo" : ""}`}>
                    <CrewMemberImage imageBase64={idDataSet.imageBase64} />
                    {lngOrPassenger ? (
                        <p className="LNG-sixth-position">
                            <span>
                                6.&nbsp;&nbsp;
                                {modifyable ? <em>Wird zugeteilt</em> : certificate.qualificationID}
                            </span>
                        </p>
                    ) : null}
                </div>
            </div>
            {certificate.type === CertificateType.UcqBoatmaster && (
                <p className="position">
                    <span>
                        6.&nbsp;&nbsp;
                        {modifyable ? <em>Wird zugeteilt</em> : certificate.qualificationID}
                    </span>
                </p>
            )}
            <div className={`${lngOrPassenger ? "LNG-flex" : ""}`}>
                <p className="position">7. &nbsp;&nbsp; {formatDate(certificate.startDate)}</p>
                <p className={`position ${lngOrPassenger ? "move-right" : ""}`}>
                    8. &nbsp;&nbsp; {formatDate(certificate.endDate)}
                </p>
            </div>
            <p>9. &nbsp;&nbsp; {certificate.issuingAuthority}</p>
            <div className={`${lngOrPassenger ? "none" : ""}`}>
                <span>
                    10. &nbsp;&nbsp;{" "}
                    {certificate.authorizations
                        .sort((a, b) => a.type - b.type)
                        .map(a => {
                            return (
                                TranslateProps(intl, authorizationTypeOptions).find(
                                    x => x.value === a.type
                                )?.code ?? ""
                            );
                        })
                        .filter(x => x !== "S")
                        .join(", ")}
                </span>
                <br />
                {certificate.authorizations.some(a => a.type === AuthorizationType.S) ? (
                    <>
                        <span>
                            {constructRiverKilometersWithAuthorization(certificate).map(x => (
                                <span key={x}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {x}
                                    <br />
                                </span>
                            ))}
                        </span>
                        <p></p>
                    </>
                ) : (
                    <p></p>
                )}
                <p style={{ paddingBottom: "50px" }}>
                    11. {writeUcqCertificateRestrictions(certificate)}
                </p>
            </div>
            <div className="certificate-qrcode-container">
                <img src={`data:image/jpg;base64,${qrBase64}`} alt="2D-Barcode" />
            </div>
        </div>
    );
};

export const writeUcqCertificateRestrictions = (certificate: ICertificate): string => {
    const resultArray: string[] = [];
    certificate.restrictions
        .sort((a, b) => a - b)
        .forEach(r => {
            let rest = r.toString().padStart(2, "0");
            if (r === 7) rest += " " + certificate.limitedToOneCraftDescription;
            else if (r === 8) rest += " " + certificate.limitedAreaDescription;
            else if (r === 9) rest += " " + certificate.limitedTaskDescription;
            resultArray.push(rest);
        });
    if (!!certificate.restriction96) resultArray.push(certificate.restriction96);
    return resultArray.join(", ");
};

export const constructRiverKilometersWithAuthorization = (certificate: ICertificate) => {
    const resultArray: string[] = [];
    certificate.authorizations
        .filter(a => a.type !== AuthorizationType.S)
        .forEach(a => {
            resultArray.push(AuthorizationType[a.type]);
        });
    // enum strecthes
    certificate.authorizations
        .filter(a => a.type === AuthorizationType.S)
        .filter(a => a.ucqRiverKilometers.length > 0)
        .forEach(a => {
            const riverKilometers = a.ucqRiverKilometers.flatMap(
                r => ucqRiverRangeProps.find(x => x.value === r)?.text as string
            );
            riverKilometers.forEach(rk => resultArray.push(`${rk}`));
        });
    // manual stretches
    certificate.authorizations
        .filter(a => a.type === AuthorizationType.S)
        .filter(a => !!a.ucqManualRiverKilometers)
        .forEach(a => {
            const manualStretchesArray = a.ucqManualRiverKilometers
                .split(";")
                // remove last empty string if there is more than one stretch
                .slice(0, a.ucqManualRiverKilometers.includes(";") ? -1 : 0);
            manualStretchesArray.forEach(ms => ms && resultArray.push(`${ms}`));
        });
    return resultArray;
};
