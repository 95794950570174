import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Icon, Input, Modal, Select } from "semantic-ui-react";
import { DateInput } from "../../components/DateInput/DateInput";
import { TranslateProps } from "../../i18n/TranslateProps";
import { dateInit, deconstructDate } from "../../models/common";
import { ISRB } from "../../models/srb";
import { ISRBFunction, srbFunctionOptions } from "../../models/srbFunction";
import { constructDate } from "../../models/common";

type Props = {
    srbFunction: ISRBFunction;
    setNewSrb: Dispatch<SetStateAction<ISRB>>;
    deleteSrbFunction: () => void;
};

export const SRBFunctionForm = ({ srbFunction, setNewSrb, deleteSrbFunction }: Props) => {
    const intl = useIntl();
    const [validFrom, setValidFrom] = useState(
        !!srbFunction.validFrom && deconstructDate(srbFunction.validFrom).year > 1700 // placeholder value for import
            ? deconstructDate(srbFunction.validFrom)
            : dateInit
    );
    const [dateOfIssue, setDateOfIssue] = useState(
        !!srbFunction.dateOfIssue && deconstructDate(srbFunction.dateOfIssue).year > 1700 // placeholder value for import
            ? deconstructDate(srbFunction.dateOfIssue)
            : dateInit
    );
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleStatusChange = (data: any) => {
        setNewSrb(prev => {
            const newState = [...prev.functions];
            const index = newState.indexOf(srbFunction);
            if (index > -1) newState[index].functionType = data.value;
            return { ...prev, functions: newState };
        });
    };

    const handlePlaceOfIssueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewSrb(prev => {
            const newState = [...prev.functions];
            const index = newState.indexOf(srbFunction);
            if (index > -1) newState[index].placeOfIssue = e.target.value;
            return { ...prev, functions: newState };
        });
    };

    useEffect(() => {
        setNewSrb(prev => {
            const newState = [...prev.functions];
            const index = newState.indexOf(srbFunction);
            if (index > -1) newState[index].validFrom = constructDate(validFrom);
            if (index > -1) newState[index].dateOfIssue = constructDate(dateOfIssue);
            return { ...prev, functions: newState };
        });
    }, [setNewSrb, srbFunction, validFrom, dateOfIssue]);

    return (
        <>
            <div className="srb-function-row">
                <section>
                    <div className="form-row two">
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "function" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                selectOnBlur={false}
                                search
                                options={TranslateProps(intl, srbFunctionOptions)}
                                value={srbFunction.functionType}
                                onChange={(e, data: any) => handleStatusChange(data)}
                            />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "validFrom" })}</label>
                            <DateInput date={validFrom} setDate={setValidFrom} clearable />
                        </section>
                    </div>
                    <div className="form-row two">
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "issueDate" })}</label>
                            <DateInput date={dateOfIssue} setDate={setDateOfIssue} clearable />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "placeOfIssue" })}</label>
                            <Input
                                value={srbFunction.placeOfIssue ?? ""}
                                onChange={handlePlaceOfIssueChange}
                            />
                        </section>
                    </div>
                </section>
                <Icon name="close" link onClick={() => setDeleteModalOpen(true)} />
            </div>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                dimmer="blurring"
                size="tiny"
            >
                <Modal.Header>
                    {intl.formatMessage({ id: "areYouSureDeleteSrbFunction" })}
                </Modal.Header>
                <Modal.Actions>
                    <Button
                        negative
                        onClick={() => setDeleteModalOpen(false)}
                        content={intl.formatMessage({ id: "cancel" })}
                    />
                    <Button
                        primary
                        onClick={() => {
                            deleteSrbFunction();
                            setDeleteModalOpen(false);
                        }}
                        content={intl.formatMessage({ id: "confirm" })}
                    ></Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
