import { BoatmasterLicenceType, ICertificate } from "../../../models/certificate";
import { IIDDataSet } from "../../../models/crewMember";
import { formatDateForCertificateView } from "../../../models/common";
import background_1 from "../../../images/CH_patent_card_2023_background_page-0001.jpg";
import background_2 from "../../../images/CH_patent_card_2023_background_page-0002.jpg";
import {
    constructRiverKilometersWithAuthorization,
    writeUcqCertificateRestrictions,
} from "./EuropeanCertificateView";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    imageBase64: string;
    qrBase64: string;
};

export const CCNRBoatmasterView = ({ certificate, idDataSet, imageBase64, qrBase64 }: Props) => {
    const getLicenceType = () => {
        switch (certificate.boatmasterLicenceType) {
            case BoatmasterLicenceType.BoatmasterLicence:
                return "Schiffsführer";
            case BoatmasterLicenceType.GovernmentLicence:
                return "Behördenpatent";
            case BoatmasterLicenceType.SportLicence:
                return "Sportpatent";
            default:
                return "";
        }
    };

    const getUserImageSrc = () => {
        const src = `data:image/jpg;base64,${imageBase64}`;
        return src;
    };

    const getAuthorizationsArrays = () => {
        const authorizationArray = constructRiverKilometersWithAuthorization(certificate);
        const authorizationString = authorizationArray.join(", ");
        if (authorizationString.length <= 35) {
            return [authorizationString, "- - -"];
        }
        const index = authorizationString.lastIndexOf(", ", 35);
        const firstArray = authorizationString.substring(0, index);
        const secondArray = authorizationString.substring(index + 2);
        return [firstArray, secondArray];
    };

    const getRestricionsArray = () => {
        const restrictionsString = writeUcqCertificateRestrictions(certificate);
        if (restrictionsString.length <= 35) {
            return [restrictionsString, "- - -"];
        }
        const index = restrictionsString.lastIndexOf(", ", 35);
        const firstArray = restrictionsString.substring(0, index);
        const secondArray = restrictionsString.substring(index + 2);
        return [firstArray, secondArray];
    };

    return (
        <div id="ccnr-boatmaster-view">
            <section
                style={{
                    fontSize: "8.5px",
                    width: "86.5mm",
                    height: "54mm",
                    maxWidth: "86.5mm",
                    maxHeight: "54mm",
                    fontFamily: "Barlow",
                    backgroundImage: `url(${background_1})`,
                    backgroundSize: "cover",
                    border: "0.5px dashed black",
                    lineHeight: "1",
                }}
            >
                <div style={{ display: "flex", marginLeft: "10px", marginTop: "10px" }}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                fontFamily: "Barlow Medium",
                            }}
                        >
                            <span style={{ marginTop: "50px" }}>
                                {certificate.boatmasterLicenceType ===
                                BoatmasterLicenceType.BoatmasterLicence
                                    ? "Befähigungszeugnis für die Binnenschifffahrt"
                                    : ""}
                            </span>
                            <span
                                style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                    fontSize: "9.5px",
                                }}
                            >
                                {getLicenceType()}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "space-between", marginLeft: "10px" }}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
                        <div style={{ marginBottom: "5px" }}>
                            <span style={{ display: "inline-block", width: "15px" }}>1.</span>
                            <span>
                                {idDataSet.nonLatinLastName
                                    ? idDataSet.nonLatinLastName
                                    : idDataSet.lastName}
                            </span>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <span style={{ display: "inline-block", width: "15px" }}>2.</span>
                            <span>
                                {idDataSet.nonLatinFirstName
                                    ? idDataSet.nonLatinFirstName
                                    : idDataSet.firstName}
                            </span>
                        </div>
                        <div style={{ display: "flex", marginBottom: "5px" }}>
                            <div style={{ width: "40%" }}>
                                <span style={{ display: "inline-block", width: "15px" }}>3a.</span>
                                <span>{formatDateForCertificateView(idDataSet.dateOfBirth)}</span>
                            </div>
                            <div>
                                <span style={{ display: "inline-block", width: "15px" }}>3b.</span>
                                <span>
                                    {idDataSet.nonLatinPlaceOfBirth
                                        ? idDataSet.nonLatinPlaceOfBirth
                                        : idDataSet.placeOfBirth}
                                </span>
                            </div>
                        </div>
                        <div style={{ marginBottom: "15px" }}>
                            <span style={{ display: "inline-block", width: "15px" }}>4.</span>
                            <span>{idDataSet.cid}</span>
                        </div>
                        <div style={{ display: "flex", marginBottom: "5px" }}>
                            <div style={{ width: "40%" }}>
                                <span style={{ display: "inline-block", width: "15px" }}>7.</span>
                                <span>{formatDateForCertificateView(certificate.startDate)}</span>
                            </div>
                            <div>
                                <span style={{ display: "inline-block", width: "15px" }}>8.</span>
                                <span>{formatDateForCertificateView(certificate.endDate)}</span>
                            </div>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <span style={{ display: "inline-block", width: "15px" }}>9.</span>
                            <span>Port of Switzerland</span>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <span style={{ display: "inline-block", width: "15px" }}>10.</span>
                            <span>{getAuthorizationsArrays()[0]}</span>
                        </div>
                    </div>
                    <div>
                        <img
                            src={getUserImageSrc()}
                            alt="User"
                            style={{
                                width: "20mm",
                                height: "25mm",
                                marginRight: "10px",
                                marginTop: "-10px",
                            }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                        marginLeft: "10px",
                    }}
                >
                    <div style={{ width: "50%" }}>
                        <span style={{ display: "inline-block", width: "15px" }}>11.</span>
                        <span>{getRestricionsArray()[0]}</span>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                        <div>
                            <span style={{ display: "inline-block", width: "15px" }}>6.</span>
                            <span style={{ fontFamily: "OCR B Std" }}>
                                {certificate.qualificationID}
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section
                style={{
                    marginTop: "20px",
                    fontSize: "8.5px",
                    width: "86.5mm",
                    height: "54mm",
                    maxWidth: "86.5mm",
                    maxHeight: "54mm",
                    fontFamily: "Barlow",
                    backgroundImage: `url(${background_2})`,
                    backgroundSize: "cover",
                    border: "0.5px dashed black",
                    lineHeight: "1",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                        marginTop: "50px",
                    }}
                >
                    <span style={{ marginBottom: "5px", fontFamily: "Barlow Medium" }}>
                        {certificate.boatmasterLicenceType ===
                        BoatmasterLicenceType.BoatmasterLicence
                            ? "Befähigungszeugnis für die Binnenschifffahrt"
                            : ""}
                    </span>
                    <span style={{ fontWeight: "bold", marginBottom: "20px", fontSize: "9.5px" }}>
                        {getLicenceType()}
                    </span>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "space-between", marginLeft: "10px" }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div style={{ marginBottom: "5px" }}>
                            <span style={{ display: "inline-block", width: "15px" }}>10.</span>
                            <span>{getAuthorizationsArrays()[1]}</span>
                        </div>
                        <div>
                            <span style={{ display: "inline-block", width: "15px" }}>11.</span>
                            <span>{getRestricionsArray()[1]}</span>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`data:image/jpg;base64,${qrBase64}`}
                            alt="QR"
                            style={{
                                width: "22mm",
                                height: "22mm",
                                marginRight: "6px",
                                marginTop: "-32px",
                            }}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};
