import { IAuthorization } from "./authorization";
import { ICertificate } from "./certificate";
import { IEidasInformation } from "./eidasInformation";
import { ISRB } from "./srb";
import { IRequestFeedback } from "./requestFeedback";
import { Gender } from "./common";

export interface ICrewMember {
    guid: string;
    cid: string;
    chId: string;
    lastUpdated: string;
    idDataSets: IIDDataSet[];
    certificates: ICertificate[];
    authorizations: IAuthorization[];
    serviceRecordBooks: ISRB[];
    requestFeedbacks: IRequestFeedback[];
    ecdbAcknowledged: boolean;
    sharepointItemCreated?: boolean;
    qrBase64?: string;
}

export interface IIDDataSet {
    guid: string;
    firstName: string;
    nonLatinFirstName: string;
    lastName: string;
    nonLatinLastName: string;
    dateOfBirth: string;
    placeOfBirth: string;
    nonLatinPlaceOfBirth: string;
    added: string;
    lastUpdated: string;
    crewMemberGuid: string;
    cid: string;
    email: string;
    zipCode: string;
    street: string;
    city: string;
    country: number;
    eidasInformation: IEidasInformation[];
    nationality: number;
    issuingAuthority: string;
    imageBase64: string;
    signatureBase64: string;
    // CH additional inputs
    gender: Gender;
    countryOfBirth: number;
    phoneNumber: string;
    remarks: string;
    chId: string;
    signatureGuid: string;
    removeImageBackground: boolean;
    removeSignatureBackground: boolean;
}

export const newIDDataSetInit: IIDDataSet = {
    guid: "",
    firstName: "",
    nonLatinFirstName: "",
    lastName: "",
    nonLatinLastName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nonLatinPlaceOfBirth: "",
    added: "",
    lastUpdated: "",
    crewMemberGuid: "",
    cid: "",
    email: "",
    zipCode: "",
    street: "",
    city: "",
    country: -1,
    eidasInformation: [],
    nationality: -1,
    issuingAuthority: "",
    imageBase64: "",
    signatureBase64: "",
    gender: Gender.Unspecified,
    countryOfBirth: -1,
    phoneNumber: "",
    remarks: "",
    chId: "",
    signatureGuid: "",
    removeImageBackground: true,
    removeSignatureBackground: true,
};

export interface IIDDataSetFilter {
    cid: string;
    chId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    placeOfBirth: string;
    qualificationId: string;
    certificateType: string;
}

export const idDataSetsFilterInit: IIDDataSetFilter = {
    cid: "",
    chId: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    qualificationId: "",
    certificateType: "",
};

export const getLatinNonLatinCombined = (nonlatin: string, latin: string) => {
    if (!!nonlatin) return nonlatin + " (" + latin + ")";
    else return latin;
};

export const getIDDataSetDisplayName = (idDataSet: IIDDataSet) => {
    if (!!idDataSet.nonLatinFirstName && !!idDataSet.nonLatinLastName) {
        return `${idDataSet.nonLatinFirstName} ${idDataSet.nonLatinLastName} (${idDataSet.firstName} ${idDataSet.lastName})`;
    } else if (!!idDataSet.nonLatinFirstName) {
        return `${idDataSet.nonLatinFirstName} ${idDataSet.lastName} (${idDataSet.firstName} ${idDataSet.lastName})`;
    } else if (!!idDataSet.nonLatinLastName) {
        return `${idDataSet.firstName} ${idDataSet.nonLatinLastName} (${idDataSet.firstName} ${idDataSet.lastName})`;
    } else return idDataSet.firstName + " " + idDataSet.lastName;
};

export interface IEcdbIdentityResponse {
    ecdbOffline: boolean;
    offlineNcdbs: string[];
    dataSets: IIDDataSet[];
    current: string[];
}

export const ecdbIdentityResponseInit: IEcdbIdentityResponse = {
    ecdbOffline: true,
    offlineNcdbs: [],
    dataSets: [],
    current: [],
};
