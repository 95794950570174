import { IntlShape } from "react-intl";
import { AuthorizationType, authorizationTypeOptions } from "./authorization";
import { MessageStatus } from "./common";

export interface IInboxMessage {
    guid: string;
    created: string;
    message: string;
    sender: string;
    status: MessageStatus;
    type: InboxMessageType;
    targetQualificationId: string;
    targetAuthorizationType: AuthorizationType;
    notes: string;
    userGuid: string;
    crewMemberGuid: string;
    crewMemberCid: string;
}

export enum InboxMessageType {
    AddAuthorizationRequest,
    CertificateWithdrawalECDB,
    CertificateWithdrawalNCDB,
    CrewMemberAdded,
    CertificateAdded,
    AuthorizationAdded,
    ServiceRecordBookAdded,
    CrewMemberUpdated,
    ServiceRecordBookUpdated,
    CertificateIsSuspended,
    CertificateIsWithdrawn,
    CertificateModified,
    PrintFeedbackFailed,
    AuthorizationIsWithdrawn,
    AuthorizationModified,
    AuthorizationIsSuspended,
}

export const getTranslatedCleanInboxMessage = (inboxMessage: IInboxMessage, intl: IntlShape) => {
    const stringEnum = InboxMessageType[inboxMessage.type];
    const intlType = stringEnum.charAt(0).toLowerCase() + stringEnum.slice(1);
    return intl
        .formatMessage({ id: intlType })
        .replace("TARGET_CERTIFICATE_ID", inboxMessage.targetQualificationId)
        .replace(
            "AUTH_TYPE",
            intl.formatMessage({
                id: authorizationTypeOptions.find(
                    x => x.value === inboxMessage.targetAuthorizationType
                )?.text as string,
            })
        )
        .replace("USER_USERNAME", inboxMessage.sender)
        .replace("CREW_MEMBER_CID", inboxMessage.crewMemberCid)
        .replace("INBOX_NOTES", !!inboxMessage.notes ? inboxMessage.notes : "N/A");
};
