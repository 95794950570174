import { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Message, Segment, Table } from "semantic-ui-react";
import { TablePagination } from "../../components/TablePagination/TablePagination";
import { getTranslatedCleanChangeLogItem, IChangeLogItem } from "../../models/changeLogItem";
import { formatDate } from "../../models/common";

type Props = {
    items: IChangeLogItem[];
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    totalRows: number;
};

export const ChangeLogTable = ({ items, page, setPage, totalRows }: Props) => {
    const intl = useIntl();

    return items.length === 0 ? (
        <Message icon="info circle" header={intl.formatMessage({ id: "noResultsFound" })} />
    ) : (
        <Segment>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1} singleLine>
                            {intl.formatMessage({ id: "timestamp" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1} singleLine>
                            {intl.formatMessage({ id: "user" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell>{intl.formatMessage({ id: "message" })}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map(c => (
                        <Table.Row key={c.guid}>
                            <Table.Cell singleLine>{formatDate(c.timestamp, true)}</Table.Cell>
                            <Table.Cell singleLine>{c.username || "EC"}</Table.Cell>
                            <Table.Cell>{getTranslatedCleanChangeLogItem(c, intl)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <TablePagination page={page} setPage={setPage} totalRows={totalRows} />
        </Segment>
    );
};
