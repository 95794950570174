import { useIntl } from "react-intl";
import { Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { agent } from "../../api/agent";
import { ICertificate } from "../../models/certificate";

type Props = {
    open: boolean;
    closeModal: () => void;
    certificate: ICertificate;
    fetchCrewMember: () => Promise<void>;
};

export const DeleteCertificateModal = ({
    open,
    closeModal,
    certificate,
    fetchCrewMember,
}: Props) => {
    const intl = useIntl();

    const handleDeleteCrewMember = async () => {
        try {
            await agent.Certificates.delete(certificate);
            toast(intl.formatMessage({ id: "certificateDeleted" }), true);
            await fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unabletoDeleteCertificate" }), false);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={() => closeModal()} dimmer="blurring" size="tiny">
            <Modal.Header>{intl.formatMessage({ id: "areYouSureDeleteCertificate" })}</Modal.Header>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => closeModal()}
                    content={intl.formatMessage({ id: "cancel" })}
                />
                <Button
                    primary
                    onClick={() => handleDeleteCrewMember()}
                    content={intl.formatMessage({ id: "confirm" })}
                />
            </Modal.Actions>
        </Modal>
    );
};
