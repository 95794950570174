import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import enMessages from "./i18n/locales/en.json";
import deMessages from "./i18n/locales/de.json";

const DEFAULT_LOCALE = "en";

interface ILangMessages extends Record<string, any> {
    en: any;
    de: any;
}

const langMessages: ILangMessages = {
    en: enMessages,
    de: deMessages,
};

const LanguageChange = (state: any) => {
    const locale = state.user.language ? state.user.language : DEFAULT_LOCALE;
    const messages = langMessages[locale];
    return { locale: locale, messages: messages };
};

export default connect(LanguageChange)(IntlProvider);
