import { DropdownItemProps } from "semantic-ui-react";
import { ICountryNationalityProps } from "../store/nationalitiesReducer";
import { CertificateType } from "./certificate";

export interface IPaginationWrapper {
    totalRows: number;
    result: any[];
}

export const daysProps = [...new Array(31)].map((x, i) => ({
    key: i + 1,
    text: i + 1,
    value: i + 1,
}));

export enum Gender {
    Unspecified,
    Male,
    Female,
    NonBinary,
}

export const genderProps: DropdownItemProps[] = [
    { key: Gender.Male, text: "male", value: Gender.Male },
    { key: Gender.Female, text: "female", value: Gender.Female },
    { key: Gender.NonBinary, text: "nonBinary", value: Gender.NonBinary },
];

const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

export const monthsProps = [...new Array(12)].map((x, i) => ({
    key: i + 1,
    text: months[i],
    value: i + 1,
}));

export const yearsProps = [...new Array(100)].fill(new Date().getFullYear() + 50).map((x, i) => ({
    key: x - i,
    text: x - i,
    value: x - i,
}));

export const birthYearsProps = [...new Array(100)].fill(new Date().getFullYear()).map((x, i) => ({
    key: x - i,
    text: x - i,
    value: x - i,
}));

export const daysOfBirth: DropdownItemProps[] = [];

export const languageProps: DropdownItemProps[] = [
    { key: "de", text: "", value: "de", flag: "de" },
    { key: "en", text: "", value: "en", flag: "uk" },
];

export interface IDate {
    day: number;
    month: number;
    year: number;
}

export const dateInit: IDate = {
    day: -1,
    month: -1,
    year: -1,
};

export const constructDate = (date: IDate | Date): string => {
    if ("year" in date)
        return (
            date.year +
            "/" +
            date.month.toString().padStart(2, "0") +
            "/" +
            date.day.toString().padStart(2, "0") 
        );
    else
        return (
            date.getFullYear() +
            "/" +
            (date.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            date.getDate().toString().padStart(2, "0")
        );
};

export const deconstructDate = (dateString: string): IDate => {
    const d = new Date(dateString);
    return { day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear() };
};

export const getTodayDate = (): IDate => {
    const d = new Date();
    return { day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear() };
};

export const getAge = (dateOfBirth: Date): number => {
    const diff = new Date().getTime() - dateOfBirth.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
};

export const getCertificateEndDate = (
    dateOfBirth: string,
    type: CertificateType,
    startDate: IDate
): IDate => {
    let dob = new Date(dateOfBirth);
    const age = getAge(dob);
    const today = getTodayDate();
    switch (type) {
        case CertificateType.UcqBoatmaster:
            if (age < 47) return { ...today, year: today.year + 13 };
            else if (age < 60) {
                const dob = deconstructDate(dateOfBirth);
                return { ...dob, year: dob.year + 60 };
            } else if (age < 70) return { ...today, year: today.year + 5 };
            else return { ...today, year: today.year + 2 };
        case CertificateType.UcqLngExpert:
        case CertificateType.UcqPassengerNavigationExpert:
            return { ...today, year: today.year + 5 };
        case CertificateType.BoatmasterOnTheRhine:
        case CertificateType.BoatmasterOnTheHighRhine:
            if (age < 50) dob.setFullYear(dob.getFullYear() + 50);
            else if (age < 55) dob.setFullYear(dob.getFullYear() + 55);
            else if (age < 60) dob.setFullYear(dob.getFullYear() + 60);
            else if (age < 65) dob.setFullYear(dob.getFullYear() + 65);
            else dob.setFullYear(dob.getFullYear() + age + 1);
            dob.setMonth(dob.getMonth() + 3);
            return deconstructDate(constructDate(dob));
        case CertificateType.AdnBescheinigung:
            return { ...startDate, year: startDate.year + 5 };
        case CertificateType.ExtensionBoatmasterOnTheRhine:
            const endDateDate = new Date(constructDate(startDate));
            endDateDate.setMonth(endDateDate.getMonth() + 3);
            return deconstructDate(constructDate(endDateDate));
        default:
            return dateInit;
    }
};

/**
 * Checks if second date is greater than first date
 * @param  {IDate} d1 First date
 * @param  {IDate} d2 Second date to compare against first date
 * @param  {boolean} allowSame Additional flag for allowing same dates
 */
export const compareDates = (d1: IDate, d2: IDate, allowSame: boolean = false): boolean | null => {
    if (
        Object.values(d1).some(x => x === -1 || x === "") ||
        Object.values(d2).some(x => x === -1 || x === "")
    )
        return null;
    if (d2.year === 1) return true;
    const date1 = new Date(d1.year, d1.month - 1, d1.day);
    const date2 = new Date(d2.year, d2.month - 1, d2.day);
    if (allowSame) return date2 >= date1;
    else return date2 > date1;
};

/**
 * Formats ISO date or datetime
 * @param  {string} dateString Date string to format
 * @param  {boolean} includeTime Optional parameter to include time as well
 */
export const formatDate = (dateString: string, includeTime: boolean = false) => {
    if (!dateString) return "";
    const isoDateFormat =
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?$/i;
    const isIsoDateString =
        dateString && typeof dateString === "string" && isoDateFormat.test(dateString);
    if (isIsoDateString) {
        const date = new Date(dateString);
        const formattedDate =
            date.getDate().toString().padStart(2, "0") +
            "/" +
            (date.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            date.getFullYear();
        if (includeTime)
            return (
                formattedDate +
                " " +
                date.getHours().toString().padStart(2, "0") +
                ":" +
                date.getMinutes().toString().padStart(2, "0") +
                ":" +
                date.getSeconds().toString().padStart(2, "0")
            );
        else return formattedDate;
    } else {
        if (!dateString.includes("-")) return dateString;
        const date = new Date(dateString);
        return (
            date.getDate().toString().padStart(2, "0") +
            "/" +
            (date.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            date.getFullYear()
        );
    }
};

export enum MessageStatus {
    Unprocessed,
    Success,
    Failure,
}

export const messageStatusProps: DropdownItemProps[] = [
    { key: MessageStatus.Unprocessed, text: "unprocessed", value: MessageStatus.Unprocessed },
    { key: MessageStatus.Success, text: "success", value: MessageStatus.Success },
    { key: MessageStatus.Failure, text: "failure", value: MessageStatus.Failure },
];

export const getCountryFromIssuingAuthority = (
    issuingAuthority: string,
    countryProps: ICountryNationalityProps[]
) => {
    if (!issuingAuthority) return -1;
    if (issuingAuthority.includes("AT"))
        return countryProps.find(x => (x.text as string) === "AUT")?.value as number;
    else if (issuingAuthority.includes("CH"))
        return countryProps.find(x => (x.text as string) === "CHE")?.value as number;
    else return -1;
};

export const formatDateForCertificateView = (dateString: string) => {
    if (!dateString) return "";
    const d = new Date(dateString);
    if (d.getFullYear() === 1) return "";
    return (
        d.getDate().toString().padStart(2, "0") +
        "." +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "." +
        d.getFullYear()
    );
};
