import { useState } from "react";
import { Segment, Button, Icon } from "semantic-ui-react";
import {
    getIDDataSetDisplayName,
    getLatinNonLatinCombined,
    ICrewMember,
} from "../../models/crewMember";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { CrewMemberImage } from "./CrewMemberImage";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { ConnectWithECDBModal } from "./ConnectWithECDBModal";
import { agent } from "../../api/agent";
import { toast } from "../..";
import { useNavigate } from "react-router-dom";
import { CrewMemberFormModal } from "./CrewMemberFormModal";
import { formatDate, genderProps } from "../../models/common";
import { ConnectWithLocalModal } from "./ConnectWithLocalModal";
import { TranslateCountryProps, TranslateProps } from "../../i18n/TranslateProps";
import { RequestFeedbackIndicator } from "../../components/RequestFeedbackIndicator/RequestFeedbackIndicator";
import { SignatureImage } from "./SignatureImage";

type Props = {
    crewMember: ICrewMember;
    fetchCrewMember: () => Promise<void>;
};

export const CrewMemberDetails = ({ crewMember, fetchCrewMember }: Props) => {
    const navigate = useNavigate();
    const user = useSelector((state: AppState) => state.user);
    const sharepointSettings = useSelector((state: AppState) => state.sharepointSettings);
    const { countries, nationalities } = useSelector((state: AppState) => state.nationalities);
    const intl = useIntl();
    const [idDataSet, setIdDataSet] = useState(crewMember.idDataSets[0]);
    const [editDatasetModalOpen, setEditDatasetModalOpen] = useState(false);
    const [connectWithECDBModalOpen, setConnectWithECDBModalOpen] = useState(false);
    const [connectWithLocalModalOpen, setConnectWithLocalModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSwitchIdDataSets = (dir: string) => {
        const currentIndex = crewMember.idDataSets.findIndex(x => x.guid === idDataSet.guid);
        if (dir === "prev") setIdDataSet([...crewMember.idDataSets][currentIndex + 1]);
        else if (dir === "next") setIdDataSet([...crewMember.idDataSets][currentIndex - 1]);
    };

    const handleCopyDataset = async () => {
        setLoading(true);
        try {
            idDataSet.added = new Date().toISOString();
            let file: File | undefined = undefined;
            if (idDataSet.imageBase64) {
                const binaryString = window.atob(idDataSet.imageBase64);
                const bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) bytes[i] = binaryString.charCodeAt(i);
                file = new File([new Uint8Array(bytes.buffer)], "profilePicture01.jpg", {
                    type: "image/jpeg",
                });
            }
            const res = await agent.CrewMembers.create(idDataSet, file, undefined, true);
            const guid = res.replace(/"/g, "");
            toast(intl.formatMessage({ id: "createdCrewMember" }), true);
            navigate("/crew-members/" + guid);
        } catch {
            toast(intl.formatMessage({ id: "unableToCreateCrewMember" }), false);
        } finally {
            setLoading(false);
        }
    };

    const getSharepointLink = () => {
        const siteUrlBackslash = sharepointSettings.siteUrl.endsWith("/")
            ? sharepointSettings.siteUrl
            : `${sharepointSettings.siteUrl}/`;

        return `${siteUrlBackslash}ecssearch?CHID=${crewMember.chId}&~path=${siteUrlBackslash}Lists/${sharepointSettings.listName}&target=Fileview`;
    };

    return (
        <>
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={idDataSet.guid}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames="fade"
                    unmountOnExit
                >
                    <Segment className="grey-segment crew-member-details-card">
                        <div className="card-header">
                            <div className="card-half card-images">
                                <CrewMemberImage imageBase64={idDataSet.imageBase64} />
                                <SignatureImage signatureBase64={idDataSet.signatureBase64} />
                            </div>
                            <div className="card-half">
                                <h1>{getIDDataSetDisplayName(idDataSet)}</h1>
                                <h2>
                                    {user.ecdbConnection && (
                                        <label>
                                            {intl.formatMessage({ id: "cid" })}:&nbsp;
                                            <span>
                                                {crewMember.cid ? (
                                                    crewMember.cid
                                                ) : (
                                                    <em>
                                                        {intl.formatMessage({
                                                            id: "unassigned",
                                                        })}
                                                    </em>
                                                )}
                                            </span>
                                        </label>
                                    )}
                                    <label>
                                        CH ID:&nbsp;
                                        <span>
                                            {!!idDataSet.chId ? (
                                                idDataSet.chId
                                            ) : (
                                                <em>{intl.formatMessage({ id: "unassigned" })}</em>
                                            )}
                                        </span>
                                    </label>
                                </h2>
                            </div>
                        </div>
                        <div className="crew-member-details-grid">
                            <div className="card-half">
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "placeOfBirth" })}:</label>
                                    <span>
                                        {getLatinNonLatinCombined(
                                            idDataSet.nonLatinPlaceOfBirth,
                                            idDataSet.placeOfBirth
                                        )}
                                    </span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "countryOfBirth" })}:</label>
                                    <span>
                                        {
                                            TranslateCountryProps(countries, intl.locale).find(
                                                x => x.value === idDataSet.countryOfBirth
                                            )?.text
                                        }
                                    </span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "nationality" })}:</label>
                                    <span>
                                        {
                                            TranslateCountryProps(nationalities, intl.locale).find(
                                                x => x.value === idDataSet.nationality
                                            )?.text
                                        }
                                    </span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "street" })}:</label>
                                    <span>{idDataSet.street}</span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "city" })}:</label>
                                    <span>{idDataSet.city}</span>
                                </section>
                                <section className="section-row">
                                    <label>Email:</label>
                                    <span>{idDataSet.email}</span>
                                </section>
                            </div>
                            <div className="card-half">
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "dateOfBirth" })}:</label>
                                    <span>{formatDate(idDataSet.dateOfBirth)}</span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "gender" })}:</label>
                                    <span>
                                        {
                                            TranslateProps(intl, genderProps).find(
                                                x => x.value === idDataSet.gender
                                            )?.text
                                        }
                                    </span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "phoneNumber" })}:</label>
                                    <span>{idDataSet.phoneNumber}</span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "zipCode" })}:</label>
                                    <span>{idDataSet.zipCode}</span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "country" })}:</label>
                                    <span>
                                        {
                                            TranslateCountryProps(countries, intl.locale).find(
                                                x => x.value === idDataSet.country
                                            )?.text
                                        }
                                    </span>
                                </section>
                                <section className="section-row">
                                    <label>{intl.formatMessage({ id: "remarks" })}:</label>
                                    <span id="remarks-field">
                                        {idDataSet.remarks ? idDataSet.remarks.trim() : ""}
                                    </span>
                                </section>
                            </div>
                        </div>
                        <hr />
                        <div className="card-actions">
                            {user.role !== UserRole.EnforcementAuthorityUser && (
                                <>
                                    {crewMember.guid.includes("ECDB_CREW_MEMBER") &&
                                    user.ecdbConnection ? (
                                        <Button
                                            primary
                                            content={intl.formatMessage({
                                                id: "connectWithLocalCrewMember",
                                            })}
                                            onClick={() => setConnectWithLocalModalOpen(true)}
                                        />
                                    ) : (
                                        <Button
                                            primary
                                            content={intl.formatMessage({ id: "editCrewMember" })}
                                            onClick={() => setEditDatasetModalOpen(true)}
                                        />
                                    )}
                                    {crewMember.chId && sharepointSettings?.siteUrl && (
                                        <a
                                            id="dossier-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={getSharepointLink()}
                                        >
                                            <Button
                                                primary
                                                content={intl.formatMessage({
                                                    id: "dossierCrewMember",
                                                })}
                                            />
                                        </a>
                                    )}
                                    {!crewMember.cid && user.ecdbConnection && (
                                        <Button
                                            primary
                                            content={intl.formatMessage({
                                                id: "connectCrewMemberWithECDB",
                                            })}
                                            onClick={() => setConnectWithECDBModalOpen(true)}
                                        />
                                    )}
                                    {crewMember.guid.includes("ECDB_CREW_MEMBER") && (
                                        <Button
                                            primary
                                            content={intl.formatMessage({ id: "copyIdDataset" })}
                                            onClick={() => handleCopyDataset()}
                                            disabled={loading}
                                            loading={loading}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        <Icon
                            link
                            name="chevron left"
                            size="huge"
                            disabled={
                                idDataSet.guid ===
                                crewMember.idDataSets[crewMember.idDataSets.length - 1].guid
                            }
                            onClick={() => handleSwitchIdDataSets("prev")}
                            title={intl.formatMessage({ id: "viewPreviousDataset" })}
                        />
                        <Icon
                            link
                            name="chevron right"
                            size="huge"
                            disabled={idDataSet.guid === crewMember.idDataSets[0].guid}
                            onClick={() => handleSwitchIdDataSets("next")}
                            title={intl.formatMessage({ id: "viewNextDataset" })}
                        />
                        <div id="details-indicator">
                            {crewMember.requestFeedbacks.length > 0 ? (
                                <RequestFeedbackIndicator
                                    feedbacks={crewMember.requestFeedbacks}
                                    left
                                />
                            ) : crewMember.ecdbAcknowledged || !!crewMember.cid ? (
                                <Icon name="check circle" size="big" />
                            ) : null}
                        </div>
                    </Segment>
                </CSSTransition>
            </SwitchTransition>
            {editDatasetModalOpen && (
                <CrewMemberFormModal
                    open={editDatasetModalOpen}
                    closeModal={() => setEditDatasetModalOpen(false)}
                    idDataSet={idDataSet}
                    fetchCrewMember={fetchCrewMember}
                />
            )}
            {connectWithECDBModalOpen && (
                <ConnectWithECDBModal
                    open={connectWithECDBModalOpen}
                    close={() => setConnectWithECDBModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    crewMemberGuid={crewMember.guid}
                />
            )}
            {connectWithLocalModalOpen && (
                <ConnectWithLocalModal
                    open={connectWithLocalModalOpen}
                    closeModal={() => setConnectWithLocalModalOpen(false)}
                    idDataSet={idDataSet}
                />
            )}
        </>
    );
};
