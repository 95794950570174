import { createIntl, IntlShape } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { TranslateProps } from "../i18n/TranslateProps";
import { ICountryNationalityProps } from "../store/nationalitiesReducer";
import { IAuthorization } from "./authorization";
import { formatDateForCertificateView } from "./common";
import { IRequestFeedback } from "./requestFeedback";
import deMessages from "../i18n/locales/de.json";

export interface ICertificate {
    guid: string;
    added: string;
    lastUpdated: string;
    type: CertificateType;
    qualificationID: string;
    restriction96: string;
    status: CertificateStatus;
    startDate: string;
    endDate: string;
    comments: string;
    reason: string;
    crewMemberGuid: string;
    suspensionStartDate: string;
    suspensionEndDate: string;
    restrictions: number[];
    authorizations: IAuthorization[];
    limitedToOneCraftDescription: string;
    limitedAreaDescription: string;
    limitedTaskDescription: string;
    issuingAuthority: string;
    fitnessIssueDate: string;
    fitnessValidUntil: string;
    administrativeNumber: string;
    lastQualificationID: string;
    nameLineOne: string;
    nameLineTwo: string;
    zipCode: string;
    city: string;
    street: string;
    country: number;
    gender: number;
    costBearer: number;
    requestFeedbacks: IRequestFeedback[];
    ecdbAcknowledged: boolean;
    batchId: string;
    printAcknowledged: boolean;
    printFeedback: string;
    isUnlimited: boolean;
    // Stretches with specific risks and High Rhine inputs
    stretchesRiverKilometers: number[];
    linkedCertificateIssueDate: string;
    linkedCertificateType: LinkedCertificateType;
    linkedCertificateIssuingCountry: number;
    linkedCertificateQualificationID: string;
    // ADN inputs
    adnExamType: AdnExamType;
    adnCourses: number[];
    // Boatmaster on the Rhine inputs
    rhineLicenceType: RhineLicenceType;
    rhineRiverKilometers: number[]; // also for Extension of Certificate of Qualification as a Boatmaster on the Rhine
    // Boatmaster on the High Rhine inputs
    highRhineLicenceType: HighRhineLicenceType;
    boatmasterLicenceType: BoatmasterLicenceType;
    highRhineRiverKilometers: number[];
    // Extension of Certificate of Qualification as a Boatmaster on the Rhine inputs
    extensionBoatmasterType: number;
}

export const newCertificateInit: ICertificate = {
    guid: "",
    added: "",
    lastUpdated: "",
    type: -1,
    qualificationID: "",
    restriction96: "",
    status: -1,
    startDate: "",
    endDate: "",
    comments: "",
    reason: "",
    crewMemberGuid: "",
    suspensionStartDate: "",
    suspensionEndDate: "",
    restrictions: [],
    authorizations: [],
    limitedToOneCraftDescription: "",
    limitedAreaDescription: "",
    limitedTaskDescription: "",
    issuingAuthority: "",
    fitnessIssueDate: "",
    fitnessValidUntil: "",
    administrativeNumber: "",
    lastQualificationID: "",
    nameLineOne: "",
    nameLineTwo: "",
    zipCode: "",
    city: "",
    street: "",
    country: 169, // CH
    gender: 0,
    costBearer: 2,
    requestFeedbacks: [],
    ecdbAcknowledged: false,
    batchId: "",
    printAcknowledged: false,
    printFeedback: "",
    isUnlimited: false,
    stretchesRiverKilometers: [],
    linkedCertificateIssueDate: "",
    linkedCertificateType: -1,
    linkedCertificateIssuingCountry: -1,
    linkedCertificateQualificationID: "",
    adnExamType: -1,
    adnCourses: [],
    rhineLicenceType: -1,
    rhineRiverKilometers: [],
    highRhineLicenceType: -1,
    highRhineRiverKilometers: [],
    boatmasterLicenceType: -1,
    extensionBoatmasterType: 0,
};

export enum CertificateType {
    Unknown = -1,
    UcqBoatmaster = 1,
    UcqHelmsman = 2,
    UcqAbleBoatman = 3,
    UcqBoatman = 4,
    UcqDeckhand = 5,
    UcqApprentice = 6,
    UcqLngExpert = 50,
    UcqPassengerNavigationExpert = 51,
    StretchesWithSpecificRisks = 60,
    Radarpatent = 61,
    HighRhineCertificate = 62,
    AdnBescheinigung = 63,
    BoatmasterOnTheRhine = 64,
    BoatmasterOnTheHighRhine = 65,
    ExtensionBoatmasterOnTheRhine = 66,
    EU_SRB = 73,
    CH_SRB = 74,
    // EngineersCertificate = 67,
    // Helmsman = 68,
    // AbleBoatman = 69,
    // Boatman = 70,
    // Deckhand = 71,
    // Apprentice = 72,
}

export const certificateTypeOptions: DropdownItemProps[] = [
    {
        key: CertificateType.UcqBoatmaster,
        text: "ucqBoatmaster",
        value: CertificateType.UcqBoatmaster,
        content: "ucqBoatmasterEU",
    },
    {
        key: CertificateType.UcqHelmsman,
        text: "ucqHelmsman",
        value: CertificateType.UcqHelmsman,
        content: "ucqHelmsmanEU",
    },
    {
        key: CertificateType.UcqAbleBoatman,
        text: "ucqAbleBoatman",
        value: CertificateType.UcqAbleBoatman,
        content: "ucqAbleBoatmanEU",
    },
    {
        key: CertificateType.UcqBoatman,
        text: "ucqBoatman",
        value: CertificateType.UcqBoatman,
        content: "ucqBoatmanEU",
    },
    {
        key: CertificateType.UcqDeckhand,
        text: "ucqDeckhand",
        value: CertificateType.UcqDeckhand,
        content: "ucqDeckhandEU",
    },
    {
        key: CertificateType.UcqApprentice,
        text: "ucqApprentice",
        value: CertificateType.UcqApprentice,
        content: "ucqApprenticeEU",
    },
    {
        key: CertificateType.UcqLngExpert,
        text: "ucqLngExpert",
        value: CertificateType.UcqLngExpert,
    },
    {
        key: CertificateType.UcqPassengerNavigationExpert,
        text: "ucqPassengerExpert",
        value: CertificateType.UcqPassengerNavigationExpert,
    },
    {
        key: CertificateType.StretchesWithSpecificRisks,
        text: "stretchesWithSpecificRisks",
        value: CertificateType.StretchesWithSpecificRisks,
    },
    {
        key: CertificateType.Radarpatent,
        text: "radarpatent",
        value: CertificateType.Radarpatent,
    },
    {
        key: CertificateType.HighRhineCertificate,
        text: "highRhineCertificate",
        value: CertificateType.HighRhineCertificate,
    },
    {
        key: CertificateType.AdnBescheinigung,
        text: "adnBescheinigung",
        value: CertificateType.AdnBescheinigung,
    },
    {
        key: CertificateType.ExtensionBoatmasterOnTheRhine,
        text: "extensionBoatmasterOnTheRhine",
        value: CertificateType.ExtensionBoatmasterOnTheRhine,
    },
    {
        key: CertificateType.EU_SRB,
        text: "euSrb",
        value: CertificateType.EU_SRB,
    },
    {
        key: CertificateType.CH_SRB,
        text: "chSrb",
        value: CertificateType.CH_SRB,
    },
    // {
    //     key: CertificateType.EngineersCertificate,
    //     text: "engineersCertificate",
    //     value: CertificateType.EngineersCertificate,
    // },
    // {
    //     key: CertificateType.Helmsman,
    //     text: "helmsman",
    //     value: CertificateType.Helmsman,
    // },
    // {
    //     key: CertificateType.AbleBoatman,
    //     text: "ableBoatman",
    //     value: CertificateType.AbleBoatman,
    // },
    // {
    //     key: CertificateType.Boatman,
    //     text: "boatman",
    //     value: CertificateType.Boatman,
    // },
    // {
    //     key: CertificateType.Deckhand,
    //     text: "deckhand",
    //     value: CertificateType.Deckhand,
    // },
    // {
    //     key: CertificateType.Apprentice,
    //     text: "apprentice",
    //     value: CertificateType.Apprentice,
    // },
    {
        key: CertificateType.BoatmasterOnTheRhine,
        text: "boatmasterOnTheRhine",
        value: CertificateType.BoatmasterOnTheRhine,
    },
    {
        key: CertificateType.BoatmasterOnTheHighRhine,
        text: "boatmasterOnTheHighRhine",
        value: CertificateType.BoatmasterOnTheHighRhine,
    },
];

export const statePrintingCertificates = [
    CertificateType.UcqBoatmaster,
    CertificateType.UcqLngExpert,
    CertificateType.UcqPassengerNavigationExpert,
];

export const unionCertificates = [
    CertificateType.UcqBoatmaster,
    CertificateType.UcqLngExpert,
    CertificateType.UcqPassengerNavigationExpert,
    CertificateType.UcqDeckhand,
    CertificateType.UcqApprentice,
    CertificateType.UcqBoatman,
    CertificateType.UcqAbleBoatman,
    CertificateType.UcqHelmsman,
];

export const downloadableCertificates = [
    CertificateType.UcqBoatmaster,
    CertificateType.UcqLngExpert,
    CertificateType.UcqPassengerNavigationExpert,
    CertificateType.StretchesWithSpecificRisks,
    CertificateType.Radarpatent,
    CertificateType.HighRhineCertificate,
    CertificateType.AdnBescheinigung,
    CertificateType.BoatmasterOnTheRhine,
    CertificateType.BoatmasterOnTheHighRhine,
    CertificateType.ExtensionBoatmasterOnTheRhine,
];

export const fitnessDataCertificates = [
    CertificateType.UcqBoatmaster,
    CertificateType.UcqLngExpert,
    CertificateType.UcqPassengerNavigationExpert,
    CertificateType.UcqDeckhand,
    CertificateType.UcqApprentice,
    CertificateType.UcqBoatman,
    CertificateType.UcqAbleBoatman,
    CertificateType.UcqHelmsman,
    // CertificateType.EngineersCertificate,
    // CertificateType.Helmsman,
    // CertificateType.AbleBoatman,
    // CertificateType.Boatman,
    // CertificateType.Deckhand,
    // CertificateType.Apprentice,
];

export const downloadableCoverPages = [
    CertificateType.BoatmasterOnTheRhine,
    CertificateType.BoatmasterOnTheHighRhine,
];

export enum CertificateStatus {
    Unknown = -1,
    Active = 1,
    Expired = 10,
    Suspended = 11,
    Withdrawn = 12,
    Lost = 90,
    Stolen = 91,
    Destroyed = 92,
}

export const certificateStatusOptions: DropdownItemProps[] = [
    { key: CertificateStatus.Active, text: "active", value: CertificateStatus.Active },
    { key: CertificateStatus.Destroyed, text: "destroyed", value: CertificateStatus.Destroyed },
    { key: CertificateStatus.Expired, text: "expired", value: CertificateStatus.Expired },
    { key: CertificateStatus.Lost, text: "lost", value: CertificateStatus.Lost },
    { key: CertificateStatus.Stolen, text: "stolen", value: CertificateStatus.Stolen },
    { key: CertificateStatus.Suspended, text: "suspended", value: CertificateStatus.Suspended },
    { key: CertificateStatus.Withdrawn, text: "withdrawn", value: CertificateStatus.Withdrawn },
];

export const restrictionStatusOptions: DropdownItemProps[] = [
    { key: 1, text: "sightCorrection", value: 1 },
    { key: 2, text: "hearingAidRequired", value: 2 },
    { key: 3, text: "limbProsthesis", value: 3 },
    { key: 4, text: "noSoloDuty", value: 4 },
    { key: 5, text: "onlyDuringDaylight", value: 5 },
    { key: 6, text: "noNavigationalDuties", value: 6 },
    { key: 7, text: "limitedToOneCraft", value: 7 },
    { key: 8, text: "limitedArea", value: 8 },
    { key: 9, text: "limitedTask", value: 9 },
    { key: 10, text: "noticeWithRestrictions", value: 10 },
];

export const modifyCertificateInit: Partial<ICertificate> = {
    status: -1,
    suspensionStartDate: "",
    suspensionEndDate: "",
    comments: "",
};

export enum AdnExamType {
    Unknown = -1,
    BasicCourse = 1,
    RepetitionCourse,
}

export const adnExamOptions: DropdownItemProps[] = [
    { key: AdnExamType.BasicCourse, text: "basicCourse", value: AdnExamType.BasicCourse },
    {
        key: AdnExamType.RepetitionCourse,
        text: "repetitionCourse",
        value: AdnExamType.RepetitionCourse,
    },
];

export enum AdnCourseType {
    General = 1,
    DryGoods,
    Tank,
    Gas,
    Chemistry,
}

export const adnCourseOptions: DropdownItemProps[] = [
    {
        key: AdnCourseType.General,
        text: "8.2.2.3.1.1 (Allgemein / General)",
        value: AdnCourseType.General,
    },
    {
        key: AdnCourseType.DryGoods,
        text: "8.2.2.3.1.2 (Trockengüter / Dry goods)",
        value: AdnCourseType.DryGoods,
    },
    {
        key: AdnCourseType.Tank,
        text: "8.2.2.3.1.3 (Tank)",
        value: AdnCourseType.Tank,
    },
    {
        key: AdnCourseType.Gas,
        text: "8.2.2.3.3.1 (Gas)",
        value: AdnCourseType.Gas,
    },
    {
        key: AdnCourseType.Chemistry,
        text: "8.2.2.3.3.2 (Chemie / Chemistry)",
        value: AdnCourseType.Chemistry,
    },
];

export enum RhineLicenceType {
    Unknown = -1,
    GreatRhineLicence = 1,
    SportsLicence,
    SmallRhineLicence,
}

export const rhineLicenceOptions: DropdownItemProps[] = [
    {
        key: RhineLicenceType.GreatRhineLicence,
        text: "greatRhineLicence",
        value: RhineLicenceType.GreatRhineLicence,
    },
    {
        key: RhineLicenceType.SportsLicence,
        text: "sportsLicence",
        value: RhineLicenceType.SportsLicence,
    },
    {
        key: RhineLicenceType.SmallRhineLicence,
        text: "smallRhineLicence",
        value: RhineLicenceType.SmallRhineLicence,
    },
];

export enum HighRhineLicenceType {
    Unknown = -1,
    GreatHighRhineLicence = 1,
    SportsHighRhineLicence,
    GovernmentLicence,
    SmallHighRhineLicence,
}

export const highRhineLicenceOptions: DropdownItemProps[] = [
    {
        key: HighRhineLicenceType.GreatHighRhineLicence,
        text: "greatHighRhineLicence",
        value: HighRhineLicenceType.GreatHighRhineLicence,
    },
    {
        key: HighRhineLicenceType.SportsHighRhineLicence,
        text: "sportsHighRhineLicence",
        value: HighRhineLicenceType.SportsHighRhineLicence,
    },
    {
        key: HighRhineLicenceType.GovernmentLicence,
        text: "governmentLicence",
        value: HighRhineLicenceType.GovernmentLicence,
    },
    {
        key: HighRhineLicenceType.SmallHighRhineLicence,
        text: "smallHighRhineLicence",
        value: HighRhineLicenceType.SmallHighRhineLicence,
    },
];

export enum BoatmasterLicenceType {
    Unknown = -1,
    BoatmasterLicence = 1,
    SportLicence,
    GovernmentLicence,
}

export const boatmasterLicenceOptions: DropdownItemProps[] = [
    {
        key: BoatmasterLicenceType.BoatmasterLicence,
        text: "boatmasterLicence",
        value: BoatmasterLicenceType.BoatmasterLicence,
        content: "Schiffsführer",
    },
    {
        key: BoatmasterLicenceType.SportLicence,
        text: "sportLicence",
        value: BoatmasterLicenceType.SportLicence,
        content: "Sportpatent",
    },
    {
        key: HighRhineLicenceType.GovernmentLicence,
        text: "governmentLicence",
        value: HighRhineLicenceType.GovernmentLicence,
        content: "Behördenpatent",
    },
];

export enum ExtensionBoatmasterType {
    GreatLicence = 1,
    SmallLicence,
    SportsLicence,
    GovernmentLicence,
}

export const extensionBoatmasterOptions: DropdownItemProps[] = [
    {
        key: ExtensionBoatmasterType.GreatLicence,
        text: "greatLicence",
        value: ExtensionBoatmasterType.GreatLicence,
    },
    {
        key: ExtensionBoatmasterType.SmallLicence,
        text: "smallLicence",
        value: ExtensionBoatmasterType.SmallLicence,
    },
    {
        key: ExtensionBoatmasterType.SportsLicence,
        text: "sportsLicence",
        value: ExtensionBoatmasterType.SportsLicence,
    },
    {
        key: ExtensionBoatmasterType.GovernmentLicence,
        text: "governmentLicence",
        value: ExtensionBoatmasterType.GovernmentLicence,
    },
];

export enum LinkedCertificateType {
    Unknown = -1,
    UcqBoatmaster = 1,
    BoatmasterOnTheRhine = 64,
    SchifferpatentA,
    SchifferpatentB,
    GrootVaarbewijsAVoorDeBinnenvaart,
    GrootVaarbewijsBVoorDeBinnenvaart,
    GrootVaarbewijsI,
    GrootVaarbewijsII,
    VaarbewijsAB,
    CertificatDeConduiteAB,
    Hochrheinpatent,
    SchiffsfuhrerzeugnisA,
    SchiffsfuhrerzeugnisB,
    KapitanszeugnisDerKlasseIB,
    SchiffsfuhrerzeugnisDerKategorieBCZ,
    SchiffsfuhrerzeugnisDerKlasseA,
    SchiffsfuhrerzeugnisDerKlasseB,
    SchiffsfuhrerzeugnisDerKategorieA,
    SchiffsfuhrerzeugnisDerKategorieBPL,
    SchiffsfuhrerzeugnisFurKapitaneDerKlasseA,
    SchiffsfuhrerzeugnisFurKapitaneDerKlasseIB,
    KapitanspatentA,
    KapitanspatentB,
    SchifferpatentFurDieBinnenschifffahrt,
}

export const linkedCertificateOptions: DropdownItemProps[] = [
    {
        key: LinkedCertificateType.UcqBoatmaster,
        text: "Schiffsführerin bzw. Schiffsführer",
        value: LinkedCertificateType.UcqBoatmaster,
        flag: "",
    },
    {
        key: LinkedCertificateType.BoatmasterOnTheRhine,
        text: "Rheinpatent",
        value: LinkedCertificateType.BoatmasterOnTheRhine,
        flag: "",
    },
    {
        key: LinkedCertificateType.SchifferpatentA,
        text: "Schifferpatent A",
        value: LinkedCertificateType.SchifferpatentA,
        flag: "de",
    },
    {
        key: LinkedCertificateType.SchifferpatentB,
        text: "Schifferpatent B",
        value: LinkedCertificateType.SchifferpatentB,
        flag: "de",
    },
    {
        key: LinkedCertificateType.GrootVaarbewijsAVoorDeBinnenvaart,
        text: "Groot vaarbewijs A voor de binnenvaart",
        value: LinkedCertificateType.GrootVaarbewijsAVoorDeBinnenvaart,
        flag: "nl",
    },
    {
        key: LinkedCertificateType.GrootVaarbewijsBVoorDeBinnenvaart,
        text: "Groot vaarbewijs B voor de binnenvaart",
        value: LinkedCertificateType.GrootVaarbewijsBVoorDeBinnenvaart,
        flag: "nl",
    },
    {
        key: LinkedCertificateType.GrootVaarbewijsI,
        text: "Groot vaarbewijs I",
        value: LinkedCertificateType.GrootVaarbewijsI,
        flag: "nl",
    },
    {
        key: LinkedCertificateType.GrootVaarbewijsII,
        text: "Groot vaarbewijs II",
        value: LinkedCertificateType.GrootVaarbewijsII,
        flag: "nl",
    },
    {
        key: LinkedCertificateType.VaarbewijsAB,
        text: "Vaarbewijs A/B",
        value: LinkedCertificateType.VaarbewijsAB,
        flag: "be",
    },
    {
        key: LinkedCertificateType.CertificatDeConduiteAB,
        text: "Certificat de conduite A/B",
        value: LinkedCertificateType.CertificatDeConduiteAB,
        flag: "be",
    },
    {
        key: LinkedCertificateType.Hochrheinpatent,
        text: "Hochrheinpatent",
        value: LinkedCertificateType.Hochrheinpatent,
        flag: "ch",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisA,
        text: "Schiffsführerzeugnis A",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisA,
        flag: "ro",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisB,
        text: "Schiffsführerzeugnis B",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisB,
        flag: "ro",
    },
    {
        key: LinkedCertificateType.KapitanszeugnisDerKlasseIB,
        text: "Kapitänszeugnis der Klasse I (B)",
        value: LinkedCertificateType.KapitanszeugnisDerKlasseIB,
        flag: "cz",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisDerKategorieBCZ,
        text: "Schiffsführerzeugnis der Kategorie B",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisDerKategorieBCZ,
        flag: "cz",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisDerKlasseA,
        text: "Schiffsführerzeugnis der Klasse A",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisDerKlasseA,
        flag: "hu",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisDerKlasseB,
        text: "Schiffsführerzeugnis der Klasse B",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisDerKlasseB,
        flag: "hu",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisDerKategorieA,
        text: "Schiffsführerzeugnis der Kategorie A",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisDerKategorieA,
        flag: "pl",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisDerKategorieBPL,
        text: "Schiffsführerzeugnis der Kategorie B",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisDerKategorieBPL,
        flag: "pl",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisFurKapitaneDerKlasseA,
        text: "Schiffsführerzeugnis für Kapitäne der Klasse A",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisFurKapitaneDerKlasseA,
        flag: "sk",
    },
    {
        key: LinkedCertificateType.SchiffsfuhrerzeugnisFurKapitaneDerKlasseIB,
        text: "Schiffsführerzeugnis für Kapitäne der Klasse I (B)",
        value: LinkedCertificateType.SchiffsfuhrerzeugnisFurKapitaneDerKlasseIB,
        flag: "sk",
    },
    {
        key: LinkedCertificateType.KapitanspatentA,
        text: "Kapitänspatent A",
        value: LinkedCertificateType.KapitanspatentA,
        flag: "at",
    },
    {
        key: LinkedCertificateType.KapitanspatentB,
        text: "Kapitänspatent B",
        value: LinkedCertificateType.KapitanspatentB,
        flag: "at",
    },
    {
        key: LinkedCertificateType.SchifferpatentFurDieBinnenschifffahrt,
        text: "Schifferpatent für die Binnenschifffahrt",
        value: LinkedCertificateType.SchifferpatentFurDieBinnenschifffahrt,
        flag: "bg",
    },
];

// helper methods

export const writeRadarpatentInfo = (radarCertificate: ICertificate): string => {
    return `Radar ${radarCertificate.qualificationID}, ${formatDateForCertificateView(
        radarCertificate.startDate
    )}, Basel`;
};

export const writeRhineCertificateRestrictions = (certificate: ICertificate): string => {
    const resultArray: string[] = [];
    const intl = createIntl({ messages: deMessages, locale: "de" });
    certificate.restrictions
        .sort((a, b) => a - b)
        .forEach(r => {
            let rest = r.toString().padStart(2, "0");
            if (r < 7) {
                const key = restrictionStatusOptions.find(x => x.value === r)?.text as string;
                const desc = intl.formatMessage({ id: key });
                const split = desc.split(" ");
                split.pop();
                rest += " " + split.join(" ");
            } else if (r === 7) rest += " " + certificate.limitedToOneCraftDescription;
            else if (r === 8) rest += " " + certificate.limitedAreaDescription;
            else if (r === 9) rest += " " + certificate.limitedTaskDescription;
            else if (r === 10) rest += " Bescheid mit Auflagen ist mitzuführen";
            resultArray.push(rest);
        });
    return resultArray.join(", ");
};

export const getIssuingCountryFromAuthority = (
    userAuthority: string,
    nationalityProps: ICountryNationalityProps[]
) => {
    if (!userAuthority) return -1;
    const authority = nationalityProps.find(x => x.text === userAuthority.substring(0, 2));
    if (!authority) return -1;
    else return authority.value as number;
};

export const getAvailableCertificateTypes = (
    intl: IntlShape,
    certificates: ICertificate[],
    cid: string
) => {
    // get only active or suspended certificates types for crew member
    const activeCertsTypes = certificates
        .filter(
            c => c.status === CertificateStatus.Active || c.status === CertificateStatus.Suspended
        )
        .flatMap(c => c.type);
    // filter out all active types from all types
    let availableTypes = certificateTypeOptions.filter(
        t => !activeCertsTypes.includes(t.value as number)
    );
    availableTypes = availableTypes.filter(item => item.key !== 73 && item.key !== 74);
    // filter all EU/UCQ types if no CID assigned to crew member
    if (!cid) {
        availableTypes = availableTypes.filter(
            x => (x.value as number) > CertificateType.UcqPassengerNavigationExpert
        );
    }
    // check for all boatmaster on the rhine subtypes
    const activeBoatmasterRhineTypes = certificates
        .filter(
            c =>
                c.type === CertificateType.BoatmasterOnTheRhine &&
                (c.status === CertificateStatus.Active || c.status === CertificateStatus.Suspended)
        )
        .map(c => c.rhineLicenceType);
    if (
        activeBoatmasterRhineTypes.length !==
            Object.values(RhineLicenceType).filter(x => typeof x === "number").length &&
        !availableTypes.some(x => x.value === CertificateType.BoatmasterOnTheRhine)
    ) {
        availableTypes.push({
            key: CertificateType.BoatmasterOnTheRhine,
            text: "boatmasterOnTheRhine",
            value: CertificateType.BoatmasterOnTheRhine,
        });
    }
    // check for all boatmaster on the high rhine subtypes
    const activeBoatmasterHighRhineTypes = certificates
        .filter(
            c =>
                c.type === CertificateType.BoatmasterOnTheHighRhine &&
                (c.status === CertificateStatus.Active || c.status === CertificateStatus.Suspended)
        )
        .map(c => c.highRhineLicenceType);
    if (
        activeBoatmasterHighRhineTypes.length !==
            Object.values(HighRhineLicenceType).filter(x => typeof x === "number").length &&
        !availableTypes.some(x => x.value === CertificateType.BoatmasterOnTheHighRhine)
    ) {
        availableTypes.push({
            key: CertificateType.BoatmasterOnTheHighRhine,
            text: "boatmasterOnTheHighRhine",
            value: CertificateType.BoatmasterOnTheHighRhine,
        });
    }
    // check for all extension subtypes
    const activeExtensionTypes = certificates
        .filter(
            c =>
                c.type === CertificateType.ExtensionBoatmasterOnTheRhine &&
                (c.status === CertificateStatus.Active || c.status === CertificateStatus.Suspended)
        )
        .map(c => c.extensionBoatmasterType);
    if (
        activeExtensionTypes.length !==
            Object.values(ExtensionBoatmasterType).filter(x => typeof x === "number").length &&
        !availableTypes.some(x => x.value === CertificateType.ExtensionBoatmasterOnTheRhine)
    ) {
        availableTypes.push({
            key: CertificateType.ExtensionBoatmasterOnTheRhine,
            text: "extensionBoatmasterOnTheRhine",
            value: CertificateType.ExtensionBoatmasterOnTheRhine,
        });
    }
    // remove LNG expert and passenger navigation expert from available types
    availableTypes = availableTypes.filter(
        x =>
            x.value !== CertificateType.UcqLngExpert &&
            x.value !== CertificateType.UcqPassengerNavigationExpert
    );
    return TranslateProps(intl, availableTypes);
};
