import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Segment, Input, Button, Select } from "semantic-ui-react";
import { DateInput } from "../../components/DateInput/DateInput";
import { TranslateProps } from "../../i18n/TranslateProps";
import { CertificateType, certificateTypeOptions } from "../../models/certificate";
import { constructDate, dateInit } from "../../models/common";
import { IIDDataSetFilter } from "../../models/crewMember";
import { AppState } from "../../store/configureStore";
import { QRScanner } from "./QRScanner";
import "./SearchPage.css";

type Props = {
    queryFilter: IIDDataSetFilter;
    setQueryFilter: Dispatch<SetStateAction<IIDDataSetFilter>>;
    fetchCrewMembers: (filter: IIDDataSetFilter, searchByCid: boolean) => void;
};

export const SearchPageFilters = ({ queryFilter, setQueryFilter, fetchCrewMembers }: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [modalOpen, setModalOpen] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(dateInit);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setQueryFilter({ ...queryFilter, [e.target.name]: e.target.value });

    const handleKeyPress = (e: any) => {
        if (e.key === "Enter" || e.charCode === 13) {
            e.target.id === "cid"
                ? fetchCrewMembers(queryFilter, true)
                : fetchCrewMembers(queryFilter, false);
        }
    };

    const certificateTypes = useMemo(() => {
        let result = certificateTypeOptions;
        if (!user.ecdbConnection) {
            result = result.filter(
                r => r.value && r.value >= (CertificateType.StretchesWithSpecificRisks as number) && r.value !== CertificateType.EU_SRB
            );
        }
        return result;
    }, [user.ecdbConnection]);

    useEffect(() => {
        setQueryFilter(s => ({ ...s, dateOfBirth: constructDate(dateOfBirth) }));
    }, [dateOfBirth, setQueryFilter]);

    return (
        <Segment className="grey-segment search-segment">
            <p>{intl.formatMessage({ id: "enterCriteria" })}:</p>
            {user.ecdbConnection && (
                <>
                    <section className="section-row">
                        <label>CID:</label>
                        <Input
                            id="cid"
                            name="cid"
                            value={queryFilter.cid}
                            onChange={handleInputChange}
                            onKeyPress={(e: any) => {
                                if (!/[0-9]/.test(e.key)) e.preventDefault();
                                queryFilter.cid.length === 8 && handleKeyPress(e);
                            }}
                            type="number"
                            autoComplete="off"
                        />
                    </section>
                    <section className="section-row">
                        <Button
                            icon="qrcode"
                            primary
                            content={intl.formatMessage({ id: "scanQRCode" })}
                            onClick={() => setModalOpen(true)}
                        />
                        <Button
                            primary
                            content={intl.formatMessage({ id: "search" })}
                            onClick={() => fetchCrewMembers(queryFilter, true)}
                            disabled={queryFilter.cid.length !== 8}
                        />
                    </section>
                    <div
                        className={`error-container ${
                            queryFilter.cid.length >= 1 && queryFilter.cid.length !== 8
                                ? "visibility"
                                : ""
                        }`}
                    >
                        <p className="error">{intl.formatMessage({ id: "cidFormatNotValid" })}</p>
                    </div>
                    <hr />
                </>
            )}
            <section className="section-row">
                <label>CH ID:</label>
                <Input
                    name="chId"
                    value={queryFilter.chId}
                    onChange={handleInputChange}
                    onKeyPress={(e: any) => queryFilter.chId.length > 0 && handleKeyPress(e)}
                    autoComplete="off"
                />
            </section>
            <section className="section-row">
                <label>{intl.formatMessage({ id: "firstName" })}:</label>
                <Input
                    name="firstName"
                    value={queryFilter.firstName}
                    onChange={handleInputChange}
                    onKeyPress={(e: any) => queryFilter.firstName.length > 0 && handleKeyPress(e)}
                    autoComplete="off"
                />
            </section>
            <section className="section-row">
                <label>{intl.formatMessage({ id: "lastName" })}:</label>
                <Input
                    name="lastName"
                    value={queryFilter.lastName}
                    onChange={handleInputChange}
                    onKeyPress={(e: any) => queryFilter.lastName.length > 0 && handleKeyPress(e)}
                    autoComplete="off"
                />
            </section>
            <section className="section-row">
                <label>{intl.formatMessage({ id: "dateOfBirth" })}:</label>
                <DateInput date={dateOfBirth} setDate={setDateOfBirth} clearable />
            </section>
            <section className="section-row">
                <label>{intl.formatMessage({ id: "placeOfBirth" })}:</label>
                <Input
                    name="placeOfBirth"
                    value={queryFilter.placeOfBirth}
                    onChange={handleInputChange}
                    onKeyPress={(e: any) =>
                        queryFilter.placeOfBirth.length > 0 && handleKeyPress(e)
                    }
                    autoComplete="off"
                />
            </section>
            <section className="section-row">
                <label>{intl.formatMessage({ id: "qualificationID" })}:</label>
                <Input
                    name="qualificationId"
                    value={queryFilter.qualificationId}
                    onChange={handleInputChange}
                    onKeyPress={(e: any) =>
                        queryFilter.qualificationId.length > 0 && handleKeyPress(e)
                    }
                    autoComplete="off"
                />
            </section>
            <section className="section-row">
                <label>{intl.formatMessage({ id: "certificateType" })}:</label>
                <Select
                    selectOnBlur={false}
                    search
                    fluid
                    options={TranslateProps(intl, certificateTypes)}
                    value={queryFilter.certificateType}
                    onChange={(e, data) => {
                        setQueryFilter({ ...queryFilter, certificateType: data.value as string });
                    }}
                    clearable
                />
            </section>
            <section className="section-row">
                <label></label>
                <Button
                    primary
                    content={intl.formatMessage({ id: "search" })}
                    onClick={() => fetchCrewMembers(queryFilter, false)}
                    disabled={
                        !Object.entries(queryFilter)
                            .filter(x => x[0] !== "cid" && x[0] !== "dateOfBirth")
                            .some(x => x[1] !== "") &&
                        queryFilter.dateOfBirth.split("/").every(x => x === "-1")
                    }
                />
            </section>
            <QRScanner open={modalOpen} close={() => setModalOpen(false)} />
        </Segment>
    );
};
