import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { loginRequest } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { RedirectIfLoggedIn } from "./RedirectIfLoggedIn";
import { LanguageSelector } from "../../components/LanguageSelector/LanguageSelector";
import { useIntl } from "react-intl";
import { AutoLogoutModal } from "./AutoLogoutModal";
import { toast } from "../..";
import { agent } from "../../api/agent";
import { ISsoSettings } from "../../models/authorization";
import { useLocation } from "react-router-dom";
import Logo from "../../images/port_of_ch_logo.png";
import * as Msal from "@azure/msal-browser";
import "./Login.css";

const loginRequestObj = {
    scopes: ["User.Read"],
};

export const Login = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const loginState = useSelector((state: AppState) => state.login);
    const location = useLocation();
    const disabled = loginState.currentlySending;

    const ssoString = sessionStorage.getItem("ssoSettings");
    const [ssoSettings, setSsoSettings] = useState<ISsoSettings>(ssoString && JSON.parse(ssoString));

    const msalInstance = useMemo(() => {
        if (ssoSettings) {
            const msalConfig = {
                auth: {
                    clientId: ssoSettings.clientId,
                    authority: `https://login.microsoftonline.com/${ssoSettings.tenantId}/`,
                },
            };
            const msal = new Msal.PublicClientApplication(msalConfig);
            msal.handleRedirectPromise().then(async res => {
                if (res !== null) {
                    dispatch(loginRequest({ token: res.idToken }));
                }
            });
            return msal;
        }
    }, [ssoSettings]);

    useEffect(() => {
        if (loginState.error?.message) {
            toast(loginState.error.message, false, 10000);
        }
    }, [loginState.error]);

    const getSsoSettings = useCallback(async () => {
        if (!ssoSettings) {
            const sso = await agent.Users.ssoSettings();
            setSsoSettings(sso);
            sessionStorage.setItem("ssoSettings", JSON.stringify(sso));
        }
    }, [ssoSettings, location]);

    useEffect(() => {
        getSsoSettings();
    }, [getSsoSettings]);

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <div className="login-wrapper">
                <img src={Logo} alt="Port of CH logo" />
                <div className="center-login-container">
                    <section className="left-container">
                        <div className="upper-container"></div>
                        <div className="lower-container"></div>
                    </section>
                    <section className="login-form-container">
                        {loginState.isEnabled ? (
                            <>
                                <LanguageSelector />
                                <Button
                                    id="login-button"
                                    fluid
                                    primary
                                    loading={loginState.currentlySending || !msalInstance}
                                    disabled={disabled}
                                    onClick={async () => {
                                        await msalInstance!.acquireTokenRedirect(loginRequestObj);
                                    }}
                                    content={intl.formatMessage({ id: "logIn" })}
                                />
                            </>
                        ) : (
                            <div id="login-locked-message">
                                <h4>{intl.formatMessage({ id: "yourAccountLocked" })}</h4>
                                <h4>{intl.formatMessage({ id: "followTheInstructions" })}</h4>
                            </div>
                        )}
                    </section>
                </div>
            </div>
            {localStorage.getItem("autoLogout") &&
                localStorage.getItem("autoLogout") === "true" && <AutoLogoutModal />}
        </div>
    );
};
