import { useIntl } from "react-intl";
import { Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { agent } from "../../api/agent";
import { ISRB } from "../../models/srb";

type Props = {
    srb: ISRB;
    open: boolean;
    close: () => void;
    setDeletedSrb: (guid: string) => void;
};

export const DeleteSRBModal = ({ open, close, srb, setDeletedSrb }: Props) => {
    const intl = useIntl();

    const handleDeleteSrb = async () => {
        try {
            await agent.ServiceRecordBooks.delete(srb.guid);
            toast(intl.formatMessage({ id: "srbDeleted" }), true);
            setDeletedSrb(srb.guid);
        } catch {
            toast(intl.formatMessage({ id: "unabletoDeleteSrb" }), false);
        } finally {
            close();
        }
    };

    return (
        <Modal open={open} onClose={() => close()} dimmer="blurring" size="tiny">
            <Modal.Header>{intl.formatMessage({ id: "areYouSureDeleteSrb" })}</Modal.Header>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => close()}
                    content={intl.formatMessage({ id: "cancel" })}
                />
                <Button
                    primary
                    onClick={() => handleDeleteSrb()}
                    content={intl.formatMessage({ id: "confirm" })}
                />
            </Modal.Actions>
        </Modal>
    );
};
