import { useSelector } from "react-redux";
import { ExtensionBoatmasterType, ICertificate } from "../../../models/certificate";
import { formatDateForCertificateView, Gender } from "../../../models/common";
import { IIDDataSet } from "../../../models/crewMember";
import { rhineRiverRangeProps, separateRiverRange } from "../../../models/riverRanges";
import { AppState } from "../../../store/configureStore";
import { SignatureImage } from "../../CrewMember/SignatureImage";
import stamp from "../../../images/SRH_stamp.png";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
};

export const ExtensionBoatmasterOnTheRhineView = ({ certificate, idDataSet }: Props) => {
    const nationalities = useSelector((state: AppState) => state.nationalities.nationalities);
    const nationality = nationalities.find(c => c.value === idDataSet.countryOfBirth)
        ?.text as string;

    return (
        <div id="extension-view">
            <h4 className="centered">Vorläufiges Rheinpatent</h4>
            <h4>Schweizerische Rheinhäfen</h4>
            <p>Ausstellende Behörde</p>
            <br />
            <p className="centered">
                <strong>Vorläufiges Rheinpatent</strong>
            </p>
            <p className="centered">
                (nur gültig im Zusammenhang mit einem Personalausweis oder Reisepass)
            </p>
            <br />
            <p className="centered">
                <strong>
                    <span
                        className={
                            certificate.extensionBoatmasterType !==
                            ExtensionBoatmasterType.GreatLicence
                                ? "crossed"
                                : ""
                        }
                    >
                        Grosses Patent
                    </span>
                    <sup>*)</sup>&nbsp;/&nbsp;
                    <span
                        className={
                            certificate.extensionBoatmasterType !==
                            ExtensionBoatmasterType.SmallLicence
                                ? "crossed"
                                : ""
                        }
                    >
                        Kleines Patent
                    </span>
                    <sup>*)</sup>
                    &nbsp;/
                </strong>
            </p>
            <p className="centered">
                <strong>
                    <span
                        className={
                            certificate.extensionBoatmasterType !==
                            ExtensionBoatmasterType.SportsLicence
                                ? "crossed"
                                : ""
                        }
                    >
                        Sportpatent
                    </span>
                    <sup>*)</sup>&nbsp;/&nbsp;
                    <span
                        className={
                            certificate.extensionBoatmasterType !==
                            ExtensionBoatmasterType.GovernmentLicence
                                ? "crossed"
                                : ""
                        }
                    >
                        Behördenpatent
                    </span>
                    <sup>*)</sup>
                </strong>
            </p>
            <br />
            <p className="content-row" style={{ marginBottom: 2 }}>
                <span>
                    <span className={idDataSet.gender === Gender.Male ? "crossed" : ""}>Frau</span>
                    &nbsp;/&nbsp;
                    <span className={idDataSet.gender === Gender.Female ? "crossed" : ""}>
                        Herr
                    </span>
                    <sup>*)</sup>
                </span>
                <strong>
                    {!!idDataSet.nonLatinLastName ? idDataSet.nonLatinLastName : idDataSet.lastName}
                </strong>
                <strong>
                    {!!idDataSet.nonLatinFirstName
                        ? idDataSet.nonLatinFirstName
                        : idDataSet.firstName}
                </strong>
                <span></span>
            </p>
            <p className="content-row">
                <label></label>
                <label>(Name)</label>
                <label>(Vorname)</label>
                <span></span>
            </p>
            <p className="content-row">
                <span>Geburtsdatum:</span>
                <strong>{formatDateForCertificateView(idDataSet.dateOfBirth)}</strong>
                <span></span>
                <span></span>
            </p>
            <p className="content-row">
                <span>Geburtsort:</span>
                <strong>
                    {!!idDataSet.nonLatinPlaceOfBirth
                        ? idDataSet.nonLatinPlaceOfBirth
                        : idDataSet.placeOfBirth}
                </strong>
                <span>Staat:</span>
                <strong>{nationality}</strong>
            </p>
            <br />
            <p>
                ist&nbsp;
                <span className={idDataSet.gender === Gender.Male ? "crossed" : ""}>Inhaberin</span>
                /
                <span className={idDataSet.gender === Gender.Female ? "crossed" : ""}>Inhaber</span>
                <sup>*)</sup>
                &nbsp;der oben angegebenen Rheinpatentart für den Streckenabschnitt von
            </p>
            <p className="content-row">{writeRiverKilometers(certificate.rhineRiverKilometers)}</p>
            <p>
                Dieses vorläufige Rheinpatent ist gültig bis zum Erhalt des Rheinpatentes, längstens
                aber 3 Monate nach seinem Ausstellungsdatum.
            </p>
            <br />
            <section className="section-right">
                <strong>Basel</strong>
                <label>(Ausstellungsort)</label>
                <strong>{formatDateForCertificateView(certificate.startDate)}</strong>
                <label>(Austellungsdatum)</label>
            </section>
            <p className="content-row">
                <section></section>
                <section>
                    <img alt="Port of Switzerland stamp" src={stamp} />
                </section>
            </p>
            <br />
            <p className="content-row">
                <section>
                    {idDataSet.signatureBase64 && (
                        <SignatureImage signatureBase64={idDataSet.signatureBase64} />
                    )}
                    <label>(Unterschrift der Inhaberin/des Inhabers)</label>
                </section>
                <section>
                    <br />
                    <p>__________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__________________</p>
                    <label>(Stempel/Unterschrift der ausstellenden Behörde)</label>
                </section>
            </p>
            <br />
            <br />
            <label>
                <sup>*)&nbsp;Nichtzutreffendes streichen</sup>
            </label>
        </div>
    );
};

const writeRiverKilometers = (rks: number[]) => {
    if (rks.length === 0) return "";
    const range = separateRiverRange(rks[0], rhineRiverRangeProps);
    return (
        <>
            <span>km</span>
            <strong>{range.from.toFixed(2)}</strong>
            <span>bis km</span>
            <strong>{range.to.toFixed(2)}</strong>
        </>
    );
};
