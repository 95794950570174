import { Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import {
    SET_USER,
    CLEAR_USER,
    SET_LANGUAGE,
    AuthentificationActionType,
    UserData,
    UserRole,
} from "../actions/authentificationActions";

const savedState: UserData | null =
    sessionStorage.getItem("user") !== null
        ? JSON.parse(sessionStorage.getItem("user") || "")
        : null;

const initialState: UserData = {
    guid: undefined,
    username: undefined,
    name: undefined,
    token: undefined,
    role: undefined,
    language: "de",
    authority: "",
    ecdbConnection: false,
    isProduction: false,
};

export const userRoleOptions: DropdownItemProps[] = [
    { key: UserRole.OfficeUser, text: "officeUser", value: UserRole.OfficeUser },
    {
        key: UserRole.EnforcementAuthorityUser,
        text: "enforcementAuthorityUser",
        value: UserRole.EnforcementAuthorityUser,
    },
    { key: UserRole.PowerUser, text: "powerUser", value: UserRole.PowerUser },
];

export const userReducer: Reducer<UserData> = (
    state: UserData = savedState || initialState,
    action: AuthentificationActionType
): UserData => {
    switch (action.type) {
        case SET_USER:
            return { ...action.user };
        case CLEAR_USER:
            return { ...initialState };
        case SET_LANGUAGE:
            return { ...state, language: action.language };
        default:
            return state;
    }
};

export default userReducer;
