import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Divider, Input, Message, Modal, Select, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { ISRB, newSrbInit, srbStatusOptions } from "../../models/srb";
import { agent } from "../../api/agent";
import { ICrewMember } from "../../models/crewMember";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { TranslateProps } from "../../i18n/TranslateProps";
import { srbFunctionInit, SrbFunctionType } from "../../models/srbFunction";
import { restrictionStatusOptions } from "../../models/certificate";
import { SRBFunctionForm } from "./SRBFunctionForm";
import { constructDate, dateInit, deconstructDate } from "../../models/common";
import { DateInput } from "../../components/DateInput/DateInput";

type Props = {
    open: boolean;
    close: () => void;
    fetchCrewMember: () => Promise<void>;
    crewMember: ICrewMember;
    srb?: ISRB;
    currentActiveSrb?: ISRB;
};

export const CHSRBFormModal = ({
    open,
    close,
    fetchCrewMember,
    crewMember,
    srb,
    currentActiveSrb,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [newSrb, setNewSrb] = useState(srb ? srb : { ...newSrbInit, chSrb: true });
    const [issueDate, setIssueDate] = useState(
        srb && deconstructDate(srb.issueDate).year !== 1 ? deconstructDate(srb.issueDate) : dateInit
    );
    const [restrictionsIssueDate, setRestrictionsIssueDate] = useState(
        srb && deconstructDate(srb.restrictionsIssueDate).year !== 1
            ? deconstructDate(srb.restrictionsIssueDate)
            : dateInit
    );
    const [restrictionsValidUntil, setRestrictionsValidUntil] = useState(
        srb && deconstructDate(srb.restrictionsValidUntil).year !== 1
            ? deconstructDate(srb.restrictionsValidUntil)
            : dateInit
    );
    const [loading, setLoading] = useState(false);
    const [duplicateFunctionsError, setDuplicateFunctionsError] = useState(false);

    const constructDates = () => {
        newSrb.issueDate = constructDate(issueDate);
        newSrb.restrictionsIssueDate = constructDate(restrictionsIssueDate);
        newSrb.restrictionsValidUntil = constructDate(restrictionsValidUntil);
    };

    const [srbSerialError, setSrbSerialError] = useState(false);

    const handleAddSRB = async () => {
        setLoading(true);
        const hasSerial = await agent.ServiceRecordBooks.checkSerialNumber(newSrb.serialNumber);
        if (hasSerial) {
            setSrbSerialError(hasSerial);
            setLoading(false);
            return;
        }
        try {
            constructDates();
            newSrb.crewMemberGuid = crewMember.guid;
            if (currentActiveSrb)
                newSrb.currentActiveSrbSerialNumber = currentActiveSrb.serialNumber;
            await agent.ServiceRecordBooks.create(newSrb);
            toast(intl.formatMessage({ id: "addedNewSRB" }), true);
            fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToCreateSRB" }), false);
        } finally {
            !hasSerial && close();
            setLoading(false);
        }
    };

    const handleEditSRB = async () => {
        setLoading(true);
        try {
            constructDates();
            await agent.ServiceRecordBooks.edit(newSrb);
            toast(intl.formatMessage({ id: "editedSRB" }), true);
            fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToEditSRB" }), false);
        } finally {
            close();
            setLoading(false);
        }
    };

    const handleDeleteSRB = (index: number) => {
        setNewSrb(prev => {
            prev.functions.splice(index, 1);
            return { ...prev };
        });
    };

    useEffect(() => {
        setNewSrb(s => ({ ...s, issuingAuthority: user.authority }));
    }, [user.authority]);

    useEffect(() => {
        const functionTypes = newSrb.functions.map(f => f.functionType);
        if (Array.from(new Set(functionTypes)).length !== functionTypes.length)
            setDuplicateFunctionsError(true);
        else setDuplicateFunctionsError(false);
    }, [newSrb.functions]);

    return (
        <Modal
            open={open}
            onClose={() => close()}
            dimmer="blurring"
            size="large"
            closeOnDimmerClick={!loading}
            className="srb-form-modal"
        >
            <Modal.Header>
                {srb ? intl.formatMessage({ id: "editSrb" }) : intl.formatMessage({ id: "addSrb" })}
            </Modal.Header>
            <Modal.Content>
                <div className="form-row two">
                    <section className="input-container">
                        <label>
                            {intl.formatMessage({ id: "serialNumber" })}
                            <span className="input-required">*</span>
                        </label>
                        <Input
                            value={newSrb.serialNumber ?? ""}
                            onChange={e => setNewSrb({ ...newSrb, serialNumber: e.target.value })}
                        />
                    </section>
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "status" })}</label>
                        <Select
                            options={TranslateProps(intl, srbStatusOptions)}
                            value={newSrb.status}
                            disabled
                        />
                    </section>
                </div>
                <div className="form-row two">
                    <section className="input-container">
                        <label>
                            {intl.formatMessage({ id: "issueDate" })}&nbsp;(
                            {intl.formatMessage({ id: "srbCombined" }).split("/")[0]})
                        </label>
                        <DateInput date={issueDate} setDate={setIssueDate} clearable />
                    </section>
                    <section className="input-container">
                        <label>
                            {intl.formatMessage({ id: "placeOfIssue" })}&nbsp;(
                            {intl.formatMessage({ id: "srbCombined" }).split("/")[0]})
                        </label>
                        <Input
                            value={newSrb.placeOfIssue ?? ""}
                            onChange={e => setNewSrb({ ...newSrb, placeOfIssue: e.target.value })}
                        />
                    </section>
                </div>
                <div className="form-row two">
                    <section className="input-container">
                        <label>
                            {intl.formatMessage({ id: "issueDate" })}&nbsp;(
                            {intl.formatMessage({ id: "restrictionsFitness" })})
                        </label>
                        <DateInput
                            date={restrictionsIssueDate}
                            setDate={setRestrictionsIssueDate}
                            clearable
                        />
                    </section>
                    <section className="input-container">
                        <label>
                            {intl.formatMessage({ id: "validUntil" })}&nbsp;(
                            {intl.formatMessage({ id: "restrictionsFitness" })})
                        </label>
                        <DateInput
                            date={restrictionsValidUntil}
                            setDate={setRestrictionsValidUntil}
                            clearable
                            extendedYears
                        />
                    </section>
                </div>
                <div className="form-row">
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "restrictions" })}</label>
                        <Select
                            search
                            multiple
                            closeOnChange
                            options={TranslateProps(intl, restrictionStatusOptions)}
                            value={newSrb.restrictions.sort()}
                            onChange={(e, data: any) =>
                                setNewSrb({ ...newSrb, restrictions: data.value.sort() })
                            }
                        />
                    </section>
                </div>
                <div className="form-row three">
                    {newSrb.restrictions.includes(7) && (
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "craftDesc" })}</label>
                            <Input
                                value={newSrb.limitedToOneCraftDescription}
                                onChange={e =>
                                    setNewSrb({
                                        ...newSrb,
                                        limitedToOneCraftDescription: e.target.value,
                                    })
                                }
                            />
                        </section>
                    )}
                    {newSrb.restrictions.includes(8) && (
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "areaDesc" })}</label>
                            <Input
                                value={newSrb.limitedAreaDescription}
                                onChange={e =>
                                    setNewSrb({
                                        ...newSrb,
                                        limitedAreaDescription: e.target.value,
                                    })
                                }
                            />
                        </section>
                    )}
                    {newSrb.restrictions.includes(9) && (
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "taskDesc" })}</label>
                            <Input
                                value={newSrb.limitedTaskDescription}
                                onChange={e =>
                                    setNewSrb({
                                        ...newSrb,
                                        limitedTaskDescription: e.target.value,
                                    })
                                }
                            />
                        </section>
                    )}
                </div>
                <div className="form-row">
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "comments" })}</label>
                        <TextArea
                            value={newSrb.comments ?? ""}
                            onChange={e => setNewSrb({ ...newSrb, comments: e.target.value })}
                            rows={3}
                        />
                    </section>
                </div>
                <Divider horizontal>{intl.formatMessage({ id: "functions" })}</Divider>
                {newSrb.functions.map((f, i) => (
                    <SRBFunctionForm
                        key={f.guid}
                        srbFunction={f}
                        setNewSrb={setNewSrb}
                        deleteSrbFunction={() => handleDeleteSRB(i)}
                    />
                ))}
                {duplicateFunctionsError && (
                    <Message
                        className="error-message"
                        icon="exclamation circle"
                        content={intl.formatMessage({ id: "datesCompareError" })}
                    />
                )}
                {srbSerialError && (
                    <Message
                        className="error-message"
                        icon="exclamation circle"
                        content={intl.formatMessage({ id: "srbSerialNumberError" })}
                    />
                )}
                <Button
                    content={intl.formatMessage({ id: "add" })}
                    basic
                    fluid
                    primary
                    onClick={() =>
                        setNewSrb({
                            ...newSrb,
                            functions: newSrb.functions.concat({
                                ...srbFunctionInit,
                                guid: Math.random().toString(),
                            }),
                        })
                    }
                    disabled={newSrb.functions.some(
                        f => f.functionType === SrbFunctionType.NotSpecified
                    )}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    fluid
                    primary
                    onClick={() => (srb ? handleEditSRB() : handleAddSRB())}
                    content={
                        srb
                            ? intl.formatMessage({ id: "saveChanges" })
                            : intl.formatMessage({ id: "addSrb" })
                    }
                    loading={loading}
                    disabled={
                        !newSrb.serialNumber ||
                        newSrb.functions.some(
                            f => f.functionType === SrbFunctionType.NotSpecified
                        ) ||
                        duplicateFunctionsError ||
                        loading
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
