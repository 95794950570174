import { Dispatch, Reducer } from "redux";
import { SET_SHAREPOINT_SETTINGS } from "../actions/sharepointSettingsActions";
import { agent } from "../api/agent";
import { ISharepointSettings } from "../models/sharepointSettings";

const initialState: ISharepointSettings = {
    siteUrl: "",
    listName: ""
};

export const sharepointSettingsReducer: Reducer<ISharepointSettings> = (
    state: ISharepointSettings = initialState,
    action: any
): ISharepointSettings => {
    switch (action.type) {
        case SET_SHAREPOINT_SETTINGS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export async function fetchSharepointSettings(dispatch: Dispatch) {
    try {
        const res = await agent.Sharepoint.getSettings();
        dispatch({
            type: "SET_SHAREPOINT_SETTINGS",
            payload: res as ISharepointSettings,
        });
    } catch {}
}