import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Select, DropdownItemProps, Input, Divider } from "semantic-ui-react";
import { DateInput } from "../../../components/DateInput/DateInput";
import { TranslateCountryProps, TranslateProps } from "../../../i18n/TranslateProps";
import {
    CertificateType,
    certificateTypeOptions,
    linkedCertificateOptions,
    getIssuingCountryFromAuthority,
    ICertificate,
    LinkedCertificateType,
} from "../../../models/certificate";
import { constructDate, dateInit, deconstructDate } from "../../../models/common";
import { AppState } from "../../../store/configureStore";

type Props = {
    certificate: ICertificate;
    setCertificate: Dispatch<SetStateAction<ICertificate>>;
    linkableCertificates: ICertificate[];
};

export const LinkedCertificateInputs = ({
    certificate,
    setCertificate,
    linkableCertificates,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const { countries, nationalities } = useSelector((state: AppState) => state.nationalities);
    const intl = useIntl();
    const [selectedLinkedCertificate, setSelectedLinkedCertificate] = useState<ICertificate>();
    const [linkedIssueDate, setLinkedIssueDate] = useState(
        certificate.linkedCertificateIssueDate
            ? deconstructDate(certificate.linkedCertificateIssueDate)
            : dateInit
    );

    const linkableCertificateOptions = useMemo(() => {
        return (
            [
                {
                    key: -1,
                    text: "N/A",
                    value: -1,
                },
            ] as DropdownItemProps[]
        ).concat(
            linkableCertificates
                .filter(c =>
                    certificate.type === CertificateType.StretchesWithSpecificRisks
                        ? c.type !== CertificateType.BoatmasterOnTheRhine &&
                          c.type !== CertificateType.BoatmasterOnTheHighRhine
                        : c
                )
                .map(c => {
                    return {
                        key: c.guid,
                        text: `${
                            TranslateProps(
                                intl,
                                certificateTypeOptions,
                                false,
                                c.type === 1 && c.qualificationID.includes("QEU")
                            )?.find(x => x.value === c.type)?.text as string
                        }
                         ( ${c.qualificationID})`,
                        value: c.guid,
                    };
                }) as DropdownItemProps[]
        );
    }, [certificate.type, intl, linkableCertificates]);

    const handleFillLinkedCertificateFields = (selectedGuid: string) => {
        const selected = linkableCertificates.find(c => c.guid === selectedGuid);
        if (selected) {
            setSelectedLinkedCertificate(selected);
            setCertificate({
                ...certificate,
                linkedCertificateIssueDate: selected.startDate,
                linkedCertificateType:
                    selected.type === CertificateType.UcqBoatmaster
                        ? LinkedCertificateType.UcqBoatmaster
                        : selected.type === CertificateType.BoatmasterOnTheRhine
                        ? LinkedCertificateType.BoatmasterOnTheRhine
                        : certificate.linkedCertificateType,
                linkedCertificateIssuingCountry: getIssuingCountryFromAuthority(
                    selected.issuingAuthority,
                    nationalities
                ),
                linkedCertificateQualificationID: selected.qualificationID,
            });
            setLinkedIssueDate(deconstructDate(selected.startDate));
        } else {
            setSelectedLinkedCertificate(undefined);
            setCertificate({
                ...certificate,
                linkedCertificateIssueDate: "",
                linkedCertificateType: -1,
                linkedCertificateIssuingCountry: -1,
                linkedCertificateQualificationID: "",
            });
            setLinkedIssueDate(dateInit);
        }
    };

    useEffect(() => {
        if (linkedIssueDate.day > -1 && linkedIssueDate.month > -1 && linkedIssueDate.year > -1)
            setCertificate(c => ({
                ...c,
                linkedCertificateIssueDate: constructDate(linkedIssueDate),
            }));
    }, [linkedIssueDate, setCertificate]);

    return (
        <>
            <Divider horizontal>
                {intl.formatMessage({ id: "linkedCertificate" })}&nbsp;
                {intl.formatMessage({ id: "additionalInformation" })}
            </Divider>
            <div className="form-row five">
                {!user.isProduction && (
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "linkedCertificate" })}</label>
                        <Select
                            placeholder="N/A"
                            selectOnBlur={false}
                            search
                            options={linkableCertificateOptions}
                            value={selectedLinkedCertificate?.guid}
                            text={
                                selectedLinkedCertificate
                                    ? (TranslateProps(
                                          intl,
                                          certificateTypeOptions,
                                          false,
                                          selectedLinkedCertificate.type === 1 &&
                                              selectedLinkedCertificate.qualificationID.includes(
                                                  "QEU"
                                              )
                                      )?.find(x => x.value === selectedLinkedCertificate.type)
                                          ?.text as string)
                                    : ""
                            }
                            onChange={(_e, data: any) =>
                                handleFillLinkedCertificateFields(data.value)
                            }
                            noResultsMessage={<></>}
                        />
                    </section>
                )}
                <section className="input-container">
                    <label>
                        {intl.formatMessage({ id: "issueDate" })}
                        <span className="input-required">*</span>
                    </label>
                    <DateInput
                        date={linkedIssueDate}
                        setDate={setLinkedIssueDate}
                        disabled={selectedLinkedCertificate !== undefined}
                    />
                </section>
                <section className="input-container">
                    <label>
                        {intl.formatMessage({ id: "type" })}
                        <span className="input-required">*</span>
                    </label>
                    <Select
                        search
                        selectOnBlur={false}
                        options={linkedCertificateOptions}
                        value={certificate.linkedCertificateType}
                        onChange={(_e, data) => {
                            const type = linkedCertificateOptions.find(x => x.value === data.value);
                            if (type) {
                                const flag = type.flag?.toString().toUpperCase() ?? "";
                                const country = nationalities.find(x => x.text === flag);
                                setCertificate({
                                    ...certificate,
                                    linkedCertificateType: data.value as LinkedCertificateType,
                                    linkedCertificateIssuingCountry: country
                                        ? (country.value as number)
                                        : -1,
                                });
                            }
                        }}
                        disabled={selectedLinkedCertificate !== undefined}
                    />
                </section>
                <section className="input-container">
                    <label>
                        {intl.formatMessage({ id: "issuingCountry" })}
                        <span className="input-required">*</span>
                    </label>
                    <Select
                        search
                        selectOnBlur={false}
                        options={TranslateCountryProps(countries, intl.locale)}
                        value={certificate.linkedCertificateIssuingCountry}
                        onChange={(_e, data: any) =>
                            setCertificate({
                                ...certificate,
                                linkedCertificateIssuingCountry: data.value,
                            })
                        }
                        disabled={selectedLinkedCertificate !== undefined}
                    />
                </section>
                <section className="input-container">
                    <label>
                        {intl.formatMessage({ id: "qualificationID" })}
                        <span className="input-required">*</span>
                    </label>
                    <Input
                        value={certificate.linkedCertificateQualificationID}
                        onChange={e =>
                            setCertificate({
                                ...certificate,
                                linkedCertificateQualificationID: e.target.value,
                            })
                        }
                        disabled={selectedLinkedCertificate !== undefined}
                    />
                </section>
            </div>
        </>
    );
};
