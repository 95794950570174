import { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

export const AutoLogoutModal = () => {
    const [open, setOpen] = useState(true);
    const intl = useIntl();

    const closeModal = () => {
        setOpen(false);
        localStorage.setItem("autoLogout", "false");
    };

    return (
        <Modal
            closeOnDimmerClick={false}
            closeOnEscape={false}
            dimmer="blurring"
            open={open}
            onClose={closeModal}
            size="tiny"
        >
            <Modal.Header>{intl.formatMessage({ id: "sessionExpired" })}</Modal.Header>
            <Modal.Actions>
                <Button
                    primary
                    onClick={closeModal}
                    content={intl.formatMessage({ id: "confirm" })}
                    fluid
                />
            </Modal.Actions>
        </Modal>
    );
};
