import { useIntl } from "react-intl";
import { Segment, Table } from "semantic-ui-react";
import { IStatistics } from "../../models/statistics";

type Props = {
    title: string;
    statistics: IStatistics;
    period: string;
};

export const StatisticsTable = ({ title, statistics, period }: Props) => {
    const intl = useIntl();

    return (
        <section>
            <div className="container-header">
                <h2>
                    {title}&nbsp;{period}
                </h2>
            </div>
            <Segment>
                <Table basic="very" compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>
                                {intl.formatMessage({ id: "certificateType" })}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>
                                {intl.formatMessage({ id: "count" })}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Object.entries(statistics).map(e => (
                            <Table.Row key={e[0]}>
                                <Table.Cell>{intl.formatMessage({ id: e[0] })}</Table.Cell>
                                <Table.Cell>{e[1]}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
        </section>
    );
};
