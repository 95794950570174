import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Select } from "semantic-ui-react";
import { birthYearsProps, daysProps, IDate, monthsProps, yearsProps } from "../../models/common";
import { TranslateProps } from "../../i18n/TranslateProps";
import { useIntl } from "react-intl";

type Props = {
    date: IDate;
    setDate: Dispatch<SetStateAction<IDate>>;
    setAdditionalDate?: Dispatch<SetStateAction<IDate>>;
    disabled?: boolean;
    selectOnBlur?: boolean;
    extendedYears?: boolean;
    clearable?: boolean;
};

export const DateInput = ({
    date,
    setDate,
    setAdditionalDate,
    disabled,
    selectOnBlur = false,
    extendedYears,
    clearable,
}: Props) => {
    const intl = useIntl();
    const [daysPropsPerMonth, setDaysPropsPerMonth] = useState(daysProps);
    const selectedYearsProps = extendedYears ? yearsProps : birthYearsProps;

    useEffect(() => {
        // black magic for limiting selectable day per selected month
        const { day, month, year } = date;
        let maxDay = 0;
        if (month > 0) {
            switch (month) {
                case 2:
                    if (year % 4 === 0) maxDay = 29;
                    else maxDay = 28;
                    setDaysPropsPerMonth(daysProps.slice(0, -(31 - maxDay)));
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    maxDay = 30;
                    setDaysPropsPerMonth(daysProps.slice(0, -(31 - maxDay)));
                    break;
                default:
                    maxDay = 31;
                    setDaysPropsPerMonth(daysProps);
                    break;
            }
            if (day > maxDay) setDate(s => ({ ...s, day: maxDay }));
        }
    }, [date, setDate]);

    useEffect(() => {
        if (!date.day) setDate({ ...date, day: -1 });
        if (!date.month) setDate({ ...date, month: -1 });
        if (!date.year) setDate({ ...date, year: -1 });
    }, [date, setDate]);

    return (
        <div className="date-select">
            <Select
                selectOnBlur={selectOnBlur}
                clearable={clearable && date.day !== -1}
                className="date-short"
                search
                options={daysPropsPerMonth}
                value={date.day}
                onChange={(e, data: any) => {
                    setDate({ ...date, day: data.value });
                    setAdditionalDate && setAdditionalDate(s => ({ ...s, day: data.value }));
                }}
                disabled={disabled}
            />
            <Select
                selectOnBlur={selectOnBlur}
                clearable={clearable && date.month !== -1}
                className="date-long"
                search
                options={TranslateProps(intl, monthsProps)}
                value={date.month}
                onChange={(e, data: any) => {
                    setDate({ ...date, month: data.value });
                    setAdditionalDate && setAdditionalDate(s => ({ ...s, month: data.value }));
                }}
                disabled={disabled}
            />
            <Select
                selectOnBlur={selectOnBlur}
                clearable={clearable && date.year !== -1}
                className="date-short"
                search
                options={selectedYearsProps}
                value={date.year}
                onChange={(e, data: any) => {
                    setDate({ ...date, year: data.value });
                    setAdditionalDate && setAdditionalDate(s => ({ ...s, year: data.value }));
                }}
                disabled={disabled}
            />
        </div>
    );
};
