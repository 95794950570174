import { DropdownItemProps } from "semantic-ui-react";
import { AuthorizationType } from "./authorization";
import { CertificateType, ICertificate } from "./certificate";

export interface IRiverRangeProps extends DropdownItemProps {
    from: number;
    to: number;
}

export const ucqRiverRangeProps: DropdownItemProps[] = [
    { key: 0, text: "RH-0166-0432", value: 0 },
    { key: 1, text: "RH-0166-0506", value: 1 },
    { key: 2, text: "RH-0166-1035", value: 2 },
    { key: 3, text: "RH-0412-1035", value: 3 },
    { key: 4, text: "RH-0493-1035", value: 4 },
    { key: 5, text: "RH-0334-0432", value: 5 },
    { key: 6, text: "RH-0334-0506", value: 6 },
    { key: 7, text: "RH-0412-0858", value: 7 },
    { key: 8, text: "RH-0493-0858", value: 8 },
    { key: 9, text: "RH-0335-0352", value: 9 },
    { key: 10, text: "RH-0352-0425", value: 10 },
    { key: 11, text: "RH-0425-0498", value: 11 },
    { key: 12, text: "RH-0498-0592", value: 12 },
    { key: 13, text: "RH-0592-0769", value: 13 },
    { key: 14, text: "RH-0769-0858", value: 14 },
];

export const stretchesRiverRangeProps: IRiverRangeProps[] = [
    {
        key: 0,
        text: "Iffezheim (km 334.00) - Mannheim (km 432.00)",
        value: 0,
        from: 334.0,
        to: 432.0,
    },
    {
        key: 1,
        text: "Iffezheim (km 334.00) - Mainz (km 506.00)",
        value: 1,
        from: 334.0,
        to: 506.0,
    },
    {
        key: 2,
        text: "Iffezheim (km 334.00) - Spyck'sche Fähre (km 857.40)",
        value: 2,
        from: 334.0,
        to: 857.4,
    },
    {
        key: 3,
        text: "Mannheim (km 412.00) - Spyck'sche Fähre (km 857.40)",
        value: 3,
        from: 412.0,
        to: 857.4,
    },
    {
        key: 4,
        text: "Mainz (km 493.00) - Spyck'sche Fähre (km 857.40)",
        value: 4,
        from: 493.0,
        to: 857.4,
    },
];

export const rhineRiverRangeProps: IRiverRangeProps[] = [
    {
        key: 0,
        text: "Basel (km 166.53) - Mannheim (km 432.00)",
        value: 0,
        from: 166.53,
        to: 432,
    },
    {
        key: 1,
        text: "Basel (km 166.53) - Mainz (km 506.00)",
        value: 1,
        from: 166.53,
        to: 506,
    },
    {
        key: 2,
        text: "Basel (km 166.53) - offenes Meer (km 1035.40)",
        value: 2,
        from: 166.53,
        to: 1035.4,
    },
    {
        key: 3,
        text: "Mannheim (km 412.00) - offenes Meer (km 1035.40)",
        value: 3,
        from: 412,
        to: 1035.4,
    },
    {
        key: 4,
        text: "Mainz (km 493.00) - offenes Meer (km 1035.40)",
        value: 4,
        from: 493,
        to: 1035.4,
    },
    // EXTENSIONS
    {
        key: 5,
        text: "Iffezheim (km 334.00) - Mannheim (km 432.00)",
        value: 5,
        from: 334,
        to: 432,
    },
    {
        key: 6,
        text: "Iffezheim (km 334.00) - Mainz (km 506.00)",
        value: 6,
        from: 334,
        to: 506,
    },
    {
        key: 7,
        text: "Mannheim (km 412.00) - Spyck'sche Fähre (km 857.40)",
        value: 7,
        from: 412,
        to: 857.4,
    },
    {
        key: 8,
        text: "Mainz (km 493.00) - Spyck'sche Fähre (km 857.40)",
        value: 8,
        from: 493,
        to: 857.4,
    },
    // DISABLED (INITIAL)
    {
        key: 9,
        text: "Basel (km 166.53) - Duisburg (km 0.00)",
        value: 9,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 10,
        text: "Basel (km 166.53) - Iffezheim (km 334.00)",
        value: 10,
        from: 166.53,
        to: 334,
    },
    {
        key: 11,
        text: "Basel (km 166.53) - Kembs (km 0.00)",
        value: 11,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 12,
        text: "Basel (km 166.53) - Ruhrort (km 0.00)",
        value: 12,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 13,
        text: "Basel (km 166.53) - Spyck'sche Fähre (km 857.40)",
        value: 13,
        from: 166.53,
        to: 857.4,
    },
    {
        key: 14,
        text: "Basel (km 166.53) - Strassburg (km 0.00)",
        value: 14,
        from: 166.53,
        to: 0,
    },
    {
        key: 15,
        text: "Basel (km 166.53) - Vogelgrün (km 0.00)",
        value: 15,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 16,
        text: "Duisburg (km 0.00) - offenes Meer (km 1035.40)",
        value: 16,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 17,
        text: "Karlsruhe (km 0.00) - offenes Meer (km 1035.40)",
        value: 17,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 18,
        text: "Koblenz (km 0.00) - offenes Meer (km 1035.40)",
        value: 18,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 19,
        text: "Köln (km 0.00) - offenes Meer (km 1035.40)",
        value: 19,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 20,
        text: "Mannheim (km 412.00) - Leverkusen (km 700.00)",
        value: 20,
        from: 412,
        to: 700,
    },
    {
        key: 21,
        text: "Strassburg (km 0.00) - offenes Meer (km 1035.40)",
        value: 21,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 22,
        text: "Strassburg (km 0.00) - Spyck'sche Fähre (km 857.40)",
        value: 22,
        from: 0.0,
        to: 857.4,
    },
    {
        key: 23,
        text: "unbekannt (km 0.00) - unbekannt (km 0.00)",
        value: 23,
        from: 0.0,
        to: 0.0,
    },
    {
        key: 24,
        text: "Vogelgrün (km 0.00) - Spyck'sche Fähre (km 857.40)",
        value: 24,
        from: 0.0,
        to: 857.4,
    },
    // DISABLED (EXTENSIONS)
    {
        key: 25,
        text: "Basel (km 166.53) - Breisach (km 0.00)",
        value: 25,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 26,
        text: "Basel (km 166.53) - Greffern (km 0.00)",
        value: 26,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 27,
        text: "Basel (km 166.53) - Ichenheim (km 0.00)",
        value: 27,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 28,
        text: "Basel (km 166.53) - Illingen (km 0.00)",
        value: 28,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 29,
        text: "Basel (km 166.53) - Karlsruhe (km 0.00)",
        value: 29,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 30,
        text: "Basel (km 166.53) - Kehl (km 0.00)",
        value: 30,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 31,
        text: "Basel (km 166.53) - Koblenz (km 0.00)",
        value: 31,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 32,
        text: "Basel (km 166.53) - Köln (km 0.00)",
        value: 32,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 33,
        text: "Basel (km 166.53) - Lauterburg (km 0.00)",
        value: 33,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 34,
        text: "Basel (km 166.53) - Steinmauern (km 0.00)",
        value: 34,
        from: 166.53,
        to: 0.0,
    },
    {
        key: 35,
        text: "Bingen (km 0.00) - offenes Meer (km 1035.40)",
        value: 35,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 36,
        text: "Bislich (km 0.00) - offenes Meer (km 1035.40)",
        value: 36,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 37,
        text: "Breisach (km 0.00) - Iffezheim (km 334.00)",
        value: 37,
        from: 0.0,
        to: 334,
    },
    {
        key: 38,
        text: "Iffezheim (km 334.00) - Karlsruhe (km 0.00)",
        value: 38,
        from: 334,
        to: 0.0,
    },
    {
        key: 39,
        text: "Iffezheim (km 334.00) - Spyck'sche Fähre (km 857.40)",
        value: 39,
        from: 334,
        to: 857.4,
    },
    {
        key: 40,
        text: "Kehl (km 0.00) - offenes Meer (km 1035.40)",
        value: 40,
        from: 0.0,
        to: 1035.4,
    },
    {
        key: 41,
        text: "Strassburg (km 0.00) - Iffezheim (km 334.00)",
        value: 41,
        from: 0.0,
        to: 334,
    },
    {
        key: 42,
        text: "Iffezheim (km 334.00) - offenes Meer (km 1035.40)",
        value: 42,
        from: 334.0,
        to: 1035.4,
    },
];

export const highRhineRiverRangeProps: IRiverRangeProps[] = [
    {
        key: 0,
        text: "Basel (km 166.53) - unterer Vorhafen der Schleuse Augst (km 155.85)",
        value: 0,
        from: 166.53,
        to: 155.85,
    },
    {
        key: 1,
        text: "Basel (km 166.53) - Rheinfelden (km 149.10)",
        value: 1,
        from: 166.53,
        to: 149.1,
    },
    // DISABLED (old)
    {
        key: 2,
        text: "unterer Vorhafen der Schleuse Augst (km 155.85) - Rheinfelden (km 149.10)",
        value: 2,
        from: 155.85,
        to: 149.1,
    },
];

export const separateRiverRange = (
    value: number,
    props: IRiverRangeProps[]
): { from: number; to: number } => {
    const riverRange = props.find(r => r.value === value);
    if (!riverRange) return { from: 0.0, to: 0.0 };
    return { from: riverRange.from, to: riverRange.to };
};

export const getRhineRiverRangeProps = (current: number[], props: IRiverRangeProps[]) => {
    props = props.filter(x => (x.value as number) <= 8);
    if (current.length === 0) {
        // for first edition of boatmaster certificate
        props = props.map(r => {
            return { ...r, disabled: (r.value as number) > 4 };
        });
    } else {
        // for all new editions of boatmaster certificate
        props = props.map(r => {
            return { ...r, disabled: (r.value as number) <= 4 };
        });
        const currentStretch = { from: 0.0, to: 0.0 };
        current.forEach(x => {
            const pair = separateRiverRange(x, rhineRiverRangeProps);
            if (currentStretch.from === 0) currentStretch.from = pair.from;
            if (currentStretch.to === 0) currentStretch.to = pair.to;
            if (pair.from !== 0 && pair.from < currentStretch.from) currentStretch.from = pair.from;
            if (pair.to !== 0 && pair.to > currentStretch.to) currentStretch.to = pair.to;
        });
        //check
        props.forEach(r => {
            if (
                (r.from >= currentStretch.from && r.to <= currentStretch.to) ||
                r.to < currentStretch.from ||
                r.from > currentStretch.to
            )
                r.disabled = true;
        });
    }
    return props;
};

export const getBoatmasterMaxRiverRange = (certificate: ICertificate) => {
    let result = { from: 0.0, to: 0.0 };
    switch (certificate.type) {
        case CertificateType.UcqBoatmaster:
            const stretchesAuth = certificate.authorizations.find(
                a => a.type === AuthorizationType.S
            );
            if (!stretchesAuth || stretchesAuth.ucqRiverKilometers.length === 0) return "/";
            const stretches: string[] = [];
            stretchesAuth.ucqRiverKilometers.forEach(rk => {
                const ucqStretch = ucqRiverRangeProps.find(x => x.value === rk);
                if (ucqStretch) stretches.push(ucqStretch.text as string);
            });
            return stretches.join(", ");
        case CertificateType.BoatmasterOnTheRhine:
        case CertificateType.ExtensionBoatmasterOnTheRhine:
            if (certificate.rhineRiverKilometers.length === 0) return "/";
            certificate.rhineRiverKilometers.forEach(rk => {
                const pair = separateRiverRange(rk, rhineRiverRangeProps);
                if (result.from === 0) result.from = pair.from;
                if (result.to === 0) result.to = pair.to;
                if (pair.from !== 0 && pair.from < result.from) result.from = pair.from;
                if (pair.to !== 0 && pair.to > result.to) result.to = pair.to;
            });
            if (certificate.type === CertificateType.BoatmasterOnTheRhine) {
                if (result.from === 334.0) result.from = 166.53;
                if (result.to === 857.4) result.to = 1035.4;
            }
            return `km ${result.from.toFixed(2)} - km ${result.to.toFixed(2)}`;
        case CertificateType.BoatmasterOnTheHighRhine:
            if (certificate.highRhineRiverKilometers.length === 0) return "/";
            if (
                certificate.highRhineRiverKilometers.includes(0) &&
                certificate.highRhineRiverKilometers.includes(2)
            )
                result = separateRiverRange(1, highRhineRiverRangeProps);
            else if (certificate.highRhineRiverKilometers.includes(1))
                result = separateRiverRange(1, highRhineRiverRangeProps);
            else if (certificate.highRhineRiverKilometers.includes(0))
                result = separateRiverRange(0, highRhineRiverRangeProps);
            else {
                const reversed = certificate.highRhineRiverKilometers.sort().reverse();
                result = separateRiverRange(reversed[0], highRhineRiverRangeProps);
            }
            return `km ${result.from.toFixed(2)} - km ${result.to.toFixed(2)}`;
        default:
            return "/";
    }
};

export const getStretchesMaxRiverRange = (certificate: ICertificate) => {
    const ranges: string[] = [];
    let range = { from: 0.0, to: 0.0 };
    certificate.stretchesRiverKilometers.forEach(rk => {
        range = separateRiverRange(rk, stretchesRiverRangeProps);
        ranges.push(`km ${range.from.toFixed(2)} - km ${range.to.toFixed(2)}`);
    });
    return ranges;
};
