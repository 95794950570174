import { useEffect, useCallback, useState, useRef } from "react";
import { Loader, Message, Button } from "semantic-ui-react";
import { ICrewMember, IIDDataSet } from "../../models/crewMember";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CertificatesTable } from "../Certificates/CertificatesTable";
import { SRBTable } from "../SRB/SRBTable";
import { AuthorizationTable } from "../Authorizations/AuthorizationTable";
import { CrewMemberDetails } from "./CrewMemberDetails";
import { useIntl } from "react-intl";
import { toast } from "../..";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { agent } from "../../api/agent";
import { DeleteCrewMemberModal } from "./DeleteCrewMemberModal";
import { CertificateStatus, CertificateType, ICertificate } from "../../models/certificate";
import { IAuthorization } from "../../models/authorization";
import { ISRB } from "../../models/srb";
import "./CrewMember.css";

export const CrewMember = () => {
    const navigate = useNavigate();
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation() as any;
    const { guid } = useParams();
    const [loading, setLoading] = useState(false);
    const [crewMemberDetails, setCrewMemberDetails] = useState(() => {
        if (loc.state && loc.state.idDataSet) {
            const ecdbIdDataSet = loc.state.idDataSet as IIDDataSet;
            const ecdbCrewMember: ICrewMember = {
                guid: "ECDB_CREW_MEMBER",
                cid: ecdbIdDataSet.cid,
                chId: "",
                lastUpdated: ecdbIdDataSet.lastUpdated,
                idDataSets: ([] as IIDDataSet[]).concat(ecdbIdDataSet),
                certificates: [],
                authorizations: [],
                serviceRecordBooks: [],
                requestFeedbacks: [],
                ecdbAcknowledged: true,
            };
            return ecdbCrewMember;
        } else return undefined;
    });
    const [certificates, setCertificates] = useState<ICertificate[]>([]);
    const [authorizations, setAuthorizations] = useState<IAuthorization[]>([]);
    const [srbs, setSrbs] = useState<ISRB[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [fetchingEcdbResponses, setFetchingEcdbResponses] = useState(false);
    const requestId = useRef<string>(
        loc.state && !!loc.state.detailRequestId ? loc.state.detailRequestId : ""
    );
    const [deletedSrb, setDeletedSrb] = useState("");
    const intl = useIntl();

    const fetchCrewMember = useCallback(
        async (useLoading: boolean = true) => {
            useLoading && setLoading(true);
            try {
                const res = await agent.CrewMembers.getByGuid(guid ?? "");
                setCrewMemberDetails({
                    ...res,
                    certificates: [],
                    authorizations: [],
                    serviceRecordBooks: [],
                });
                updateStatesHelper(res);
                if (res.sharepointItemCreated) {
                    toast(intl.formatMessage({ id: "newDossierItemCreated" }), true);
                }
            } catch {
                toast(intl.formatMessage({ id: "unableToFetchCrewMemberDetails" }), false);
            } finally {
                setLoading(false);
            }
        },
        [guid, intl]
    );

    const handleSendCrewDetailRequestToECDB = useCallback(async () => {
        if (!!crewMemberDetails?.cid && !requestId.current) {
            setFetchingEcdbResponses(true);
            try {
                const targetRequestId = await agent.CrewMembers.sendDetailRequest(
                    crewMemberDetails.cid
                );
                requestId.current = targetRequestId.replace(/"/g, "").replace(/-/g, "");
            } catch {
                toast(intl.formatMessage({ id: "unableToFetchDataSets" }), false);
            }
        }
    }, [crewMemberDetails?.cid, intl]);

    const handleCrewDetailResponseFromECDB = useCallback(async () => {
        if (!requestId.current) await handleSendCrewDetailRequestToECDB();
        const res = await agent.CrewMembers.getDetailResponse(requestId.current);
        if (res) updateStatesHelper(res);
    }, [handleSendCrewDetailRequestToECDB]);

    useEffect(() => {
        if (loc.state && loc.state.idDataSet) {
            const ecdbIdDataSet = loc.state.idDataSet as IIDDataSet;
            agent.CrewMembers.getByCid(ecdbIdDataSet.cid).then(
                res => res && "guid" in res && navigate("/crew-members/" + res.guid)
            );
        } else {
            fetchCrewMember();
            const intervalId = setInterval(() => {
                fetchCrewMember(false);
            }, 15000);
            return () => clearInterval(intervalId);
        }
    }, [fetchCrewMember, loc.state, navigate]);

    useEffect(() => {
        if (user.ecdbConnection) {
            handleCrewDetailResponseFromECDB();
            let count = 1;
            const intervalId = setInterval(() => {
                if (count >= 24) {
                    setFetchingEcdbResponses(false);
                    clearInterval(intervalId);
                }
                if (!!requestId.current) handleCrewDetailResponseFromECDB();
                count++;
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [handleCrewDetailResponseFromECDB, user.ecdbConnection]);

    const updateStatesHelper = (data: ICrewMember) => {
        const { certificates, authorizations, serviceRecordBooks } = data;

        setCertificates(prevCerts => {
            const firstFetch: boolean = !prevCerts.length;
            certificates.forEach(c => {
                if (
                    !prevCerts.some(
                        p =>
                            p.qualificationID === c.qualificationID &&
                            p.type === c.type &&
                            p.rhineLicenceType === c.rhineLicenceType &&
                            p.highRhineLicenceType === c.highRhineLicenceType &&
                            p.extensionBoatmasterType === c.extensionBoatmasterType
                    )
                ) {
                    c.guid = c.issuingAuthority.includes("CH")
                        ? c.guid
                        : "ECDB_CERT_" + Math.random();
                    firstFetch ? prevCerts.push(c) : prevCerts.unshift(c);
                } else {
                    const i = prevCerts.findIndex(
                        p =>
                            p.qualificationID === c.qualificationID &&
                            p.type === c.type &&
                            p.rhineLicenceType === c.rhineLicenceType &&
                            p.highRhineLicenceType === c.highRhineLicenceType &&
                            p.extensionBoatmasterType === c.extensionBoatmasterType
                    );
                    if (i > -1) prevCerts[i] = { ...c };
                }
            });
            return [...prevCerts];
        });
        setAuthorizations(prevAuths => {
            const firstFetch: boolean = !prevAuths.length;
            authorizations.forEach(a => {
                if (!prevAuths.some(p => p.qualificationID === a.qualificationID)) {
                    a.guid = a.issuingAuthority.includes("CH")
                        ? a.guid
                        : "ECDB_AUTH_" + Math.random();
                    firstFetch ? prevAuths.push(a) : prevAuths.unshift(a);
                } else {
                    const i = prevAuths.findIndex(p => p.qualificationID === a.qualificationID);
                    if (i > -1) prevAuths[i] = { ...a };
                }
            });
            return [...prevAuths];
        });
        setSrbs(prevSrbs => {
            const firstFetch: boolean = !prevSrbs.length;
            serviceRecordBooks.forEach(s => {
                if (
                    !prevSrbs.some(p => p.serialNumber === s.serialNumber) &&
                    !prevSrbs.some(p => p.guid === s.guid)
                ) {
                    s.guid = s.issuingAuthority.includes("CH")
                        ? s.guid
                        : "ECDB_SRB_" + Math.random();
                    firstFetch ? prevSrbs.push(s) : prevSrbs.unshift(s);
                } else {
                    let i = prevSrbs.findIndex(p => p.serialNumber === s.serialNumber);
                    if (i === -1) i = prevSrbs.findIndex(p => p.guid === s.guid);
                    if (i > -1) prevSrbs[i] = { ...s };
                }
            });
            return [...prevSrbs];
        });
    };

    useEffect(() => {
        return () => {
            requestId.current = "";
        };
    }, []);

    useEffect(() => {
        if (deletedSrb) {
            setSrbs(prevSrbs => {
                prevSrbs = prevSrbs.filter(s => s.guid !== deletedSrb);
                return [...prevSrbs];
            });
            setDeletedSrb("");
        }
    }, [deletedSrb]);

    return (
        <div className="crew-member-container">
            <div className="container-header">
                <h1>{intl.formatMessage({ id: "viewCrewMemberDetails" })}</h1>
                {fetchingEcdbResponses && user.ecdbConnection && (
                    <strong id="ecdb-fetching-message">
                        <Loader active inline size="small" />
                        {intl.formatMessage({ id: "fetchingFromEcdb" })}
                    </strong>
                )}
                {user.role === UserRole.PowerUser &&
                    !crewMemberDetails?.guid.includes("ECDB_CREW_MEMBER") && (
                        <Button
                            negative
                            basic
                            content={intl.formatMessage({ id: "deleteCrewMemberData" })}
                            onClick={() => setModalOpen(true)}
                        />
                    )}
            </div>
            {loading ? (
                <Loader active inline="centered" size="big" />
            ) : crewMemberDetails ? (
                <div className="crew-member-details-container">
                    <CrewMemberDetails
                        crewMember={crewMemberDetails}
                        fetchCrewMember={() => fetchCrewMember()}
                    />
                    <CertificatesTable
                        crewMember={crewMemberDetails}
                        certificates={certificates}
                        fetchCrewMember={() => fetchCrewMember()}
                        dateOfBirth={crewMemberDetails.idDataSets[0].dateOfBirth}
                        authorizations={authorizations}
                    />
                    {user.ecdbConnection && (
                        <AuthorizationTable
                            authorizations={authorizations}
                            eligibleCertificates={certificates.filter(
                                c =>
                                    (c.status === CertificateStatus.Active ||
                                        c.status === CertificateStatus.Suspended) &&
                                    c.type === CertificateType.UcqBoatmaster
                            )}
                            crewMember={crewMemberDetails}
                            fetchCrewMember={() => fetchCrewMember()}
                        />
                    )}
                    <SRBTable
                        srbs={srbs}
                        setDeletedSrb={setDeletedSrb}
                        crewMember={crewMemberDetails}
                        fetchCrewMember={() => fetchCrewMember()}
                    />
                    <DeleteCrewMemberModal
                        open={modalOpen}
                        closeModal={() => setModalOpen(false)}
                        crewMemberGuid={crewMemberDetails.guid}
                    />
                </div>
            ) : (
                <Message
                    icon="info circle"
                    header={intl.formatMessage({ id: "crewMemberNotFound" })}
                />
            )}
        </div>
    );
};
