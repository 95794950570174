import { useState } from "react";
import { useIntl } from "react-intl";
import { Modal, Button } from "semantic-ui-react";
import { toast } from "../..";
import { agent } from "../../api/agent";

type Props = {
    open: boolean;
    close: () => void;
    fetchCrewMember: () => Promise<void>;
    crewMemberGuid: string;
};

export const ConnectWithECDBModal = ({ open, close, fetchCrewMember, crewMemberGuid }: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const handleConnectCrewMemberWithECDB = async () => {
        setLoading(true);
        try {
            await agent.CrewMembers.connectWithEcdb(crewMemberGuid);
            toast(intl.formatMessage({ id: "successConnectCrewMemberWithECDB" }), true);
            fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "failConnectCrewMemberWithECDB" }), false);
        } finally {
            setLoading(false);
            close();
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => close()}
            dimmer="blurring"
            size="tiny"
            closeOnDimmerClick={!loading}
        >
            <Modal.Header>
                {intl.formatMessage({ id: "areYouSureConnectCrewMemberWithECDB" })}
            </Modal.Header>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => close()}
                    content={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                />
                <Button
                    primary
                    onClick={() => handleConnectCrewMemberWithECDB()}
                    content={intl.formatMessage({ id: "confirm" })}
                    loading={loading}
                    disabled={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};
