import { Button, Modal } from "semantic-ui-react";
import { CertificateType, ICertificate } from "../../models/certificate";
import { IIDDataSet } from "../../models/crewMember";
import { EuropeanCertificateView } from "./CertificateViews/EuropeanCertificateView";
import { useIntl } from "react-intl";
import { StretchesCertificateView } from "./CertificateViews/StretchesCertificateView";
import { RadarpatentView } from "./CertificateViews/RadarpatentView";
import { HighRhineCertificateView } from "./CertificateViews/HighRhineCertificateView";
import { AdnCertificateView } from "./CertificateViews/AdnCertificateView";
import { BoatmasterOnTheRhineView } from "./CertificateViews/BoatmasterOnTheRhineView";
import { BoatmasterOnTheHighRhineView } from "./CertificateViews/BoatmasterOnTheHighRhineView";
import { ExtensionBoatmasterOnTheRhineView } from "./CertificateViews/ExtensionBoatmasterOnTheRhineView";
import "./ViewCertificateModal.css";
import { CCNRBoatmasterView } from "./CertificateViews/CCNRBoatmasterView";
import { OtherCertificateView } from "./CertificateViews/OtherCertificateView";

type Props = {
    open: boolean;
    close: () => void;
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    stretchesCertificates: ICertificate[]; // for display of related data in view
    radarCertificate?: ICertificate; // for boatmaster on the rhine view
    renew?: boolean;
    actions?: boolean;
    handleAddCertificate?: () => Promise<void>;
    handleRenewCertificate?: () => Promise<void>;
    handleEditCertificate?: () => Promise<void>;
    loading?: boolean;
    edit?: boolean;
    qrBase64: string;
    imageBase64: string;
};

export const ViewCertificateModal = ({
    open,
    close,
    certificate,
    idDataSet,
    stretchesCertificates,
    radarCertificate,
    renew,
    actions,
    handleAddCertificate,
    handleRenewCertificate,
    handleEditCertificate,
    loading,
    edit,
    qrBase64,
    imageBase64,
}: Props) => {
    const intl = useIntl();
    const modifyable =
        actions !== undefined &&
        handleAddCertificate !== undefined &&
        handleEditCertificate !== undefined &&
        handleRenewCertificate !== undefined;

    return (
        <Modal
            open={open}
            onClose={close}
            size="small"
            className="certificate-view-modal"
            dimmer="inverted"
            closeOnDimmerClick={!actions}
        >
            <Modal.Content className="certificate-view">
                {(() => {
                    if (
                        certificate.type === CertificateType.UcqBoatmaster &&
                        certificate.issuingAuthority === "CH01"
                    ) {
                        return (
                            <CCNRBoatmasterView
                                certificate={certificate}
                                idDataSet={idDataSet}
                                imageBase64={imageBase64 ?? ""}
                                qrBase64={qrBase64 ?? ""}
                            />
                        );
                    }
                    switch (certificate.type) {
                        case CertificateType.UcqBoatmaster:
                        case CertificateType.UcqLngExpert:
                        case CertificateType.UcqPassengerNavigationExpert:
                            return (
                                <EuropeanCertificateView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    modifyable={modifyable}
                                    qrBase64={qrBase64 ?? ""}
                                />
                            );
                        case CertificateType.StretchesWithSpecificRisks:
                            return (
                                <StretchesCertificateView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    stretchesCertificates={stretchesCertificates}
                                    renew={renew && !edit}
                                />
                            );
                        case CertificateType.Radarpatent:
                            return (
                                <RadarpatentView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    renew={renew && !edit}
                                />
                            );
                        case CertificateType.HighRhineCertificate:
                            return (
                                <HighRhineCertificateView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    renew={renew && !edit}
                                />
                            );
                        case CertificateType.AdnBescheinigung:
                            return (
                                <AdnCertificateView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    renew={renew && !edit}
                                />
                            );
                        case CertificateType.BoatmasterOnTheRhine:
                            return (
                                <BoatmasterOnTheRhineView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    radarCertificate={radarCertificate}
                                    renew={renew && !edit}
                                />
                            );
                        case CertificateType.BoatmasterOnTheHighRhine:
                            return (
                                <BoatmasterOnTheHighRhineView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                    radarCertificate={radarCertificate}
                                    renew={renew && !edit}
                                />
                            );
                        case CertificateType.ExtensionBoatmasterOnTheRhine:
                            return (
                                <ExtensionBoatmasterOnTheRhineView
                                    certificate={certificate}
                                    idDataSet={idDataSet}
                                />
                            );
                        case CertificateType.UcqHelmsman:
                        case CertificateType.UcqAbleBoatman:
                        case CertificateType.UcqBoatman:
                        case CertificateType.UcqDeckhand:
                        case CertificateType.UcqApprentice:
                            return <OtherCertificateView certificate={certificate} />;
                        default:
                            return (
                                <div className="default-certificate-view">
                                    <h3>{intl.formatMessage({ id: "noViewAvailable" })}</h3>
                                </div>
                            );
                    }
                })()}
            </Modal.Content>
            {modifyable && (
                <Modal.Actions>
                    <Button
                        negative
                        onClick={close}
                        content={intl.formatMessage({ id: "cancel" })}
                        disabled={loading}
                    />
                    <Button
                        primary
                        onClick={() =>
                            edit === true
                                ? handleEditCertificate()
                                : renew === true
                                ? handleRenewCertificate()
                                : handleAddCertificate()
                        }
                        content={intl.formatMessage({ id: "publish" })}
                        loading={loading}
                        disabled={loading}
                    />
                </Modal.Actions>
            )}
        </Modal>
    );
};
