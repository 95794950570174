import { useState } from "react";
import { Segment, Table, Button, Message, Popup } from "semantic-ui-react";
import { ICertificate } from "../../models/certificate";
import { useIntl } from "react-intl";
import { CertificateFormModal } from "./CertificateForm/CertificateFormModal";
import { CertificatesRow } from "./CertificatesRow";
import { UserRole } from "../../actions/authentificationActions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { ICrewMember } from "../../models/crewMember";
import { IAuthorization } from "../../models/authorization";

type Props = {
    crewMember: ICrewMember;
    certificates: ICertificate[];
    fetchCrewMember: () => Promise<void>;
    dateOfBirth: string;
    authorizations: IAuthorization[];
};

export const CertificatesTable = ({
    certificates,
    fetchCrewMember,
    dateOfBirth,
    crewMember,
    authorizations,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [addModalOpen, setAddModalOpen] = useState(false);

    return (
        <section>
            <div className="container-header">
                <h2>{intl.formatMessage({ id: "certificatesOfQualification" })}</h2>
                {user.role !== UserRole.EnforcementAuthorityUser &&
                crewMember.guid.includes("ECDB_CREW_MEMBER") ? (
                    <Popup
                        content={intl.formatMessage({ id: "crewMemberNotInDb" })}
                        position="left center"
                        flowing
                        trigger={
                            <Button
                                id="disabled-button"
                                primary
                                content={intl.formatMessage({ id: "issueNewCertificate" })}
                                onClick={() =>
                                    crewMember.guid.includes("ECDB_CREW_MEMBER")
                                        ? null
                                        : setAddModalOpen(true)
                                }
                            />
                        }
                    />
                ) : user.role !== UserRole.EnforcementAuthorityUser ? (
                    <Button
                        primary
                        content={intl.formatMessage({ id: "issueNewCertificate" })}
                        onClick={() => setAddModalOpen(true)}
                    />
                ) : null}
            </div>
            <Segment>
                {certificates.length === 0 ? (
                    <Message
                        icon="info circle"
                        header={intl.formatMessage({ id: "noResultsFound" })}
                    />
                ) : (
                    <Table basic="very" compact="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell singleLine width={1}>
                                    {intl.formatMessage({ id: "issuedBy" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "certificateType" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "type" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "rkmStretch" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "qualificationID" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "validUntil" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "status" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "actions" })}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {certificates.map(c => (
                                <CertificatesRow
                                    key={
                                        c.qualificationID +
                                        c.type +
                                        c.rhineLicenceType +
                                        c.highRhineLicenceType +
                                        c.extensionBoatmasterType
                                    }
                                    certificate={c}
                                    fetchCrewMember={fetchCrewMember}
                                    dateOfBirth={dateOfBirth}
                                    crewMember={crewMember}
                                    renewable={
                                        certificates
                                            .filter(
                                                x =>
                                                    x.type === c.type &&
                                                    x.rhineLicenceType === c.rhineLicenceType &&
                                                    x.highRhineLicenceType ===
                                                        c.highRhineLicenceType &&
                                                    x.extensionBoatmasterType ===
                                                        c.extensionBoatmasterType
                                            )
                                            .sort(
                                                (a, b) =>
                                                    new Date(b.added).getTime() -
                                                    new Date(a.added).getTime()
                                            )[0] === c
                                    }
                                    certificates={certificates}
                                    authorizations={authorizations}
                                    qrBase64={crewMember.qrBase64 ?? ""}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Segment>
            {addModalOpen && (
                <CertificateFormModal
                    open={addModalOpen}
                    close={() => setAddModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    dateOfBirth={dateOfBirth}
                    certificates={certificates}
                    crewMember={crewMember}
                    edit={false}
                />
            )}
        </section>
    );
};
