import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Label, Menu } from "semantic-ui-react";
import { logout, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { useState } from "react";
import { LanguageSelector } from "../../components/LanguageSelector/LanguageSelector";
import { useIntl } from "react-intl";
import { HeaderMobile } from "./HeaderMobile";
import Logo from "../../icons/logo_white_no_text.png";
import "./Header.css";

export const Header = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const user = useSelector((state: AppState) => state.user);
    const { count } = useSelector((state: AppState) => state.unreadMessages);
    const [hamburgerModalOpen, setHamburgerModalOpen] = useState(false);

    return (
        <header>
            <Menu secondary>
                <Menu.Item id="logo-with-text" as={Link} to="/" header>
                    <img src={Logo} alt="Logo" />
                </Menu.Item>
                <Menu.Item id="logo-without-text" as={Link} to="/" header>
                    <img src={Logo} alt="Logo" />
                </Menu.Item>
                <div className="header-items">
                    <Menu.Item
                        as={Link}
                        to="/search"
                        content={intl.formatMessage({ id: "searchCrewMember" })}
                    />
                    {(user.role === UserRole.PowerUser || user.role === UserRole.OfficeUser) && (
                        <Menu.Item
                            as={Link}
                            to="/statistics"
                            content={intl.formatMessage({ id: "statistics" })}
                        />
                    )}
                    {user.ecdbConnection &&
                        (user.role === UserRole.PowerUser || user.role === UserRole.OfficeUser) && (
                            <Menu.Item as={Link} to="/inbox">
                                {intl.formatMessage({ id: "inbox" })}
                                {count > 0 && (
                                    <Label floating circular>
                                        {count}
                                    </Label>
                                )}
                            </Menu.Item>
                        )}
                    {user.role === UserRole.PowerUser && (
                        <Menu.Item
                            as={Link}
                            to="/change-log"
                            content={intl.formatMessage({ id: "changeLog" })}
                        />
                    )}
                    {/*user.role === UserRole.PowerUser && (
                        <Menu.Item
                            as={Link}
                            to="/manage-users"
                            content={intl.formatMessage({ id: "userManagement" })}
                        />
                    )*/}
                    <Menu.Item position="right" disabled>
                        <Icon name="user circle" size="large" />
                        <span>{user.name}</span>
                    </Menu.Item>
                    <Menu.Item>
                        <LanguageSelector />
                    </Menu.Item>
                    <Menu.Item onClick={() => dispatch(logout())}>
                        <Icon name="sign-out" size="large" />
                        <span>{intl.formatMessage({ id: "logout" })}</span>
                    </Menu.Item>
                </div>
                <Menu.Item
                    className="hamburger-icon"
                    position="right"
                    onClick={() => setHamburgerModalOpen(true)}
                >
                    <Icon name="bars" size="large" />
                </Menu.Item>
            </Menu>
            <HeaderMobile
                open={hamburgerModalOpen}
                closeModal={() => setHamburgerModalOpen(false)}
                user={user}
            />
        </header>
    );
};
