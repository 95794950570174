import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Button, Input, Segment } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IChangeLogItem } from "../../models/changeLogItem";
import { agent } from "../../api/agent";
import { AppState } from "../../store/configureStore";
import { ChangeLogTable } from "./ChangeLogTable";
import "./ChangeLog.css";

export const ChangeLog = () => {
    const intl = useIntl();
    const user = useSelector((state: AppState) => state.user);
    const [items, setItems] = useState<IChangeLogItem[]>([]);
    const [totalRows, setTotalRows] = useState(-1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");

    const fetchChangeLogItems = useCallback(
        async (query: string) => {
            try {
                setItems([]);
                setTotalRows(-1);
                await new Promise(r => setTimeout(r, 100));
                const res = await agent.ChangeLogs.get(page, query);
                setItems(res.result as IChangeLogItem[]);
                setTotalRows(res.totalRows);
            } catch {
                toast(intl.formatMessage({ id: "unableToFetch" }), false);
            }
        },
        [page, intl]
    );

    const handleExportChangeLogs = async () => {
        setLoading(true);
        try {
            await agent.ChangeLogs.export();
        } catch {
            toast(intl.formatMessage({ id: "failedToExport" }), false);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: KeyboardEvent) =>
        e.key === "Enter" ? fetchChangeLogItems(query) : null;

    useEffect(() => {
        !query && fetchChangeLogItems("");
    }, [fetchChangeLogItems, query]);

    return user.role !== UserRole.PowerUser ? (
        <Navigate to="/unauthorized" />
    ) : (
        <div>
            <div className="container-header">
                <h1>{intl.formatMessage({ id: "changeLog" })}</h1>
                <Button
                    primary
                    content={intl.formatMessage({ id: "exportChangeLog" })}
                    onClick={() => handleExportChangeLogs()}
                    disabled={loading}
                    loading={loading}
                />
            </div>
            <section className="change-log-container">
                <Segment className="grey-segment">
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "search" })}</label>
                        <Input
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </section>
                    <Button
                        primary
                        content={intl.formatMessage({ id: "search" })}
                        onClick={() => fetchChangeLogItems(query)}
                        disabled={!query}
                    />
                </Segment>
                <CSSTransition in={totalRows > -1} timeout={100} classNames="fade" unmountOnExit>
                    <ChangeLogTable
                        items={items}
                        page={page}
                        setPage={setPage}
                        totalRows={totalRows}
                    />
                </CSSTransition>
            </section>
        </div>
    );
};
