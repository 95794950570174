import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { agent } from "../../api/agent";

type Props = {
    open: boolean;
    closeModal: () => void;
    crewMemberGuid: string;
};

export const DeleteCrewMemberModal = ({ open, closeModal, crewMemberGuid }: Props) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const handleDeleteCrewMember = async () => {
        try {
            await agent.CrewMembers.delete(crewMemberGuid);
            toast(intl.formatMessage({ id: "crewMemberDeleted" }), true);
            navigate("/");
        } catch {
            toast(intl.formatMessage({ id: "deleteCrewMember" }), false);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={() => closeModal()} dimmer="blurring" size="tiny">
            <Modal.Header>
                {intl.formatMessage({ id: "areYouSureDeleteCrewMemberData" })}
            </Modal.Header>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => closeModal()}
                    content={intl.formatMessage({ id: "cancel" })}
                />
                <Button
                    primary
                    onClick={() => handleDeleteCrewMember()}
                    content={intl.formatMessage({ id: "confirm" })}
                />
            </Modal.Actions>
        </Modal>
    );
};
