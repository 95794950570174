import { DropdownItemProps } from "semantic-ui-react";
import { IRequestFeedback } from "./requestFeedback";
import { ISRBFunction } from "./srbFunction";

export interface ISRB {
    guid: string;
    added: string;
    lastUpdated: string;
    serialNumber: string;
    issueDate: string;
    active: boolean;
    issuingAuthority: string;
    paperSrbLastIssueAuthority: string;
    paperSrbLastIssueDate: string;
    paperSrbLastNB: string;
    paperSrbNavigationDays: string;
    comments: string;
    reason: string;
    suspensionStartDate: string;
    suspensionEndDate: string;
    crewMemberCid: string;
    crewMemberGuid: string;
    currentActiveSrbSerialNumber: string;
    requestFeedbacks: IRequestFeedback[];
    ecdbAcknowledged: boolean;
    //CH fields
    chSrb: boolean;
    functions: ISRBFunction[];
    status: SrbStatus;
    restrictions: number[];
    limitedToOneCraftDescription: string;
    limitedAreaDescription: string;
    limitedTaskDescription: string;
    placeOfIssue: string;
    restrictionsIssueDate: string;
    restrictionsValidUntil: string;
}

export enum SrbStatus {
    Active = 1,
    Inactive = 2,
    Expired = 10,
    Suspended = 11,
    Withdrawn = 12,
    Lost = 90,
    Stolen = 91,
    Destroyed = 92,
}

export const newSrbInit: ISRB = {
    guid: "",
    added: "",
    lastUpdated: "",
    serialNumber: "",
    issueDate: "",
    active: true,
    issuingAuthority: "",
    paperSrbLastIssueAuthority: "",
    paperSrbLastIssueDate: "",
    paperSrbLastNB: "",
    paperSrbNavigationDays: "",
    comments: "",
    reason: "",
    suspensionStartDate: "",
    suspensionEndDate: "",
    crewMemberCid: "",
    crewMemberGuid: "",
    currentActiveSrbSerialNumber: "",
    requestFeedbacks: [],
    ecdbAcknowledged: false,
    //CH fields
    chSrb: false,
    functions: [],
    status: SrbStatus.Active,
    restrictions: [],
    limitedToOneCraftDescription: "",
    limitedAreaDescription: "",
    limitedTaskDescription: "",
    placeOfIssue: "",
    restrictionsIssueDate: "",
    restrictionsValidUntil: "",
};

export const srbActiveOptions: DropdownItemProps[] = [
    { key: true, text: "active", value: true },
    { key: false, text: "inactive", value: false },
];

export const srbStatusOptions: DropdownItemProps[] = [
    { key: SrbStatus.Active, text: "active", value: SrbStatus.Active },
    { key: SrbStatus.Inactive, text: "inactive", value: SrbStatus.Inactive },
    { key: SrbStatus.Destroyed, text: "destroyed", value: SrbStatus.Destroyed },
    { key: SrbStatus.Expired, text: "expired", value: SrbStatus.Expired },
    { key: SrbStatus.Lost, text: "lost", value: SrbStatus.Lost },
    { key: SrbStatus.Stolen, text: "stolen", value: SrbStatus.Stolen },
    { key: SrbStatus.Suspended, text: "suspended", value: SrbStatus.Suspended },
    { key: SrbStatus.Withdrawn, text: "withdrawn", value: SrbStatus.Withdrawn },
];
