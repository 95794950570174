import { useIntl } from "react-intl";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { toast } from "../..";

type Props = {
    open: boolean;
    close: () => void;
};

export const QRScanner = ({ open, close }: Props) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const handleError = (err: string) => {
        toast(intl.formatMessage({ id: "unableToReadQR" }), false);
        console.error(err);
        close();
    };

    const handleScan = (data: string | null) => {
        if (data) {
            const split = data.split("/");
            const cid = split[split.length - 1];
            navigate("/search", { state: cid });
            close();
        }
    };

    return (
        <Modal
            className="qr-modal"
            open={open}
            onClose={() => close()}
            dimmer="blurring"
            size="small"
            closeIcon
        >
            <Modal.Content>
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100%" }}
                />
            </Modal.Content>
        </Modal>
    );
};
