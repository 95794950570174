import { Dispatch, Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import { toast } from "..";
import { GET_NATIONALITIES } from "../actions/nationalitiesActions";
import { agent } from "../api/agent";

export interface ICountryNationalityProps extends DropdownItemProps {
    en: string;
    de: string;
}

export interface CountriesAndNationalitiesState {
    countries: ICountryNationalityProps[];
    nationalities: ICountryNationalityProps[];
}

const initialState: CountriesAndNationalitiesState = {
    countries: [],
    nationalities: [],
};

export const nationalitiesReducer: Reducer<CountriesAndNationalitiesState> = (
    state: CountriesAndNationalitiesState = initialState,
    action: any
): CountriesAndNationalitiesState => {
    switch (action.type) {
        case GET_NATIONALITIES:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchCountriesAndNationalities(dispatch: Dispatch) {
    try {
        const res = await agent.Users.getCountriesAndNationalities();
        const countries = res.countries.sort((a, b) =>
            (a.text as string).localeCompare(b.text as string)
        );
        const nationalities = res.nationalities.sort((a, b) =>
            (a.text as string).localeCompare(b.text as string)
        );
        dispatch({
            type: "GET_NATIONALITIES",
            payload: { countries, nationalities } as CountriesAndNationalitiesState,
        });
    } catch {
        toast("Unable to fetch countries and nationalities", false);
    }
}
