import { createRoot } from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { Login } from "./containers/Login/Login";
import { PrivateRoute } from "./containers/Login/PrivateRoute";
import { SetToastMessage, ShowToastMessage, HideToastMessage } from "./actions/toastMessageActions";
import ConnectedIntlProvider from "./ConnectedIntlProvider";
import { ToastMessage } from "./components/ToastMessage/ToastMessage";

const store = configureStore();

export const toast = (text: string, success: boolean, timeout: number = 5000) => {
    const dispatch = store.dispatch;
    dispatch(SetToastMessage(text, success));
    dispatch(ShowToastMessage());
    setTimeout(() => dispatch(HideToastMessage()), timeout);
};

const root = createRoot(document.getElementById("root")!);
root.render(
    <Provider store={store}>
        <ConnectedIntlProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<PrivateRoute path="/" component={App} />} />
                </Routes>
            </BrowserRouter>
            <ToastMessage />
        </ConnectedIntlProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
