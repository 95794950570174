import { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Segment, Table } from "semantic-ui-react";
import { TablePagination } from "../../components/TablePagination/TablePagination";
import { IInboxMessage } from "../../models/inboxMessage";
import { InboxTableRow } from "./InboxTableRow";

type Props = {
    inboxMessages: IInboxMessage[];
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    totalRows: number;
};

export const InboxTable = ({ inboxMessages, page, setPage, totalRows }: Props) => {
    const intl = useIntl();

    return (
        <Segment>
            <Table basic="very" compact="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>
                            {intl.formatMessage({ id: "dateOfReception" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell>{intl.formatMessage({ id: "message" })}</Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                            {intl.formatMessage({ id: "sender" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                            {intl.formatMessage({ id: "status" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                            {intl.formatMessage({ id: "viewDetails" })}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {inboxMessages.map(inboxMessage => (
                        <InboxTableRow
                            key={inboxMessage.guid}
                            inboxMessage={inboxMessage}
                            page={page}
                        />
                    ))}
                </Table.Body>
            </Table>
            <TablePagination page={page} setPage={setPage} totalRows={totalRows} />
        </Segment>
    );
};
