import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Button } from "semantic-ui-react";
import { IssuingAuthorityIndicator } from "../../components/IssuingAuthorityIndicator/IssuingAuthorityIndicator";
import { formatDate } from "../../models/common";
import { IIDDataSet } from "../../models/crewMember";
import { AppState } from "../../store/configureStore";

type Props = {
    idDataSet: IIDDataSet;
    detailRequestId: string;
};

export const SearchPageResultRow = ({ idDataSet, detailRequestId }: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();

    return (
        <Table.Row>
            <Table.Cell singleLine>
                <IssuingAuthorityIndicator issuingAuthority={idDataSet.issuingAuthority} />
            </Table.Cell>
            {user.ecdbConnection && (
                <Table.Cell singleLine>
                    {idDataSet.cid || <em>{intl.formatMessage({ id: "unassigned" })}</em>}
                </Table.Cell>
            )}
            <Table.Cell>{idDataSet.chId}</Table.Cell>
            <Table.Cell>
                {idDataSet.firstName} {idDataSet.lastName}
            </Table.Cell>
            <Table.Cell>{formatDate(idDataSet.dateOfBirth)}</Table.Cell>
            <Table.Cell>{idDataSet.placeOfBirth}</Table.Cell>
            <Table.Cell singleLine>
                <Button
                    as={Link}
                    to={{
                        pathname:
                            "/crew-members/" +
                            (idDataSet.guid.includes("ECDB_DATASET")
                                ? idDataSet.guid
                                : idDataSet.crewMemberGuid),
                    }}
                    state={{
                        detailRequestId: detailRequestId,
                        idDataSet: idDataSet.guid.includes("ECDB_DATASET")
                            ? idDataSet
                            : undefined,
                    }}
                    content={intl.formatMessage({ id: "viewDetails" })}
                    primary
                    basic
                    size="small"
                />
            </Table.Cell>
        </Table.Row>
    );
};
