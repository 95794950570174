import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { Modal, Button, Input, Select, Divider, TextArea, Icon, Radio } from "semantic-ui-react";
import { toast } from "../..";
import {
    getCroppedImg,
    ImageDropzoneCropper,
} from "../../components/Dropzone/ImageDropzoneCropper";
import { TranslateCountryProps, TranslateProps } from "../../i18n/TranslateProps";
import { constructDate, dateInit, deconstructDate, Gender, genderProps } from "../../models/common";
import { IIDDataSet, newIDDataSetInit } from "../../models/crewMember";
import { newEidasInformationInit } from "../../models/eidasInformation";
import { DateInput } from "../../components/DateInput/DateInput";
import { agent } from "../../api/agent";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { Crop } from "react-image-crop";

type Props = {
    open: boolean;
    closeModal: () => void;
    idDataSet?: IIDDataSet;
    fetchCrewMember?: () => Promise<void>;
};

export const CrewMemberFormModal = ({ open, closeModal, idDataSet, fetchCrewMember }: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const { countries, nationalities } = useSelector((state: AppState) => state.nationalities);
    const intl = useIntl();
    const navigate = useNavigate();
    const [newIdDataSet, setNewIdDataSet] = useState(
        idDataSet
            ? { ...idDataSet, removeImageBackground: true, removeSignatureBackground: true }
            : { ...newIDDataSetInit, issuingAuthority: user.authority }
    );
    const [loading, setLoading] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(
        idDataSet ? deconstructDate(idDataSet.dateOfBirth) : dateInit
    );
    const [imageFile, setImageFile] = useState<File>();
    const [imageCrop, setImageCrop] = useState<Crop>();
    const [signatureImageFile, setSignatureImageFile] = useState<File>();
    const [signatureImageCrop, setSignatureImageCrop] = useState<Crop>();

    const handleCloseModal = () => {
        setNewIdDataSet(idDataSet ? idDataSet : newIDDataSetInit);
        closeModal();
        setImageCrop(undefined);
        setSignatureImageCrop(undefined);
    };

    const handleAddCrewMember = async () => {
        setLoading(true);
        try {
            newIdDataSet.added = new Date().toISOString();
            newIdDataSet.dateOfBirth = constructDate(dateOfBirth);
            const img = await getCroppedImg(imageCrop, false);
            const sig = await getCroppedImg(signatureImageCrop, true);
            const res = await agent.CrewMembers.create(newIdDataSet, img, sig, false);
            const guid = res.replace(/"/g, "");
            toast(intl.formatMessage({ id: "createdCrewMember" }), true);
            navigate("/crew-members/" + guid);
        } catch {
            toast(intl.formatMessage({ id: "unableToCreateCrewMember" }), false);
        } finally {
            setLoading(false);
            handleCloseModal();
        }
    };

    const handleEditCrewMember = async () => {
        setLoading(true);
        try {
            newIdDataSet.dateOfBirth = constructDate(dateOfBirth);
            const img = await getCroppedImg(imageCrop, false);
            const sig = await getCroppedImg(signatureImageCrop, true);
            await agent.CrewMembers.edit(newIdDataSet, img, sig);
            toast(intl.formatMessage({ id: "crewMemberEdited" }), true);
            fetchCrewMember && (await fetchCrewMember());
        } catch {
            toast(intl.formatMessage({ id: "unableToEditCrewMember" }), false);
        } finally {
            setLoading(false);
            handleCloseModal();
        }
    };

    const handleEidasInformationInputChange = async (e: any, index: number) => {
        const newEidasInformation = [...newIdDataSet.eidasInformation];
        newEidasInformation[index].personIdentifier = e.target.value;
        setNewIdDataSet({ ...newIdDataSet, eidasInformation: [...newEidasInformation] });
    };

    const handleAddNewEidasInformation = async () => {
        setNewIdDataSet({
            ...newIdDataSet,
            eidasInformation: newIdDataSet.eidasInformation.concat({ ...newEidasInformationInit }),
        });
    };

    const createImageFile = async (url: string, signature: boolean) => {
        const res = await fetch(`${url}.jpg`);
        const data = await res.arrayBuffer();
        if (signature) setSignatureImageFile(new File([data], "sig.jpg", { type: "image/jpeg" }));
        else setImageFile(new File([data], "img.jpg", { type: "image/jpeg" }));
    };

    return (
        <Modal
            className="crew-member-modal"
            open={open}
            onClose={handleCloseModal}
            dimmer="inverted"
            closeOnDimmerClick={!loading}
            closeOnEscape={!loading}
        >
            <Modal.Header>
                <span>
                    {idDataSet
                        ? intl.formatMessage({ id: "editCrewMember" })
                        : intl.formatMessage({ id: "addCrewMember" })}
                </span>
                <Icon name="close" link size="large" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Content>
                <div>
                    <form className="add-user-form" autoComplete="off">
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "firstName" })}
                                <span className="input-required">*</span>
                            </label>
                            <Input
                                value={newIdDataSet.firstName}
                                onChange={e =>
                                    setNewIdDataSet({ ...newIdDataSet, firstName: e.target.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "nonLatinFN" })}</label>
                            <Input
                                value={newIdDataSet.nonLatinFirstName}
                                onChange={e =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        nonLatinFirstName: e.target.value,
                                    })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "lastName" })}
                                <span className="input-required">*</span>
                            </label>
                            <Input
                                value={newIdDataSet.lastName}
                                onChange={e =>
                                    setNewIdDataSet({ ...newIdDataSet, lastName: e.target.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "nonLatinLN" })}</label>
                            <Input
                                value={newIdDataSet.nonLatinLastName}
                                onChange={e =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        nonLatinLastName: e.target.value,
                                    })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "placeOfBirth" })}
                                <span className="input-required">*</span>
                            </label>
                            <Input
                                value={newIdDataSet.placeOfBirth}
                                onChange={e =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        placeOfBirth: e.target.value,
                                    })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "nonLatinPOB" })}</label>
                            <Input
                                value={newIdDataSet.nonLatinPlaceOfBirth}
                                onChange={e =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        nonLatinPlaceOfBirth: e.target.value,
                                    })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "dateOfBirth" })}
                                <span className="input-required">*</span>
                            </label>
                            <DateInput date={dateOfBirth} setDate={setDateOfBirth} selectOnBlur />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "countryOfBirth" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                selectOnBlur={false}
                                search
                                options={TranslateCountryProps(countries, intl.locale)}
                                value={newIdDataSet.countryOfBirth}
                                onChange={(e, data: any) =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        countryOfBirth: +data.value,
                                    })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "street" })}
                                <span className="input-required">*</span>
                            </label>
                            <Input
                                value={newIdDataSet.street}
                                onChange={e =>
                                    setNewIdDataSet({ ...newIdDataSet, street: e.target.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "zipCode" })}
                                <span className="input-required">*</span>
                            </label>
                            <Input
                                value={newIdDataSet.zipCode}
                                onChange={e =>
                                    setNewIdDataSet({ ...newIdDataSet, zipCode: e.target.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "city" })}
                                <span className="input-required">*</span>
                            </label>
                            <Input
                                value={newIdDataSet.city}
                                onChange={e =>
                                    setNewIdDataSet({ ...newIdDataSet, city: e.target.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "country" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                selectOnBlur={false}
                                search
                                options={TranslateCountryProps(countries, intl.locale)}
                                value={newIdDataSet.country}
                                onChange={(e, data: any) =>
                                    setNewIdDataSet({ ...newIdDataSet, country: +data.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "gender" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                selectOnBlur={false}
                                search
                                options={TranslateProps(intl, genderProps)}
                                value={newIdDataSet.gender}
                                onChange={(e, data: any) =>
                                    setNewIdDataSet({ ...newIdDataSet, gender: +data.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "nationality" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                selectOnBlur={false}
                                search
                                options={TranslateCountryProps(nationalities, intl.locale, true)}
                                value={newIdDataSet.nationality}
                                onChange={(e, data: any) =>
                                    setNewIdDataSet({ ...newIdDataSet, nationality: +data.value })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "phoneNumber" })}</label>
                            <Input
                                value={newIdDataSet.phoneNumber}
                                onChange={e =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        phoneNumber: e.target.value,
                                    })
                                }
                            />
                        </section>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "email" })}</label>
                            <Input
                                value={newIdDataSet.email}
                                onChange={e =>
                                    setNewIdDataSet({ ...newIdDataSet, email: e.target.value })
                                }
                            />
                        </section>
                    </form>
                    <section className="input-container" style={{ marginTop: "15px" }}>
                        <label>{intl.formatMessage({ id: "remarks" })}</label>
                        <TextArea
                            value={newIdDataSet.remarks}
                            onChange={e =>
                                setNewIdDataSet({ ...newIdDataSet, remarks: e.target.value })
                            }
                        />
                    </section>
                    {user.ecdbConnection && (
                        <>
                            <Divider horizontal>{intl.formatMessage({ id: "eidasInfo" })}</Divider>
                            <div id="eidas-container">
                                {newIdDataSet.eidasInformation.map((ei, index) => (
                                    <section
                                        className="input-container"
                                        id="add-person-identifier"
                                        key={index}
                                    >
                                        <label>
                                            {intl.formatMessage({ id: "personId" })} #{index + 1}
                                        </label>
                                        <Input
                                            value={ei.personIdentifier ?? ""}
                                            onChange={e =>
                                                handleEidasInformationInputChange(e, index)
                                            }
                                        />
                                    </section>
                                ))}
                                <Button
                                    id="add-person-identifier"
                                    content={intl.formatMessage({ id: "addNewPersonID" })}
                                    basic
                                    fluid
                                    primary
                                    onClick={handleAddNewEidasInformation}
                                    disabled={newIdDataSet.eidasInformation.some(
                                        x => x.personIdentifier === ""
                                    )}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <div className="image-dropzone-label">
                        <label>
                            <strong>{intl.formatMessage({ id: "signatureImage" })}</strong>
                        </label>
                        {!!newIdDataSet.signatureGuid && (
                            <Button
                                content={intl.formatMessage({ id: "useCurrentImage" })}
                                size="small"
                                onClick={() =>
                                    createImageFile(
                                        `/signatures/${newIdDataSet.signatureGuid}`,
                                        true
                                    )
                                }
                            />
                        )}
                        <div>
                            <Radio
                                toggle
                                label={intl.formatMessage({ id: "removeBackground" })}
                                disabled={!signatureImageCrop}
                                checked={newIdDataSet.removeSignatureBackground}
                                onChange={() =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        removeSignatureBackground:
                                            !newIdDataSet.removeSignatureBackground,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <ImageDropzoneCropper
                        image={signatureImageFile}
                        setImage={setSignatureImageFile}
                        crop={signatureImageCrop}
                        setCrop={setSignatureImageCrop}
                        currentImageBase64={newIdDataSet.signatureBase64}
                        signature
                    />
                    <div className="image-dropzone-label">
                        <label>
                            <strong>{intl.formatMessage({ id: "crewMemberImage" })}</strong>
                            <span className="input-required">*</span>
                        </label>
                        {!!newIdDataSet.guid && (
                            <Button
                                content={intl.formatMessage({ id: "useCurrentImage" })}
                                size="small"
                                onClick={() =>
                                    createImageFile(`/images/${newIdDataSet.guid}`, false)
                                }
                            />
                        )}
                        <div>
                            <Radio
                                toggle
                                label={intl.formatMessage({ id: "removeBackground" })}
                                disabled={!imageCrop}
                                checked={newIdDataSet.removeImageBackground}
                                onChange={() =>
                                    setNewIdDataSet({
                                        ...newIdDataSet,
                                        removeImageBackground: !newIdDataSet.removeImageBackground,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <ImageDropzoneCropper
                        image={imageFile}
                        setImage={setImageFile}
                        crop={imageCrop}
                        setCrop={setImageCrop}
                        currentImageBase64={newIdDataSet.imageBase64}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={() => (idDataSet ? handleEditCrewMember() : handleAddCrewMember())}
                    content={
                        idDataSet
                            ? intl.formatMessage({ id: "saveChanges" })
                            : intl.formatMessage({ id: "add" })
                    }
                    loading={loading}
                    disabled={
                        loading ||
                        Object.values(dateOfBirth).some(x => !x || x === -1) ||
                        !newIdDataSet.firstName ||
                        !newIdDataSet.lastName ||
                        !newIdDataSet.placeOfBirth ||
                        !newIdDataSet.street ||
                        !newIdDataSet.zipCode ||
                        !newIdDataSet.city ||
                        newIdDataSet.country === -1 ||
                        newIdDataSet.gender === Gender.Unspecified ||
                        newIdDataSet.countryOfBirth === -1 ||
                        newIdDataSet.nationality === -1 ||
                        (!idDataSet && !imageCrop)
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
