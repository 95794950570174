import { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Input, Select } from "semantic-ui-react";
import { DateInput } from "../../../components/DateInput/DateInput";
import { TranslateProps } from "../../../i18n/TranslateProps";
import {
    CertificateType,
    ICertificate,
    restrictionStatusOptions,
} from "../../../models/certificate";
import { IDate } from "../../../models/common";
import "./FitnessDataForm.css";

type Props = {
    certificate: ICertificate;
    setCertificate: Dispatch<SetStateAction<ICertificate>>;
    fitnessStartDate: IDate;
    setFitnessStartDate: Dispatch<SetStateAction<IDate>>;
    fitnessEndDate: IDate;
    setFitnessEndDate: Dispatch<SetStateAction<IDate>>;
};

export const FitnessDataForm = ({
    certificate,
    setCertificate,
    fitnessStartDate,
    setFitnessStartDate,
    fitnessEndDate,
    setFitnessEndDate,
}: Props) => {
    const intl = useIntl();

    return (
        <>
            <div className="form-row four">
                <section className="input-container">
                    <label>{intl.formatMessage({ id: "issueDate" })}</label>
                    <DateInput
                        date={fitnessStartDate}
                        setDate={setFitnessStartDate}
                        extendedYears
                    />
                </section>
                <section className="input-container">
                    <label>{intl.formatMessage({ id: "endDate" })}</label>
                    <DateInput date={fitnessEndDate} setDate={setFitnessEndDate} extendedYears />
                </section>
                {certificate.type !== CertificateType.UcqLngExpert &&
                    certificate.type !== CertificateType.UcqPassengerNavigationExpert && (
                        <section id="form-row-span-two-last" className="input-container">
                            <label>{intl.formatMessage({ id: "restrictions" })}</label>
                            <Select
                                search
                                multiple
                                upward={false}
                                options={TranslateProps(intl, restrictionStatusOptions)}
                                value={certificate.restrictions.sort()}
                                onChange={(e, data: any) =>
                                    setCertificate({
                                        ...certificate,
                                        restrictions: data.value.sort(),
                                    })
                                }
                            />
                        </section>
                    )}
            </div>
            {certificate.type !== CertificateType.UcqLngExpert &&
                certificate.type !== CertificateType.UcqPassengerNavigationExpert && (
                    <div className="form-row three">
                        {certificate.restrictions.includes(7) && (
                            <section className="input-container">
                                <label>{intl.formatMessage({ id: "craftDesc" })}</label>
                                <Input
                                    value={certificate.limitedToOneCraftDescription}
                                    onChange={e =>
                                        setCertificate({
                                            ...certificate,
                                            limitedToOneCraftDescription: e.target.value,
                                        })
                                    }
                                />
                            </section>
                        )}
                        {certificate.restrictions.includes(8) && (
                            <section className="input-container">
                                <label>{intl.formatMessage({ id: "areaDesc" })}</label>
                                <Input
                                    value={certificate.limitedAreaDescription}
                                    onChange={e =>
                                        setCertificate({
                                            ...certificate,
                                            limitedAreaDescription: e.target.value,
                                        })
                                    }
                                />
                            </section>
                        )}
                        {certificate.restrictions.includes(9) && (
                            <section className="input-container">
                                <label>{intl.formatMessage({ id: "taskDesc" })}</label>
                                <Input
                                    value={certificate.limitedTaskDescription}
                                    onChange={e =>
                                        setCertificate({
                                            ...certificate,
                                            limitedTaskDescription: e.target.value,
                                        })
                                    }
                                />
                            </section>
                        )}
                    </div>
                )}
        </>
    );
};
