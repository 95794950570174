import { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";

type Props = {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    totalRows: number;
};

export const TablePagination = ({ page, setPage, totalRows }: Props) => {
    const intl = useIntl();
    const count =
        totalRows > 1
            ? `${(page - 1) * 10 + 1}-${Math.min(
                  totalRows,
                  (page - 1) * 10 + 10
              )} ${intl.formatMessage({ id: "of" })} ${totalRows}`
            : `1 ${intl.formatMessage({ id: "of" })} 1`;

    return totalRows > 0 ? (
        <section className="table-pagination">
            <span>{count}</span>
            <Icon
                name="chevron left"
                link
                size="large"
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
            />
            <Icon
                name="chevron right"
                link
                size="large"
                disabled={page === Math.ceil(totalRows / 10)}
                onClick={() => setPage(page + 1)}
            />
        </section>
    ) : null;
};
