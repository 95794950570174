import { Dispatch, Reducer } from "redux";
import { GET_UNREAD_MESSAGES } from "../actions/unreadMessagesActions";
import { agent } from "../api/agent";

export interface UnreadMessagesState {
    count: number;
}

const initialState: UnreadMessagesState = {
    count: 0,
};

export const unreadMessagesReducer: Reducer<UnreadMessagesState> = (
    state: UnreadMessagesState = initialState,
    action: any
): UnreadMessagesState => {
    switch (action.type) {
        case GET_UNREAD_MESSAGES:
            return { ...state, count: action.payload };
        default:
            return state;
    }
};

export async function fetchUnreadMessages(dispatch: Dispatch) {
    const res = await agent.Inbox.getUnread();
    dispatch({ type: "GET_UNREAD_MESSAGES", payload: res });
}
