export interface IStatistics extends Record<string, number> {
    ucqBoatmaster: number;
    ucqHelmsman: number;
    ucqAbleBoatman: number;
    ucqBoatman: number;
    ucqDeckhand: number;
    ucqApprentice: number;
    ucqLngExpert: number;
    ucqPassengerExpert: number;
    stretchesWithSpecificRisks: number;
    radarpatent: number;
    highRhineCertificate: number;
    adnBescheinigung: number;
    boatmasterOnTheRhine: number;
    boatmasterOnTheHighRhine: number;
    extensionBoatmasterOnTheRhine: number;
}

export interface IStatisticsResult {
    activeCertificates: IStatistics;
    issuedCertificates: IStatistics;
    suspendedCertificates: IStatistics;
    withdrawnCertificates: IStatistics;
}

export const statisticsInit: IStatistics = {
    ucqBoatmaster: 0,
    ucqHelmsman: 0,
    ucqAbleBoatman: 0,
    ucqBoatman: 0,
    ucqDeckhand: 0,
    ucqApprentice: 0,
    ucqLngExpert: 0,
    ucqPassengerExpert: 0,
    stretchesWithSpecificRisks: 0,
    radarpatent: 0,
    highRhineCertificate: 0,
    adnBescheinigung: 0,
    boatmasterOnTheRhine: 0,
    boatmasterOnTheHighRhine: 0,
    extensionBoatmasterOnTheRhine: 0
};
