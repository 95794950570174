import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Button, Message, Segment, Select } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { TranslateProps } from "../../i18n/TranslateProps";
import { getTranslatedCleanInboxMessage, IInboxMessage } from "../../models/inboxMessage";
import { agent } from "../../api/agent";
import { AppState } from "../../store/configureStore";
import { fetchUnreadMessages } from "../../store/unreadMessagesReducer";
import { messageStatusProps, MessageStatus } from "../../models/common";

export const InboxMessageDetails = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const loc = useLocation();
    const { guid } = useParams();
    const [inboxMessage, setInboxMessage] = useState<IInboxMessage>();
    const [loading, setLoading] = useState(true);

    const fetchInboxMessage = useCallback(async () => {
        setLoading(true);
        try {
            const res = await agent.Inbox.details(guid ?? "");
            setInboxMessage(res);
        } catch {
            toast(intl.formatMessage({ id: "unableToFetchInboxMessage" }), false);
        } finally {
            setLoading(false);
        }
    }, [guid, intl]);

    const handleProcessMessage = async (status: MessageStatus) => {
        try {
            await agent.Inbox.process(guid ?? "", status);
            setInboxMessage(i => ({ ...i!, status: status }));
            toast(intl.formatMessage({ id: "inboxMessageProcessed" }), true);
            await fetchUnreadMessages(dispatch);
        } catch {
            toast(intl.formatMessage({ id: "unableToProcessInboxMessage" }), false);
        }
    };

    useEffect(() => {
        fetchInboxMessage();
    }, [fetchInboxMessage]);

    return user.role !== UserRole.PowerUser && user.role !== UserRole.OfficeUser ? (
        <Navigate to="/unauthorized" />
    ) : (
        <div className="inbox-message-details-container">
            <section className="container-header">
                <h1>{intl.formatMessage({ id: "inboxMessageDetails" })}</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/inbox", state: loc.state }}
                    primary
                    icon="arrow left"
                    content={intl.formatMessage({ id: "backToList" })}
                />
            </section>
            <CSSTransition in={!loading} timeout={100} classNames="fade" unmountOnExit>
                {inboxMessage !== undefined ? (
                    <Segment className="grey-segment">
                        <section className="section-row">
                            <label>{intl.formatMessage({ id: "dateOfReception" })}:</label>
                            <p>{inboxMessage.created}</p>
                        </section>
                        <section className="section-row">
                            <label>{intl.formatMessage({ id: "sender" })}:</label>
                            <p>{inboxMessage.sender}</p>
                        </section>
                        <section className="section-row">
                            <label>{intl.formatMessage({ id: "message" })}:</label>
                            <p>{getTranslatedCleanInboxMessage(inboxMessage, intl)}</p>
                        </section>
                        <section className="section-row">
                            <label>{intl.formatMessage({ id: "processed" })}:</label>
                            <Select
                                selectOnBlur={false}
                                options={TranslateProps(intl, messageStatusProps)}
                                value={inboxMessage.status}
                                onChange={(e, data) => handleProcessMessage(+data.value!)}
                                disabled={inboxMessage.status !== MessageStatus.Unprocessed}
                            />
                        </section>
                        <section className="inbox-action">
                            <Button
                                as={Link}
                                to={"/crew-members/" + inboxMessage.crewMemberGuid}
                                primary
                                content={intl.formatMessage({ id: "goToCrewMemberPage" })}
                            />
                        </section>
                    </Segment>
                ) : (
                    <Message
                        icon="info circle"
                        header={intl.formatMessage({ id: "unableToFetchInboxMessage" })}
                    />
                )}
            </CSSTransition>
        </div>
    );
};
