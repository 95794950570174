import { formatDate } from "../../models/common";

export const formatDateRange = (dates: Date[]) => {
    if (dates.length === 0 || dates.length !== 2) return [];
    return [dates[0], dates[1]];
};

export const formatDateRangeDisplay = (dates: Date[]) => {
    if (dates.length === 0 || dates.length !== 2) return "";
    return formatDate(dates[0].toISOString()) + " - " + formatDate(dates[1].toISOString());
};
