import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Segment, Table, Message, Button, Popup } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { IAuthorization } from "../../models/authorization";
import { CertificateStatus, ICertificate } from "../../models/certificate";
import { ICrewMember } from "../../models/crewMember";
import { AppState } from "../../store/configureStore";
import { AddAuthorizationsForm } from "./AddAuthorizationsForm";
import { AuthorizationsRow } from "./AuthorizationsRow";

type Props = {
    authorizations: IAuthorization[];
    eligibleCertificates: ICertificate[];
    crewMember: ICrewMember;
    fetchCrewMember: () => Promise<void>;
};

export const AuthorizationTable = ({
    authorizations,
    eligibleCertificates,
    crewMember,
    fetchCrewMember,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const intl = useIntl();

    return (
        <section>
            <div className="container-header">
                <h2>{intl.formatMessage({ id: "specificAuthorizations" })}</h2>
                {user.role !== UserRole.EnforcementAuthorityUser &&
                crewMember.guid.includes("ECDB_CREW_MEMBER") ? (
                    <Popup
                        content={intl.formatMessage({ id: "crewMemberNotInDb" })}
                        position="left center"
                        flowing
                        trigger={
                            <Button
                                id="disabled-button"
                                primary
                                content={intl.formatMessage({
                                    id: "issueNewSpecificAuthorization",
                                })}
                                onClick={() =>
                                    crewMember.guid.includes("ECDB_CREW_MEMBER")
                                        ? null
                                        : setModalOpen(true)
                                }
                            />
                        }
                    />
                ) : user.role !== UserRole.EnforcementAuthorityUser ? (
                    <Button
                        primary
                        content={intl.formatMessage({ id: "issueNewSpecificAuthorization" })}
                        onClick={() => setModalOpen(true)}
                        disabled={eligibleCertificates.length === 0}
                    />
                ) : null}
            </div>
            <Segment>
                {authorizations.length === 0 ? (
                    <Message
                        icon="info circle"
                        header={intl.formatMessage({ id: "noResultsFound" })}
                    />
                ) : (
                    <Table basic="very" compact="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell singleLine width={1}>
                                    {intl.formatMessage({ id: "issuedBy" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={6}>
                                    {intl.formatMessage({ id: "type" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    {intl.formatMessage({ id: "qualificationID" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    {intl.formatMessage({ id: "linkedDocument" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {intl.formatMessage({ id: "validUntil" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {intl.formatMessage({ id: "status" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    {intl.formatMessage({ id: "actions" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {authorizations.map(a => (
                                <AuthorizationsRow key={a.qualificationID} authorization={a} />
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Segment>
            {eligibleCertificates.length > 0 && modalOpen && (
                <AddAuthorizationsForm
                    open={modalOpen}
                    close={() => setModalOpen(false)}
                    activeAuthorizations={authorizations.filter(
                        a =>
                            (a.status === CertificateStatus.Active ||
                                a.status === CertificateStatus.Suspended) &&
                            a.linkedQualificationID === eligibleCertificates[0].qualificationID
                    )}
                    targetCertificate={eligibleCertificates[0]}
                    crewMember={crewMember}
                    fetchCrewMember={fetchCrewMember}
                />
            )}
        </section>
    );
};
