import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Button, Segment } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IStatistics, IStatisticsResult, statisticsInit } from "../../models/statistics";
import { agent } from "../../api/agent";
import { AppState } from "../../store/configureStore";
import { StatisticsTable } from "./StatisticsTable";
import { CalendarInputDouble } from "../../components/CalendarInput/CalendarInputDouble";
import {
    formatDateRange,
    formatDateRangeDisplay,
} from "../../components/CalendarInput/dateHelpers";
import { constructDate } from "../../models/common";
import "./Statistics.css";

export const Statistics = () => {
    const user = useSelector((state: AppState) => state.user);
    const [statistics, setStatistics] = useState<IStatisticsResult>();
    const [currentActiveCertificates, setCurrentActiveCertificates] = useState<IStatistics>();
    const [dates, setDates] = useState<Date[]>([]);
    const [exporting, setExporting] = useState(false);
    const intl = useIntl();
    const ucqKeys = useRef(Object.keys(statisticsInit).filter(x => x.includes("ucq")));

    const changePeriod = (e: any, manualInput: boolean) => {
        !manualInput ? setDates(formatDateRange(e)) : setDates(e);
    };

    const fetchCurrentActiveCertificates = useCallback(async () => {
        try {
            const res = await agent.Statistics.getCurrentActiveCertificates();
            if (!user.ecdbConnection) ucqKeys.current.forEach(x => delete res[x]);
            setCurrentActiveCertificates(res);
        } catch {
            toast(intl.formatMessage({ id: "unableToFetchStatistics" }), false);
        }
    }, [intl, ucqKeys, user.ecdbConnection]);

    const fetchStatistics = useCallback(async () => {
        setStatistics(undefined);
        await new Promise(r => setTimeout(r, 100));
        try {
            const startDate = constructDate(dates[0]).replace(/\//g, "-");
            const endDate = constructDate(dates[1]).replace(/\//g, "-");
            const res = await agent.Statistics.get(startDate, endDate);
            if (res && !user.ecdbConnection) {
                ucqKeys.current.forEach(x => {
                    if (res.activeCertificates) delete res.activeCertificates[x];
                    if (res.issuedCertificates) delete res.issuedCertificates[x];
                    if (res.suspendedCertificates) delete res.suspendedCertificates[x];
                    if (res.withdrawnCertificates) delete res.withdrawnCertificates[x];
                });
            }
            setStatistics(res);
        } catch (err) {
            console.error(err);
            toast(intl.formatMessage({ id: "unableToFetchStatistics" }), false);
        }
    }, [dates, user.ecdbConnection, intl]);

    const exportStatistics = async () => {
        setExporting(true);
        try {
            const startDate = constructDate(dates[0]);
            const endDate = constructDate(dates[1]);
            await agent.Statistics.export(startDate, endDate);
        } catch {
            toast(intl.formatMessage({ id: "failedToExportStatistics" }), false);
        } finally {
            setExporting(false);
        }
    };

    useEffect(() => {
        fetchCurrentActiveCertificates();
    }, [fetchCurrentActiveCertificates]);

    useEffect(() => {
        dates.length > 0 && fetchStatistics();
    }, [fetchStatistics, dates]);

    return user.role !== UserRole.PowerUser && user.role !== UserRole.OfficeUser ? (
        <Navigate to="/unauthorized" />
    ) : (
        <div className="statistics-page-container">
            <div className="container-header">
                <h1>{intl.formatMessage({ id: "statistics" })}</h1>
                <Button
                    primary
                    content={intl.formatMessage({ id: "exportStatistics" })}
                    disabled={exporting || !statistics || dates.length === 0}
                    loading={exporting}
                    onClick={() => exportStatistics()}
                />
            </div>
            <div className="statistics-container">
                <Segment className="grey-segment search-segment">
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "period" })}</label>
                        <CalendarInputDouble
                            dates={dates}
                            handleDateChange={(e: any, manualInput: boolean) =>
                                changePeriod(e, manualInput)
                            }
                        />
                    </section>
                </Segment>
                <div className="statistics-grid">
                    <CSSTransition
                        in={!!currentActiveCertificates}
                        timeout={100}
                        classNames="fade"
                        unmountOnExit
                    >
                        <>
                            {currentActiveCertificates && (
                                <StatisticsTable
                                    title={intl.formatMessage({ id: "currentActiveCertificates" })}
                                    statistics={currentActiveCertificates}
                                    period=""
                                />
                            )}
                        </>
                    </CSSTransition>
                    <CSSTransition in={!!statistics} timeout={100} classNames="fade" unmountOnExit>
                        <>
                            {statistics && (
                                <>
                                    <StatisticsTable
                                        title={intl.formatMessage({ id: "issuedCertificates" })}
                                        statistics={statistics.issuedCertificates}
                                        period={formatDateRangeDisplay(dates)}
                                    />
                                    <StatisticsTable
                                        title={intl.formatMessage({ id: "suspendedCertificates" })}
                                        statistics={statistics.suspendedCertificates}
                                        period={formatDateRangeDisplay(dates)}
                                    />
                                    <StatisticsTable
                                        title={intl.formatMessage({ id: "withdrawnCertificates" })}
                                        statistics={statistics.withdrawnCertificates}
                                        period={formatDateRangeDisplay(dates)}
                                    />
                                </>
                            )}
                        </>
                    </CSSTransition>
                </div>
            </div>
        </div>
    );
};
