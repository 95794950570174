import {
    boatmasterLicenceOptions,
    BoatmasterLicenceType,
    CertificateType,
    certificateTypeOptions,
    ICertificate,
    restrictionStatusOptions,
} from "../../../models/certificate";
import { getLatinNonLatinCombined } from "../../../models/crewMember";
import { AuthorizationType, authorizationTypeOptions } from "../../../models/authorization";
import { TranslateProps } from "../../../i18n/TranslateProps";
import { formatDate, Gender } from "../../../models/common";
import { useIntl } from "react-intl";

type Props = {
    certificate: ICertificate;
};

export const OtherCertificateView = ({ certificate }: Props) => {
    const intl = useIntl();

    return (
        <div className={`ucq-frame `}>
            <div className="middle">
                <div className="middleLeft">
                    <p
                        style={{
                            paddingTop: "50px",
                            marginBottom: "0px",
                            fontWeight: "bold",
                            fontSize: "larger",
                        }}
                    >
                        {TranslateProps(intl, certificateTypeOptions).find(
                            x => x.value === certificate.type
                        )?.text ?? ""}
                    </p>
                    <p className="position">
                        {intl.formatMessage({ id: "startDate" })}: &nbsp;&nbsp;{" "}
                        {formatDate(certificate.startDate)}
                    </p>
                    <p className="position">
                        {intl.formatMessage({ id: "endDate" })}: &nbsp;&nbsp;{" "}
                        {formatDate(certificate.endDate)}
                    </p>
                    <p className="position">
                        {intl.formatMessage({ id: "comments" })}: &nbsp;&nbsp;{" "}
                        {certificate.comments}
                    </p>
                    <hr />
                    <p className="position">{intl.formatMessage({ id: "fitnessData" })}</p>
                    <p className="position">
                        {intl.formatMessage({ id: "validFrom" })}: &nbsp;&nbsp;{" "}
                        {formatDate(certificate.fitnessIssueDate)}
                    </p>
                    <p className="position">
                        {intl.formatMessage({ id: "validUntil" })}: &nbsp;&nbsp;{" "}
                        {formatDate(certificate.fitnessValidUntil)}
                    </p>
                    <p className="position"></p>
                    {intl.formatMessage({ id: "restrictions" })}: &nbsp;&nbsp;{" "}
                    {certificate.restrictions
                        .map(
                            x =>
                                TranslateProps(intl, restrictionStatusOptions).find(
                                    y => y.value === x
                                )?.text
                        )
                        .join(", ")}
                    <p style={{ paddingBottom: "50px", marginBottom: "0px" }}></p>
                </div>
            </div>
        </div>
    );
};
