import { Link } from "react-router-dom";
import { Icon, Menu, Modal, TransitionablePortal } from "semantic-ui-react";
import { logout, UserData, UserRole } from "../../actions/authentificationActions";
import { LanguageSelector } from "../../components/LanguageSelector/LanguageSelector";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Logo from "../../icons/logo_white_no_text.png";

type Props = {
    open: boolean;
    closeModal: () => void;
    user: UserData;
};

export const HeaderMobile = ({ open, closeModal, user }: Props) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    return (
        <TransitionablePortal
            open={open}
            onOpen={() => setTimeout(() => document.body.classList.add("modal-fade-in"), 0)}
            transition={{ animation: "scale", duration: 300 }}
            id="hamburger-modal"
        >
            <Modal
                centered={false}
                basic
                onClose={() => closeModal()}
                open
                size="fullscreen"
                id="hamburger-modal"
            >
                <div className="vertical-menu-hamburger-header">
                    <div className="hamburger-modal-header">
                        <Menu.Item
                            id="logo-without-text"
                            as={Link}
                            to="/search"
                            header
                            onClick={() => closeModal()}
                        >
                            <img src={Logo} alt="Logo" />
                        </Menu.Item>
                        <LanguageSelector />
                        <Icon
                            className="hamburger-icon"
                            name="close"
                            size="big"
                            onClick={() => closeModal()}
                        />
                    </div>
                    <Menu.Item
                        className="header-menu-options"
                        onClick={() => closeModal()}
                        as={Link}
                        to="/search"
                        content={intl.formatMessage({ id: "searchCrewMember" })}
                    />
                    {(user.role === UserRole.PowerUser || user.role === UserRole.OfficeUser) && (
                        <Menu.Item
                            className="header-menu-options"
                            as={Link}
                            to="/statistics"
                            content={intl.formatMessage({ id: "statistics" })}
                            onClick={() => closeModal()}
                        />
                    )}
                    {(user.role === UserRole.PowerUser || user.role === UserRole.OfficeUser) && (
                        <Menu.Item
                            className="header-menu-options"
                            onClick={() => closeModal()}
                            as={Link}
                            to="/inbox"
                            content={intl.formatMessage({ id: "inbox" })}
                        />
                    )}
                    {user.role === UserRole.PowerUser && (
                        <Menu.Item
                            className="header-menu-options"
                            onClick={() => closeModal()}
                            as={Link}
                            to="/change-log"
                            content={intl.formatMessage({ id: "changeLog" })}
                        />
                    )}
                    {/*user.role === UserRole.PowerUser && (
                        <Menu.Item
                            className="header-menu-options"
                            onClick={() => closeModal()}
                            as={Link}
                            to="/manage-users"
                            content={intl.formatMessage({ id: "userManagement" })}
                        />
                    )*/}
                    <Menu.Item as={Link} to="/manage-users">
                        <Icon name="user circle" size="large" />
                        <span className="header-menu-options">{user.name}</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => dispatch(logout())}>
                        <Icon name="sign-out" size="large" />
                        <span className="header-menu-options">
                            {intl.formatMessage({ id: "logout" })}
                        </span>
                    </Menu.Item>
                </div>
            </Modal>
        </TransitionablePortal>
    );
};
