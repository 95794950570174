import { FormattedMessage, IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { adnCourseOptions, adnExamOptions, ICertificate } from "../../../models/certificate";
import { formatDateForCertificateView } from "../../../models/common";
import { IIDDataSet } from "../../../models/crewMember";
import { AppState } from "../../../store/configureStore";
import { CrewMemberImage } from "../../CrewMember/CrewMemberImage";
import { SignatureImage } from "../../CrewMember/SignatureImage";
import deMessages from "../../../i18n/locales/de.json";
import stamp from "../../../images/SRH_stamp.png";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    renew?: boolean;
};

export const AdnCertificateView = ({ certificate, idDataSet, renew }: Props) => {
    const nationalities = useSelector((state: AppState) => state.nationalities.nationalities);
    const nationality = nationalities.find(c => c.value === idDataSet.nationality)?.text as string;

    return (
        <div id="adn-card">
            <section id="front">
                <p>CH</p>
                <p>Bescheinigung über besondere Kenntnisse des ADN</p>
                <br />
                <div id="front-container">
                    <div id="info-container">
                        <p>
                            <label>1.</label>
                            <span>{renew ? "" : certificate.qualificationID}</span>
                        </p>
                        <p>
                            <label>2.</label>
                            <span>
                                {(!!idDataSet.nonLatinLastName
                                    ? idDataSet.nonLatinLastName
                                    : idDataSet.lastName
                                ).toUpperCase()}
                            </span>
                        </p>
                        <p>
                            <label>3.</label>
                            <span>
                                {!!idDataSet.nonLatinFirstName
                                    ? idDataSet.nonLatinFirstName
                                    : idDataSet.firstName}
                            </span>
                        </p>
                        <p>
                            <label>4.</label>
                            <span>{formatDateForCertificateView(idDataSet.dateOfBirth)}</span>
                        </p>
                        <p>
                            <label>5.</label>
                            <span>{nationality}</span>
                        </p>
                        <p>
                            <label>6.</label>
                            {idDataSet.signatureBase64 && (
                                <SignatureImage signatureBase64={idDataSet.signatureBase64} />
                            )}
                        </p>
                        <p>
                            <label>7.</label>
                            <span>Schweizerische Rheinhäfen</span>
                        </p>
                        <p>
                            <label>8.</label>
                            <span>
                                GÜLTIG BIS:&nbsp;{formatDateForCertificateView(certificate.endDate)}
                            </span>
                        </p>
                    </div>
                    <div id="crew-member-image-container">
                        <CrewMemberImage imageBase64={idDataSet.imageBase64} />
                    </div>
                </div>
            </section>
            <section id="back">
                <p>
                    <label>1.</label>
                    <span>{renew ? "" : certificate.qualificationID}</span>
                </p>
                <br />
                <p>Diese Bescheinigung ist gültig für besondere Kenntnisse des ADN gemäss:</p>
                <br />
                <div className="exam-stamp">
                    <div className="exam">
                        <p>
                            <IntlProvider locale="de" messages={deMessages}>
                                <FormattedMessage
                                    id={
                                        adnExamOptions.find(
                                            x => x.value === certificate.adnExamType
                                        )?.text as string
                                    }
                                />
                            </IntlProvider>
                        </p>
                        {certificate.adnCourses.sort().map(course => (
                            <p key={course}>
                                {
                                    (
                                        adnCourseOptions.find(x => x.value === course)
                                            ?.text as string
                                    ).split(" ")[0]
                                }
                            </p>
                        ))}
                    </div>
                    <img alt="Port of Switzerland stamp" src={stamp} />
                </div>
            </section>
        </div>
    );
};
