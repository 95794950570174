import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Icon, Popup, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { ISRB, srbStatusOptions } from "../../models/srb";
import { AppState } from "../../store/configureStore";
import { agent } from "../../api/agent";
import { useIntl } from "react-intl";
import { formatDate } from "../../models/common";
import { EUSRBFormModal } from "./EUSRBFormModal";
import { ICrewMember } from "../../models/crewMember";
import { RequestFeedbackIndicator } from "../../components/RequestFeedbackIndicator/RequestFeedbackIndicator";
import { CHSRBFormModal } from "./CHSRBFormModal";
import { DeleteSRBModal } from "./DeleteSRBModal";
import { ModifyCHSRBForm } from "./ModifyCHSRBForm";

type Props = {
    srb: ISRB;
    crewMember: ICrewMember;
    fetchCrewMember: () => Promise<void>;
    setDeletedSrb: (guid: string) => void;
};

export const SRBRow = ({ srb, crewMember, fetchCrewMember, setDeletedSrb }: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [euModalOpen, setEuModalOpen] = useState(false);
    const [chModalOpen, setChModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modifyModalOpen, setModifyModalOpen] = useState(false);
    const srbStatus = useMemo(() => {
        if (!srb.chSrb) {
            if (srb.active) return intl.formatMessage({ id: "active" });
            else return intl.formatMessage({ id: "inactive" });
        } else {
            const status = srbStatusOptions.find(x => x.value === srb.status);
            return status ? intl.formatMessage({ id: status.text as string }) : "";
        }
    }, [intl, srb.active, srb.chSrb, srb.status]);
    const disabled = srb.guid.includes("ECDB_SRB");

    const handleDownloadQrCode = async () => {
        setLoading(true);
        let filename = `${srb.serialNumber}.jpg`;
        try {
            await agent.ServiceRecordBooks.downloadQr(srb.guid, filename);
        } catch {
            toast(intl.formatMessage({ id: "failedToDownloadSRB" }), false);
        } finally {
            setLoading(false);
        }
    };

    const deleteLimit = useMemo(
        () => new Date(srb.added).setDate(new Date(srb.added).getDate() + 1),
        [srb.added]
    );

    const commentsAndReason = useMemo(() => {
        return (
            <div className="comments-reason-tooltip">
                {!!srb.comments && (
                    <>
                        <p>{intl.formatMessage({ id: "comments" })}:</p>
                        <span>{srb.comments}</span>
                    </>
                )}

                {!!srb.reason && (
                    <>
                        <p>{intl.formatMessage({ id: "reason" })}:</p>
                        <span>{srb.reason}</span>
                    </>
                )}
            </div>
        );
    }, [srb.comments, srb.reason, intl]);

    return (
        <Table.Row>
            <Table.Cell>{srb.serialNumber}</Table.Cell>
            <Table.Cell>{formatDate(srb.issueDate)}</Table.Cell>
            <Table.Cell>{srb.placeOfIssue ?? "/"}</Table.Cell>
            <Table.Cell singleLine>
                {srbStatus}
                {(!!srb.comments || !!srb.reason) && (
                    <Popup
                        content={commentsAndReason}
                        position="top center"
                        wide
                        trigger={<Icon name="info circle" size="large" />}
                        hoverable
                    />
                )}
            </Table.Cell>
            <Table.Cell collapsing>
                {user.role !== UserRole.EnforcementAuthorityUser && (
                    <Dropdown
                        id="srbs-options"
                        floating
                        text={intl.formatMessage({ id: "options" })}
                        loading={loading}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={intl.formatMessage({ id: "viewEditDetails" })}
                                onClick={() =>
                                    srb.chSrb ? setChModalOpen(true) : setEuModalOpen(true)
                                }
                                disabled={loading || disabled}
                            />
                            {srb.chSrb ? (
                                <Dropdown.Item
                                    text={intl.formatMessage({ id: "modify" })}
                                    onClick={() => setModifyModalOpen(true)}
                                    disabled={loading || disabled}
                                />
                            ) : (
                                <>
                                    <Dropdown.Item
                                        text={intl.formatMessage({ id: "downloadQrCode" })}
                                        onClick={() => handleDownloadQrCode()}
                                        disabled={loading || disabled}
                                    />
                                </>
                            )}
                            {srb.chSrb && new Date().getTime() < deleteLimit && (
                                <Dropdown.Item
                                    text={intl.formatMessage({ id: "delete" })}
                                    onClick={() => setDeleteModalOpen(true)}
                                    disabled={loading || disabled}
                                />
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {!srb.chSrb && srb.requestFeedbacks.length > 0 && (
                    <RequestFeedbackIndicator feedbacks={srb.requestFeedbacks} />
                )}
            </Table.Cell>
            {euModalOpen && (
                <EUSRBFormModal
                    open={euModalOpen}
                    close={() => setEuModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    crewMember={crewMember}
                    srb={srb}
                />
            )}
            {chModalOpen && (
                <CHSRBFormModal
                    open={chModalOpen}
                    close={() => setChModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    crewMember={crewMember}
                    srb={srb}
                />
            )}
            {deleteModalOpen && (
                <DeleteSRBModal
                    open={deleteModalOpen}
                    close={() => setDeleteModalOpen(false)}
                    srb={srb}
                    setDeletedSrb={setDeletedSrb}
                />
            )}
            {modifyModalOpen && (
                <ModifyCHSRBForm
                    open={modifyModalOpen}
                    close={() => setModifyModalOpen(false)}
                    srb={srb}
                    fetchCrewMember={fetchCrewMember}
                />
            )}
        </Table.Row>
    );
};
