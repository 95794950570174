import { FormattedMessage, IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import {
    ICertificate,
    rhineLicenceOptions,
    writeRadarpatentInfo,
    writeRhineCertificateRestrictions,
} from "../../../models/certificate";
import { formatDateForCertificateView } from "../../../models/common";
import { IIDDataSet } from "../../../models/crewMember";
import { rhineRiverRangeProps, separateRiverRange } from "../../../models/riverRanges";
import { AppState } from "../../../store/configureStore";
import { CrewMemberImage } from "../../CrewMember/CrewMemberImage";
import { SignatureImage } from "../../CrewMember/SignatureImage";
import deMessages from "../../../i18n/locales/de.json";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    radarCertificate?: ICertificate;
    renew?: boolean;
};

export const BoatmasterOnTheRhineView = ({
    certificate,
    idDataSet,
    radarCertificate,
    renew,
}: Props) => {
    const nationalities = useSelector((state: AppState) => state.nationalities.nationalities);
    const country = nationalities.find(c => c.value === idDataSet.countryOfBirth)?.text as string; // because ISO 2

    return (
        <div className="rheinpatents-view rheinpatent">
            <section
                id="front"
                style={{
                    fontSize: "13px",
                    maxWidth: "500px",
                    maxHeight: "316px",
                    fontWeight: "bold",
                    position: "relative",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    borderRadius: "20px",
                }}
            >
                <span style={{ position: "absolute", top: "61px" }}>
                    <IntlProvider locale="de" messages={deMessages}>
                        <FormattedMessage
                            id={
                                rhineLicenceOptions.find(
                                    x => x.value === certificate.rhineLicenceType
                                )?.text as string
                            }
                        />
                    </IntlProvider>
                </span>
                <span style={{ position: "absolute", top: "81px" }}>
                    {!!idDataSet.nonLatinLastName ? idDataSet.nonLatinLastName : idDataSet.lastName}
                </span>
                <span style={{ position: "absolute", top: "100px" }}>
                    {!!idDataSet.nonLatinFirstName
                        ? idDataSet.nonLatinFirstName
                        : idDataSet.firstName}
                </span>
                <span style={{ position: "absolute", top: "120px" }}>
                    {formatDateForCertificateView(idDataSet.dateOfBirth)}&nbsp;-&nbsp;
                    {country}&nbsp;-&nbsp;
                    {!!idDataSet.nonLatinPlaceOfBirth
                        ? idDataSet.nonLatinPlaceOfBirth
                        : idDataSet.placeOfBirth}
                </span>
                <span style={{ position: "absolute", top: "140px" }}>
                    {formatDateForCertificateView(certificate.startDate)}
                </span>
                <span style={{ position: "absolute", top: "161px" }}>
                    {idDataSet.signatureBase64 && (
                        <SignatureImage signatureBase64={idDataSet.signatureBase64} />
                    )}
                </span>
                <span style={{ position: "absolute", top: "217px" }}>
                    {writeRhineRiverRanges(certificate.rhineRiverKilometers)}
                </span>
                <span style={{ position: "absolute", top: "256px" }}>
                    {certificate.isUnlimited
                        ? "siehe Pkt. 11"
                        : formatDateForCertificateView(certificate.endDate)}
                </span>
                <span style={{ position: "absolute", top: "276px" }}>
                    {radarCertificate && (
                        <>
                            <span>{writeRadarpatentInfo(radarCertificate)}</span>
                            <br />
                        </>
                    )}
                    <span>{writeRhineCertificateRestrictions(certificate)}</span>
                </span>
                <span style={{ position: "absolute", top: "159px", left: "298px" }}>
                    {renew ? "" : certificate.qualificationID}
                </span>
                <div
                    id="crew-member-image-container"
                    style={{
                        position: "absolute",
                        top: "160px",
                        left: "358px",
                        width: "100px",
                        height: "125px",
                    }}
                >
                    <CrewMemberImage imageBase64={idDataSet.imageBase64} />
                </div>
            </section>
            <section id="back" style={{ borderRadius: "20px" }}></section>
        </div>
    );
};

const writeRhineRiverRanges = (rks: number[]) => {
    const result = { from: 0, to: 0 };
    rks.forEach(rk => {
        const pair = separateRiverRange(rk, rhineRiverRangeProps);
        if (result.from === 0) result.from = pair.from;
        if (result.to === 0) result.to = pair.to;
        if (pair.from !== 0 && pair.from < result.from) result.from = pair.from;
        if (pair.to !== 0 && pair.to > result.to) result.to = pair.to;
    });
    if (result.from === 334.0) result.from = 166.53;
    if (result.to === 857.4) result.to = 1035.4;
    return `km ${result.from.toFixed(2)} - km ${result.to.toFixed(2)}`;
};
