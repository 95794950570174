import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Input, Select } from "semantic-ui-react";
import { TranslateCountryProps } from "../../../i18n/TranslateProps";
import { ICertificate } from "../../../models/certificate";
import { IIDDataSet } from "../../../models/crewMember";
import { AppState } from "../../../store/configureStore";

type Props = {
    certificate: ICertificate;
    setCertificate: Dispatch<SetStateAction<ICertificate>>;
    idDataSet: IIDDataSet;
};

export const DeliveryInfoForm = ({ certificate, setCertificate, idDataSet }: Props) => {
    const { countries } = useSelector((state: AppState) => state.nationalities);
    const intl = useIntl();
    const refIdDataSet = useRef(idDataSet);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCertificate(c => ({ ...c, [e.target.name]: e.target.value }));
    };

    useEffect(() => {
        setCertificate(c => ({
            ...c,
            nameLineOne: refIdDataSet.current.firstName,
            nameLineTwo: refIdDataSet.current.lastName,
            city: refIdDataSet.current.city,
            street: refIdDataSet.current.street,
            zipCode: refIdDataSet.current.zipCode,
            country: refIdDataSet.current.country,
        }));
    }, [setCertificate]);

    return (
        <div className="form-row six">
            <section className="input-container">
                <label>
                    {intl.formatMessage({ id: "nameLine1" })}
                    <span className="input-required">*</span>
                </label>
                <Input
                    name="nameLineOne"
                    value={certificate.nameLineOne ?? ""}
                    onChange={handleInputChange}
                />
            </section>
            <section className="input-container">
                <label>
                    {intl.formatMessage({ id: "nameLine2" })}
                    <span className="input-required">*</span>
                </label>
                <Input
                    name="nameLineTwo"
                    value={certificate.nameLineTwo ?? ""}
                    onChange={handleInputChange}
                />
            </section>
            <section className="input-container">
                <label>
                    {intl.formatMessage({ id: "zipCode" })}
                    <span className="input-required">*</span>
                </label>
                <Input
                    name="zipCode"
                    value={certificate.zipCode ?? ""}
                    onChange={handleInputChange}
                />
            </section>
            <section className="input-container">
                <label>
                    {intl.formatMessage({ id: "city" })}
                    <span className="input-required">*</span>
                </label>
                <Input name="city" value={certificate.city ?? ""} onChange={handleInputChange} />
            </section>
            <section className="input-container">
                <label>
                    {intl.formatMessage({ id: "street" })}
                    <span className="input-required">*</span>
                </label>
                <Input
                    name="street"
                    value={certificate.street ?? ""}
                    onChange={handleInputChange}
                />
            </section>
            <section className="input-container">
                <label>
                    {intl.formatMessage({ id: "country" })}
                    <span className="input-required">*</span>
                </label>
                <Select
                    selectOnBlur={false}
                    search
                    options={TranslateCountryProps(countries, intl.locale)}
                    value={certificate.country}
                    onChange={(e, data: any) =>
                        setCertificate({ ...certificate, country: +data.value })
                    }
                />
            </section>
        </div>
    );
};
