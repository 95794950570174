import { FormattedMessage, IntlProvider } from "react-intl";
import { ICertificate, linkedCertificateOptions } from "../../../models/certificate";
import { formatDateForCertificateView, Gender } from "../../../models/common";
import { IIDDataSet } from "../../../models/crewMember";
import logo from "../../../images/port_of_ch_logo.png";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/configureStore";
import deMessages from "../../../i18n/locales/de.json";
import stamp from "../../../images/SRH_stamp.png";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    renew?: boolean;
};

export const HighRhineCertificateView = ({ certificate, idDataSet, renew }: Props) => {
    const { nationalities } = useSelector((state: AppState) => state.nationalities); // because ISO 2
    const countryOfBirth = nationalities.find(c => c.value === idDataSet.countryOfBirth);
    const countryOfBirthValue =
        countryOfBirth && countryOfBirth.value !== -1 ? (countryOfBirth.text as string) : "";
    const issuingCountry = nationalities.find(
        c => c.value === certificate.linkedCertificateIssuingCountry
    );
    const issuingCountryValue =
        issuingCountry && issuingCountry.value !== -1 ? (issuingCountry.text as string) : "";
    const linkedCertificateType = linkedCertificateOptions.find(
        c => c.value === certificate.linkedCertificateType
    );
    const linkedCertificateCountry = nationalities.find(
        c => c.value === certificate.linkedCertificateIssuingCountry
    );

    return (
        <div id="high-rhine-view">
            <img alt="Port of Switzerland logo" src={logo} />
            <p id="qual-id">
                <label>Nr.</label>
                <span className="underlined">{renew ? "" : certificate.qualificationID}</span>
            </p>
            <p>Die Schweizerischen Rheinhäfen bestätigen hiermit, dass</p>
            <p className="content-row" style={{ marginBottom: 2 }}>
                <label>
                    <span
                        style={
                            idDataSet.gender === Gender.Male
                                ? { textDecoration: "line-through" }
                                : {}
                        }
                    >
                        Frau
                    </span>
                    /
                    <span
                        style={
                            idDataSet.gender === Gender.Female
                                ? { textDecoration: "line-through" }
                                : {}
                        }
                    >
                        Herr
                    </span>
                    *
                </label>
                <span className="underlined">
                    {!!idDataSet.nonLatinLastName ? idDataSet.nonLatinLastName : idDataSet.lastName}
                </span>
                <span className="underlined">
                    {!!idDataSet.nonLatinFirstName
                        ? idDataSet.nonLatinFirstName
                        : idDataSet.firstName}
                </span>
            </p>
            <p className="content-row small">
                <label></label>
                <span>(Name)</span>
                <span>(Vorname)</span>
            </p>
            <p className="content-row">
                <label>Geburtsdatum:</label>
                <span className="underlined">
                    {formatDateForCertificateView(idDataSet.dateOfBirth)}
                </span>
                <span></span>
            </p>
            <p className="content-row">
                <label>Geburtsort:</label>
                <span className="underlined">
                    {!!idDataSet.nonLatinPlaceOfBirth
                        ? idDataSet.nonLatinPlaceOfBirth
                        : idDataSet.placeOfBirth}
                </span>
                <span className="content-row">
                    <label>Staat:</label>
                    <span className="underlined country">{countryOfBirthValue}</span>
                </span>
            </p>
            <p>
                <span
                    style={
                        idDataSet.gender === Gender.Male ? { textDecoration: "line-through" } : {}
                    }
                >
                    Inhaberin
                </span>
                /
                <span
                    style={
                        idDataSet.gender === Gender.Female ? { textDecoration: "line-through" } : {}
                    }
                >
                    Inhaber
                </span>
                * des Grossen Rheinpatents oder eines als gleichwertig anerkannten
                Schiffsführerzeugnisses
            </p>
            <p className="content-row">
                <abbr>Nr.</abbr>
                <span className="underlined">{certificate.linkedCertificateQualificationID}</span>
                <span></span>
            </p>
            <p className="content-row">
                <abbr>Ausstellende Behörde</abbr>
                <span className="underlined">
                    {linkedCertificateType &&
                        linkedCertificateType.value !== 1 &&
                        `${
                            (linkedCertificateType.flag as string).toUpperCase() ||
                            issuingCountryValue
                        } - `}
                    {linkedCertificateType &&
                        linkedCertificateType.value === 1 &&
                        `${linkedCertificateCountry?.text}-Befähigungszeugnis `}
                    {linkedCertificateType && (
                        <IntlProvider locale="de" messages={deMessages}>
                            <FormattedMessage id={linkedCertificateType.text as string} />
                        </IntlProvider>
                    )}
                </span>
            </p>
            <p className="content-row">
                <abbr>in</abbr>
                <span className="underlined">{issuingCountryValue}</span>
                <abbr>am</abbr>
                <span className="underlined">
                    {formatDateForCertificateView(certificate.linkedCertificateIssueDate)}
                </span>
            </p>
            <p>
                die Strecke zwischen der Einfahrt des unteren Vorhafens der Schleuse Augst und dem
                Oberhaupt der Schleuse Augst vier Mal in jede Richtung innerhalb von zwei Jahren
                unter nachgewiesener Assistenz eines Inhabers des Hochrheinpatents dieser Strecke
                befahren hat.
            </p>
            <div className="signature-stamp">
                <div className="signature">
                    <p>Schweizerische Rheinhäfen Basel</p>
                    <br />
                    <br />
                    <br />
                    <p className="content-row">
                        <span className="underlined"></span>
                    </p>
                </div>
                <img alt="Port of Switzerland stamp" src={stamp} />
            </div>
            <p className="content-row small">
                <span>(Unterschrift/Stempel)</span>
            </p>
            <p className="content-row">
                <abbr>Basel</abbr>
                <span className="underlined">
                    {formatDateForCertificateView(certificate.startDate)}
                </span>
                <span></span>
            </p>
            <p>
                Diese Bescheinigung ist auf der vorgenannten Strecke an Bord mitzuführen. Sie
                berechtigt zusammen mit einem gültigen Rheinpatent oder einem gültigen, als
                gleichwertig anerkannten Schiffsführerzeugnis zum Befahren des Hochrheins. Sofern
                das 50. Altersjahr erreicht wurde, ist bei einem anerkannten Schiffsführerzeugnis
                zudem der Bescheid zur Tauglichkeit erforderlich.
            </p>
            <span>* Nichtzutreffendes streichen</span>
            <span id="last-line">
                Sämtliche Korrespondenzen sind an die Amtsstelle und nicht an einzelne Mitarbeiter
                zu richten.
            </span>
        </div>
    );
};
