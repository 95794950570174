import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Segment, Table, Button, Message, Popup } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { ICrewMember } from "../../models/crewMember";
import { ISRB } from "../../models/srb";
import { AppState } from "../../store/configureStore";
import { EUSRBFormModal } from "./EUSRBFormModal";
import { CHSRBFormModal } from "./CHSRBFormModal";
import { SRBRow } from "./SRBRow";

type Props = {
    srbs: ISRB[];
    crewMember: ICrewMember;
    fetchCrewMember: () => Promise<void>;
    setDeletedSrb: (guid: string) => void;
};

export const SRBTable = ({ srbs, crewMember, fetchCrewMember, setDeletedSrb }: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [euModalOpen, setEuModalOpen] = useState(false);
    const [chModalOpen, setChModalOpen] = useState(false);

    return (
        <section>
            <div className="container-header">
                <h2>{intl.formatMessage({ id: "srbCombined" })}</h2>
                <span className="srb-buttons">
                    {user.role !== UserRole.EnforcementAuthorityUser &&
                        user.ecdbConnection &&
                        (!crewMember.cid ? (
                            <Popup
                                content={intl.formatMessage({ id: "cidNotAssigned" })}
                                position="top center"
                                trigger={
                                    <Button
                                        id="disabled-button"
                                        primary
                                        content={intl.formatMessage({
                                            id: "issueNewSrb",
                                        })}
                                    />
                                }
                            />
                        ) : crewMember.guid.includes("ECDB_CREW_MEMBER") && user.ecdbConnection ? (
                            <Popup
                                content={intl.formatMessage({ id: "crewMemberNotInDb" })}
                                position="left center"
                                flowing
                                trigger={
                                    <Button
                                        id="disabled-button"
                                        primary
                                        content={intl.formatMessage({
                                            id: "issueNewSrb",
                                        })}
                                    />
                                }
                            />
                        ) : user.ecdbConnection ? (
                            <Button
                                primary
                                content={intl.formatMessage({ id: "issueNewSrb" })}
                                onClick={() => setEuModalOpen(true)}
                            />
                        ) : null)}
                </span>
            </div>
            <Segment>
                {srbs.length === 0 ? (
                    <Message
                        icon="info circle"
                        header={intl.formatMessage({ id: "noResultsFound" })}
                    />
                ) : (
                    <Table basic="very" compact="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "serialNumber" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "issueDate" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "placeOfIssue" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "status" })}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {intl.formatMessage({ id: "actions" })}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {srbs.map(srb => (
                                <SRBRow
                                    setDeletedSrb={setDeletedSrb}
                                    key={srb.serialNumber}
                                    srb={srb}
                                    crewMember={crewMember}
                                    fetchCrewMember={fetchCrewMember}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Segment>
            {euModalOpen && (
                <EUSRBFormModal
                    open={euModalOpen}
                    close={() => setEuModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    crewMember={crewMember}
                    currentActiveSrb={srbs.find(s => s.active)}
                />
            )}
            {chModalOpen && (
                <CHSRBFormModal
                    open={chModalOpen}
                    close={() => setChModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    crewMember={crewMember}
                    currentActiveSrb={srbs.find(s => s.active)}
                />
            )}
        </section>
    );
};
