import { Button, Dropdown, Icon, Modal, Popup, Table } from "semantic-ui-react";
import { RequestFeedbackIndicator } from "../../components/RequestFeedbackIndicator/RequestFeedbackIndicator";
import {
    AuthorizationType,
    authorizationTypeOptions,
    IAuthorization,
} from "../../models/authorization";
import { boatmasterLicenceOptions, certificateStatusOptions } from "../../models/certificate";
import { formatDate } from "../../models/common";
import { IssuingAuthorityIndicator } from "../../components/IssuingAuthorityIndicator/IssuingAuthorityIndicator";
import { TranslateProps } from "../../i18n/TranslateProps";
import { useIntl } from "react-intl";
import { useState } from "react";
import { toast } from "../..";
import { agent } from "../../api/agent";
import "./Authorizations.css";
import { ucqRiverRangeProps } from "../../models/riverRanges";

const formatRanges = (authorization: IAuthorization) => {
    if (authorization.type !== AuthorizationType.S) {
        return;
    }

    // get all enum stretches from authorization
    const stretches = authorization.ucqRiverKilometers
        .map(str => `${ucqRiverRangeProps.find(r => r.key === str)?.text?.toString() ?? ""}`)
        .sort((a, b) => a.localeCompare(b));

    // get all manual stretches from authorization
    const manualStretches = authorization.ucqManualRiverKilometers
        .split(";")
        .map(r => r.trim())
        .filter(r => r.length > 0)
        .map(str => `${str}`)
        .sort((a, b) => a.localeCompare(b));

    // merge enum stretches and manual stretches
    stretches.push(...manualStretches);

    return (
        <span>
            <Popup
                content={stretches.map(r => (
                    <p key={r}>{r}</p>
                ))}
                position="top center"
                flowing
                trigger={<Icon name="info circle" size="large" />}
                hoverable
            />
        </span>
    );
};

type Props = {
    authorization: IAuthorization;
};

export const AuthorizationsRow = ({ authorization }: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleDownloadAuthorization = async (boatmasterLicenceType?: number) => {
        setLoading(true);
        try {
            await agent.Authorizations.downloadAsPdf(
                authorization.guid,
                `Authorization_${authorization.guid}.pdf`,
                boatmasterLicenceType
            );
        } catch {
            toast(intl.formatMessage({ id: "failedToDownload" }), false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Table.Row>
                <Table.Cell>
                    <IssuingAuthorityIndicator issuingAuthority={authorization.issuingAuthority} />
                </Table.Cell>
                <Table.Cell>
                    {
                        TranslateProps(intl, authorizationTypeOptions).find(
                            t => t.value === authorization.type
                        )?.text
                    }
                    {formatRanges(authorization)}
                </Table.Cell>
                <Table.Cell>{authorization.qualificationID}</Table.Cell>
                <Table.Cell>{authorization.linkedQualificationID}</Table.Cell>
                <Table.Cell>{formatDate(authorization.endDate)}</Table.Cell>
                <Table.Cell>
                    {
                        TranslateProps(intl, certificateStatusOptions).find(
                            x => x.value === authorization.status
                        )?.text
                    }
                </Table.Cell>
                <Table.Cell collapsing>
                    <Dropdown
                        id="certificates-options"
                        floating
                        text={intl.formatMessage({ id: "options" })}
                        loading={loading}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                className="download-button"
                                text={`${intl.formatMessage({
                                    id: "download",
                                })} (${intl.formatMessage({ id: "authorization" })})`}
                                onClick={
                                    authorization.type === AuthorizationType.R
                                        ? () => handleDownloadAuthorization()
                                        : () => setModalOpen(true)
                                }
                                disabled={
                                    authorization.issuingAuthority !== "CH01" ||
                                    authorization.linkedQualificationID.includes("CH01") ||
                                    (authorization.type !== AuthorizationType.R &&
                                        authorization.type !== AuthorizationType.S)
                                }
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
                <Table.Cell style={{ textAlign: "right" }}>
                    {authorization.requestFeedbacks.length > 0 && (
                        <RequestFeedbackIndicator feedbacks={authorization.requestFeedbacks} />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
                dimmer="blurring"
                size="small"
                closeOnDimmerClick={!loading}
                closeIcon={true}
            >
                <Modal.Header>{intl.formatMessage({ id: "boatmasterLicence" })}</Modal.Header>
                <Modal.Actions>
                    {" "}
                    <Button.Group>
                        {boatmasterLicenceOptions.map((option, i) => (
                            <>
                                <Button
                                    key={i}
                                    onClick={() => {
                                        setModalOpen(false);
                                        handleDownloadAuthorization(option.value as number);
                                    }}
                                >
                                    {
                                        TranslateProps(intl, boatmasterLicenceOptions).find(
                                            b => b.value === option.value
                                        )?.text as string
                                    }
                                </Button>
                                {i < boatmasterLicenceOptions.length - 1 && <Button.Or />}
                            </>
                        ))}
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        </>
    );
};
