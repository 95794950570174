import { Route, Routes } from "react-router-dom";
import { Layout } from "./containers/Layout/Layout";
import { SearchPage } from "./containers/SearchPage/SearchPage";
import { Unauthorized } from "./containers/Unauthorized/Unauthorized";
import { CrewMember } from "./containers/CrewMember/CrewMember";
import { ChangeLog } from "./containers/ChangeLog/ChangeLog";
import { fetchCountriesAndNationalities } from "./store/nationalitiesReducer";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Statistics } from "./containers/Statistics/Statistics";
import { Inbox } from "./containers/Inbox/Inbox";
import { InboxMessageDetails } from "./containers/Inbox/InboxMessageDetails";
import { fetchUnreadMessages } from "./store/unreadMessagesReducer";
import { logout } from "./actions/authentificationActions";
import { AppState } from "./store/configureStore";
import { Buffer } from "buffer";
import { fetchSharepointSettings } from "./store/sharepointSettingsReducer";
import "./App.css";

const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

    const jsonPayload = decodeURIComponent(
        Buffer.from(base64, "base64")
            .toString()
            .replace(/ü/g, "u")
            .replace(/ö/g, "o")
            .replace(/ä/g, "a")
            .split("")
            .map(c => {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(jsonPayload).exp;
};

export const App = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const exp = parseJwt(user.token!) as number;

    const fetchDataCallback = useCallback(() => {
        fetchCountriesAndNationalities(dispatch);
        fetchSharepointSettings(dispatch);
        if (user.ecdbConnection) fetchUnreadMessages(dispatch);
    }, [dispatch, user.ecdbConnection]);

    useEffect(() => {
        if (window.location.host.includes("test.")) document.title = "TEST " + document.title;
    }, []);

    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (new Date().getTime() > new Date(exp * 1000).getTime()) {
                localStorage.setItem("autoLogout", "true");
                dispatch(logout());
            }
            if (user.ecdbConnection) fetchUnreadMessages(dispatch);
        }, 30000);
        return () => clearInterval(intervalId);
    }, [dispatch, exp, user.ecdbConnection]);

    return (
        <Layout>
            <Routes>
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/crew-members/:guid" element={<CrewMember />} />
                <Route path="/change-log" element={<ChangeLog />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/inbox">
                    <Route index element={<Inbox />} />
                    <Route path=":guid" element={<InboxMessageDetails />}></Route>
                </Route>
                <Route path="/search" element={<SearchPage />} />
                <Route path="*" element={<SearchPage />} />
            </Routes>
        </Layout>
    );
};
