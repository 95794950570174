import { Flag, FlagNameValues } from "semantic-ui-react";

type Props = {
    issuingAuthority: string;
};

export const IssuingAuthorityIndicator = ({ issuingAuthority }: Props) => {
    return (
        <>
            <Flag
                name={
                    issuingAuthority.toLowerCase().substring(0, 2) === "ec"
                        ? "eu"
                        : (issuingAuthority.toLowerCase().substring(0, 2) as FlagNameValues)
                }
            />
            {issuingAuthority}
        </>
    );
};
