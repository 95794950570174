import { useCallback, useEffect, useRef, useState } from "react";
import {
    ecdbIdentityResponseInit,
    idDataSetsFilterInit,
    IIDDataSet,
    IIDDataSetFilter,
} from "../../models/crewMember";
import { SearchPageResults } from "./SearchPageResults";
import { CSSTransition } from "react-transition-group";
import { SearchPageFilters } from "./SearchPageFilters";
import { Button } from "semantic-ui-react";
import { CrewMemberFormModal } from "../CrewMember/CrewMemberFormModal";
import { useIntl } from "react-intl";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { UserRole } from "../../actions/authentificationActions";
import { agent } from "../../api/agent";
import { useLocation } from "react-router-dom";
import "./SearchPage.css";

export const SearchPage = () => {
    const loc = useLocation();
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [queryFilter, setQueryFilter] = useState(idDataSetsFilterInit);
    const [idDataSets, setIdDataSets] = useState<IIDDataSet[]>([]);
    const [ecdbResponse, setEcdbResponse] = useState(ecdbIdentityResponseInit);
    const [modalOpen, setModalOpen] = useState(false);
    const [identityRequestId, setIdentityRequestId] = useState("");
    const [detailRequestId, setDetailRequestId] = useState("");
    const [loaded, setLoaded] = useState(false);
    const currentRef = useRef<string[]>([]);

    const fetchCrewMembers = useCallback(
        async (filter: IIDDataSetFilter, searchByCid: boolean) => {
            setIdDataSets([]);
            setEcdbResponse(ecdbIdentityResponseInit);
            setLoaded(false);
            currentRef.current = [];
            try {
                const res = await agent.CrewMembers.getIdDataSets(filter, searchByCid);
                setIdDataSets(res);
                setLoaded(true);
                if (user.ecdbConnection) {
                    const ids = await agent.CrewMembers.sendIdentityRequest(filter, searchByCid);
                    setIdentityRequestId(ids.identityRequestId.replace(/"/g, "").replace(/-/g, ""));
                    setDetailRequestId(ids.detailRequestId.replace(/"/g, "").replace(/-/g, ""));
                }
            } catch {
                toast(intl.formatMessage({ id: "unableToFetchDataSets" }), false);
            }
        },
        [intl, user.ecdbConnection]
    );

    const fetchResponsesFromECDB = useCallback(async () => {
        const res = await agent.CrewMembers.getIdentityResponses(
            identityRequestId,
            currentRef.current
        );
        setEcdbResponse(res);
        currentRef.current = res.current;
        setIdDataSets(prev => {
            res.dataSets.forEach(s => {
                if (!prev.some(x => x.guid === s.guid)) prev.push(s);
            });
            return [...prev.sort((a, b) => a.cid.localeCompare(b.cid))];
        });
    }, [identityRequestId]);

    useEffect(() => {
        if (!!identityRequestId && user.ecdbConnection) {
            fetchResponsesFromECDB();
            let count = 1;
            const intervalId = setInterval(() => {
                if (count >= 24) setIdentityRequestId("");
                fetchResponsesFromECDB();
                count++;
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [identityRequestId, fetchResponsesFromECDB, user.ecdbConnection]);

    useEffect(() => {
        if (loc.state && typeof loc.state === "string") {
            const qrCid = loc.state as string;
            const newFilter: IIDDataSetFilter = { ...idDataSetsFilterInit, cid: qrCid };
            setQueryFilter(newFilter);
            fetchCrewMembers(newFilter, true);
        }
    }, [fetchCrewMembers, loc.state]);

    return (
        <div className="search-page-container">
            <div className="container-header">
                <h1>{intl.formatMessage({ id: "searchCrewMember" })}</h1>
                {user.role !== UserRole.EnforcementAuthorityUser && (
                    <Button
                        primary
                        icon="plus"
                        content={intl.formatMessage({ id: "addCrewMember" })}
                        onClick={() => setModalOpen(true)}
                    />
                )}
            </div>
            <div className="search-container">
                <SearchPageFilters
                    queryFilter={queryFilter}
                    setQueryFilter={setQueryFilter}
                    fetchCrewMembers={fetchCrewMembers}
                />
                <CSSTransition in={loaded} timeout={100} classNames="fade" unmountOnExit>
                    <SearchPageResults
                        idDataSets={idDataSets}
                        ecdbFetching={!!identityRequestId}
                        ecdbResponse={ecdbResponse}
                        detailRequestId={detailRequestId}
                    />
                </CSSTransition>
            </div>
            {modalOpen && (
                <CrewMemberFormModal open={modalOpen} closeModal={() => setModalOpen(false)} />
            )}
        </div>
    );
};
