export const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "#5f6774",
    borderStyle: "dashed",
    color: "#5f6774",
    outline: "none",
    transition: "border .24s ease-in-out",
    fontFamily: "Roboto",
    fontWeight: "bold",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "10px",
};

export const activeStyle = {
    borderColor: "#2196f3",
};

export const acceptStyle = {
    borderColor: "#43a047",
};

export const rejectStyle = {
    borderColor: "#c0392b",
};
