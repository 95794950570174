import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "semantic-ui-react";
import { setLanguage } from "../../actions/authentificationActions";
import { languageProps } from "../../models/common";
import { AppState } from "../../store/configureStore";
import "./LanguageSelector.css";

localStorage.setItem("language", "de");

export const LanguageSelector = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [localLanguage, setLocalLanguage] = useState(localStorage.getItem("language")!);

    const handleChangeLanguage = async (value: string) => {
        if (user.guid && value !== user.language) {
            dispatch(setLanguage(value));
            sessionStorage.setItem("user", JSON.stringify({ ...user, language: value }));
        } else {
            localStorage.setItem("language", value);
            setLocalLanguage(value);
            dispatch(setLanguage(value));
        }
    };

    return (
        <Select
            className="language-selector"
            selectOnBlur={false}
            options={languageProps}
            value={user.guid ? user.language : localLanguage}
            onChange={(e, data: any) => handleChangeLanguage(data.value)}
        />
    );
};
