import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Message, Modal, Select, TextArea } from "semantic-ui-react";
import { toast } from "../../..";
import { UserRole } from "../../../actions/authentificationActions";
import { DateInput } from "../../../components/DateInput/DateInput";
import { TranslateProps } from "../../../i18n/TranslateProps";
import {
    CertificateStatus,
    certificateStatusOptions,
    ICertificate,
} from "../../../models/certificate";
import {
    compareDates,
    constructDate,
    dateInit,
    deconstructDate,
    getTodayDate,
} from "../../../models/common";
import { AppState } from "../../../store/configureStore";
import { agent } from "../../../api/agent";

type Props = {
    open: boolean;
    close: () => void;
    certificate: ICertificate;
    fetchCrewMember: () => Promise<void>;
};

export const ModifyCertificateForm = ({ open, close, certificate, fetchCrewMember }: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [status, setStatus] = useState(
        user.role === UserRole.EnforcementAuthorityUser ||
            certificate.issuingAuthority !== user.authority
            ? CertificateStatus.Suspended
            : certificate.status
    );
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(
        !certificate.suspensionStartDate || certificate.suspensionStartDate.includes("0001-")
            ? getTodayDate()
            : deconstructDate(certificate.suspensionStartDate)
    );
    const [endDate, setEndDate] = useState(
        !certificate.suspensionEndDate || certificate.suspensionEndDate.includes("0001-")
            ? dateInit
            : deconstructDate(certificate.suspensionEndDate)
    );
    const [reason, setReason] = useState(certificate.reason ?? "");
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const options =
        certificate.issuingAuthority === user.authority
            ? certificateStatusOptions
            : certificateStatusOptions.filter(
                  x =>
                      x.value === CertificateStatus.Suspended ||
                      x.value === CertificateStatus.Withdrawn
              );

    const handleModifyCertificate = async () => {
        setLoading(true);
        try {
            const dto: Partial<ICertificate> = {
                status: status,
                suspensionStartDate: constructDate(startDate),
                suspensionEndDate: constructDate(endDate),
                reason: reason,
                qualificationID: certificate.qualificationID,
                type: certificate.type,
                rhineLicenceType: certificate.rhineLicenceType,
                highRhineLicenceType: certificate.highRhineLicenceType,
                extensionBoatmasterType: certificate.extensionBoatmasterType,
            };
            await agent.Certificates.modify(dto);
            if (
                status === CertificateStatus.Suspended &&
                user.role !== UserRole.EnforcementAuthorityUser &&
                certificate.issuingAuthority !== user.authority
            ) {
                setLoading(false);
                setWithdrawModalOpen(true);
            } else {
                toast(intl.formatMessage({ id: "updatedCertificate" }), true);
                await fetchCrewMember();
            }
        } catch {
            toast(intl.formatMessage({ id: "unableToUpdateCertificate" }), false);
        } finally {
            setLoading(false);
            close();
        }
    };

    const handleSendWithdrawRequest = async () => {
        setLoading(true);
        try {
            await agent.Certificates.sendWithdrawalRequest(certificate.qualificationID, reason);
            toast(intl.formatMessage({ id: "updatedCertificate" }), true);
            await fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToUpdateCertificate" }), false);
        } finally {
            setLoading(false);
            setWithdrawModalOpen(false);
            close();
        }
    };

    useEffect(() => {
        setEndDate(
            !certificate.suspensionEndDate || certificate.suspensionEndDate.includes("0001-")
                ? dateInit
                : deconstructDate(certificate.suspensionEndDate)
        );
        setReason(certificate.reason !== "" ? certificate.reason : "");
    }, [certificate.reason, certificate.suspensionEndDate, status]);

    useEffect(() => {
        //compare suspension dates
        if (!compareDates(startDate, endDate)) setDatesError(true);
        else setDatesError(false);
        //compare suspension start date with certificate start date
        if (!compareDates(deconstructDate(certificate.startDate), startDate, true))
            setStartDateError(true);
        else setStartDateError(false);
        //compare suspension end date with certificate end date
        if (!compareDates(endDate, deconstructDate(certificate.endDate), true))
            setEndDateError(true);
        else setEndDateError(false);
    }, [certificate.endDate, certificate.startDate, endDate, startDate]);

    return (
        <>
            <Modal
                open={open}
                onClose={() => close()}
                dimmer="blurring"
                size="small"
                className="modify-certificate-modal"
                closeOnDimmerClick={!loading}
            >
                <Modal.Header>{intl.formatMessage({ id: "modifyCertificate" })}</Modal.Header>
                <Modal.Content>
                    <section className="input-container" id="topAddCertificate">
                        <label>{intl.formatMessage({ id: "certificateStatus" })}</label>
                        <Select
                            search
                            fluid
                            options={TranslateProps(intl, options)}
                            value={status}
                            onChange={(e, data: any) => setStatus(+data.value)}
                            disabled={user.role === UserRole.EnforcementAuthorityUser}
                        />
                    </section>
                    {status === CertificateStatus.Suspended ||
                    status === CertificateStatus.Withdrawn ? (
                        <>
                            <form className="add-certificate-form">
                                <section className="input-container">
                                    <label>
                                        {intl.formatMessage({ id: "startDate" })}
                                        <span className="input-required">*</span>
                                    </label>
                                    <DateInput
                                        date={startDate}
                                        setDate={setStartDate}
                                        extendedYears
                                    />
                                </section>
                                {status === CertificateStatus.Suspended && (
                                    <section className="input-container">
                                        <label>
                                            {intl.formatMessage({ id: "endDate" })}
                                            <span className="input-required">*</span>
                                        </label>
                                        <DateInput
                                            date={endDate}
                                            setDate={setEndDate}
                                            extendedYears
                                        />
                                    </section>
                                )}
                            </form>
                            <section id="bottomAddCertificate" className="input-container">
                                <label>
                                    {intl.formatMessage({ id: "comments" })}
                                    <span className="input-required">*</span>
                                </label>
                                <TextArea
                                    value={reason}
                                    onChange={e => setReason(e.target.value)}
                                    rows={5}
                                />
                            </section>
                            {status === CertificateStatus.Suspended && (
                                <>
                                    {datesError ? (
                                        <Message
                                            className="error-message"
                                            icon="exclamation circle"
                                            content={intl.formatMessage({
                                                id: "datesCompareError",
                                            })}
                                        />
                                    ) : startDateError ? (
                                        <Message
                                            className="error-message"
                                            icon="exclamation circle"
                                            content={intl.formatMessage({
                                                id: "suspensionStartDateError",
                                            })}
                                        />
                                    ) : endDateError ? (
                                        <Message
                                            className="error-message"
                                            icon="exclamation circle"
                                            content={intl.formatMessage({
                                                id: "suspensionEndDateError",
                                            })}
                                        />
                                    ) : null}
                                </>
                            )}
                        </>
                    ) : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        onClick={() =>
                            certificate.issuingAuthority !== user.authority &&
                            status === CertificateStatus.Withdrawn
                                ? handleSendWithdrawRequest()
                                : handleModifyCertificate()
                        }
                        content={intl.formatMessage({ id: "saveChanges" })}
                        fluid
                        loading={loading}
                        disabled={
                            loading ||
                            (status === CertificateStatus.Suspended &&
                                (!reason || datesError || startDateError || endDateError)) ||
                            (status === CertificateStatus.Withdrawn && !reason)
                        }
                    />
                </Modal.Actions>
            </Modal>
            <Modal
                open={withdrawModalOpen}
                onClose={() => setWithdrawModalOpen(false)}
                dimmer="blurring"
                size="tiny"
            >
                <Modal.Header>{intl.formatMessage({ id: "requestForWithdrawal" })}</Modal.Header>
                <Modal.Actions>
                    <Button
                        negative
                        onClick={() => setWithdrawModalOpen(false)}
                        content={intl.formatMessage({ id: "cancel" })}
                        disabled={loading}
                    />
                    <Button
                        primary
                        onClick={() => handleSendWithdrawRequest()}
                        content={intl.formatMessage({ id: "confirm" })}
                        loading={loading}
                        disabled={loading}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
