import { IntlShape } from "react-intl";
import { DropdownItemProps } from "semantic-ui-react";
import { ICountryNationalityProps } from "../store/nationalitiesReducer";

export const TranslateProps = (
    intl: IntlShape,
    props: DropdownItemProps[],
    sort: boolean = false,
    isEU: boolean = false
) => {
    const translated: DropdownItemProps[] = props.map(x => ({
        ...x,
        // boatmaster (key = 1) should be translated as CH/CCNR
        text: intl.formatMessage({ id: (isEU && x.key === 1 ? x.content : x.text) as string }),
        content: intl.formatMessage({ id: x.text as string }),
    }));
    if (sort) {
        return translated.sort((a, b) => (a.text as string).localeCompare(b.text as string));
    } else {
        return translated;
    }
};

export const TranslateCountryProps = (
    props: ICountryNationalityProps[],
    locale: string,
    addNa: boolean = false
) => {
    if (locale !== "en" && locale !== "de") return props;
    const sortedProps = props
        .filter(x => (x.value as number) >= 0)
        .sort((a, b) => (a[locale] as string).localeCompare(b[locale] as string));
    if (addNa) {
        const na: ICountryNationalityProps = {
            key: -1,
            text: "NotSpecified",
            value: -1,
            en: "",
            de: "",
        };
        sortedProps.unshift(na);
    }
    return sortedProps.map(x => ({ ...x, text: x[locale] }));
};
