import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DropdownProps, Icon, Input, InputOnChangeData, Label, Select } from "semantic-ui-react";
import { TranslateProps } from "../../../i18n/TranslateProps";
import {
    AuthorizationType,
    authorizationTypeOptions,
    IAuthorization,
    newAuthorizationInit,
} from "../../../models/authorization";
import { CertificateType, ICertificate } from "../../../models/certificate";
import { ucqRiverRangeProps } from "../../../models/riverRanges";
import "./AuthorizationsForm.css";

type Props = {
    certificate: ICertificate;
    setCertificate: Dispatch<SetStateAction<ICertificate>>;
};

const stretchRegex: RegExp = /^[A-Z][A-Z-]-\d\d\d\d-\d\d\d\d$/;

export const AuthorizationsForm = ({ certificate, setCertificate }: Props) => {
    const intl = useIntl();

    const [invalidStretch, setInvalidStretch] = useState(false);
    const [manualStretches, setManualStretches] = useState<string>();

    useEffect(() => {
        if (manualStretches === undefined) {
            const manStretches = certificate.authorizations
                .filter(a => a.type === AuthorizationType.S)
                .filter(a => a.ucqManualRiverKilometers !== "")
                .filter((value, index, array) => array.indexOf(value) === index) // remove duplicates
                .map(a => a.ucqManualRiverKilometers)
                // split by semi-colon if multiple stretches are present in one authorization
                .map(x => x.split(";"))
                // to one dimensional array
                .flat()
                // filter by regex
                .filter(x => stretchRegex.exec(x))
                .join(";");

            // add semi-colon to the end of the string if exists
            if (manStretches) {
                setManualStretches(`${manStretches};`);
            }
        }
    }, [certificate, manualStretches]);

    const handleAddRiverRanges = (values: number[]) => {
        setCertificate({
            ...certificate,
            authorizations: certificate.authorizations.map(a => ({
                ...a,
                ucqRiverKilometers: a.type === AuthorizationType.S ? values : [],
            })),
        });
    };

    const handleSpecificAuthorizations = (newTypes: number[]) => {
        // get all stretches from S type authorizations
        const strecthes = certificate.authorizations
            .filter(a => a.type === AuthorizationType.S)
            .filter((value, index, array) => array.indexOf(value) === index) // remove duplicates
            ?.map(x => x.ucqRiverKilometers)
            .flat();

        // remove manual stretches if no S type authorization is selected
        if (!newTypes.includes(AuthorizationType.S)) {
            setManualStretches("");
        }

        const newAuths: IAuthorization[] = [];
        newTypes.forEach(t =>
            newAuths.push({
                ...newAuthorizationInit,
                type: t as AuthorizationType,
                linkedCertificateGuid: certificate.guid,
                startDate: certificate.startDate,
                endDate: certificate.endDate,
                issuingAuthority: certificate.issuingAuthority,
                status: certificate.status,
                linkedQualificationID: certificate.qualificationID,
                crewMemberGuid: certificate.crewMemberGuid,
                ucqRiverKilometers: t === AuthorizationType.S ? strecthes : [],
                ucqManualRiverKilometers: t === AuthorizationType.S ? manualStretches ?? "" : "",
            })
        );

        setCertificate({
            ...certificate,
            authorizations: newAuths,
        });
    };

    const validateStretch = (stretch: string) => {
        if (stretchRegex.exec(stretch)) {
            setInvalidStretch(false);
        }
    };

    const handleAddManualRanges = (e: any) => {
        if (e.key === "Enter" || e.charCode === 13) {
            setInvalidStretch(false);
            if (stretchRegex.exec(e.target.value)) {
                setManualStretches(`${manualStretches ?? ""}${e.target.value};`);
                setCertificate({
                    ...certificate,
                    authorizations: certificate.authorizations.map(a => ({
                        ...a,
                        ucqManualRiverKilometers:
                            a.type === AuthorizationType.S
                                ? `${manualStretches ?? ""}${e.target.value};`
                                : "",
                    })),
                });
            } else {
                setInvalidStretch(true);
            }
        }
    };

    const handleRemoveStretch = (s: string) => {
        const strecthes = certificate.authorizations
            .filter(a => a.type === AuthorizationType.S)
            .filter((value, index, array) => array.indexOf(value) === index) // remove duplicates
            ?.map(x => x.ucqRiverKilometers)
            .flat();

        setManualStretches(manualStretches?.replace(`${s};`, ""));
        const newAuth: IAuthorization = {
            ...newAuthorizationInit,
            type: AuthorizationType.S,
            startDate: certificate.startDate,
            endDate: certificate.endDate,
            issuingAuthority: certificate.issuingAuthority,
            status: certificate.status,
            linkedQualificationID: certificate.qualificationID,
            crewMemberGuid: certificate.crewMemberGuid,
            ucqRiverKilometers: strecthes,
            ucqManualRiverKilometers: manualStretches?.replace(`${s};`, "") ?? "",
        };

        setCertificate({
            ...certificate,
            authorizations: certificate.authorizations
                .filter(a => a.type !== AuthorizationType.S)
                .concat([newAuth]),
        });
    };

    return (
        <>
            <section className="input-container" style={{ paddingBottom: "10px" }}>
                <label>{intl.formatMessage({ id: "specificAuthorizations" })}</label>
                <Select
                    search
                    multiple
                    options={TranslateProps(intl, authorizationTypeOptions)}
                    value={certificate.authorizations
                        .map(a => a.type)
                        .filter((value, index, array) => array.indexOf(value) === index)} // remove duplicates
                    onChange={(e, data: any) => handleSpecificAuthorizations(data.value)}
                    disabled={certificate.type !== CertificateType.UcqBoatmaster}
                    closeOnChange
                />
            </section>
            <div className="form-row two">
                {certificate.authorizations.map(a => a.type).includes(AuthorizationType.S) && (
                    <>
                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "range" })}</label>
                            <Select
                                search
                                multiple
                                options={ucqRiverRangeProps}
                                value={
                                    certificate.authorizations
                                        .filter(a => a.type === AuthorizationType.S)
                                        .filter(
                                            (value, index, array) => array.indexOf(value) === index
                                        ) // remove duplicates
                                        ?.map(x => x.ucqRiverKilometers)
                                        .flat() ?? []
                                }
                                onChange={(_e, data: DropdownProps) =>
                                    handleAddRiverRanges(data.value as number[])
                                }
                            />
                        </section>

                        <section className="input-container">
                            <label>{intl.formatMessage({ id: "manualAddSections" })}</label>
                            <Input
                                className={invalidStretch ? "invalidStretch" : ""}
                                onChange={(
                                    _e: ChangeEvent<HTMLInputElement>,
                                    data: InputOnChangeData
                                ) => {
                                    validateStretch(data.value);
                                }}
                                onKeyPress={(e: any) => {
                                    handleAddManualRanges(e);
                                }}
                            />
                            {manualStretches
                                ?.split(";")
                                // remove empty strings
                                .filter(s => s !== "")
                                .map((s, i) => (
                                    <Label as="a" key={i}>
                                        {s}
                                        <Icon
                                            name="delete"
                                            onClick={() => handleRemoveStretch(s)}
                                        />
                                    </Label>
                                ))}
                            {invalidStretch && (
                                <label className="invalidLabel">
                                    {intl.formatMessage({ id: "wrongStretchCodeFormat" })}
                                </label>
                            )}
                        </section>
                    </>
                )}
            </div>
        </>
    );
};
