import { useMemo, useCallback, useState, Dispatch, SetStateAction, useEffect } from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import ReactCrop, { Crop, makeAspectCrop } from "react-image-crop";
import { useIntl } from "react-intl";
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from "./dropzoneStyles";
import "react-image-crop/dist/ReactCrop.css";

type Props = {
    image: File | undefined;
    setImage: Dispatch<SetStateAction<File | undefined>>;
    crop: Crop | undefined;
    setCrop: Dispatch<SetStateAction<Crop | undefined>>;
    currentImageBase64: string;
    signature?: boolean;
};

export const ImageDropzoneCropper = ({
    image,
    setImage,
    crop,
    setCrop,
    currentImageBase64,
    signature,
}: Props) => {
    const [preview, setPreview] = useState("");
    const intl = useIntl();
    const cropWidth = useMemo(() => (signature ? 500 : 390), []);
    const cropHeight = useMemo(() => (signature ? 200 : 490), []);
    const aspect = useMemo(() => cropWidth / cropHeight, []);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setImage(acceptedFiles[0]);
        },
        [setImage]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: { "image/*": [] },
        onDrop,
        maxFiles: 1,
    });

    const style: DropzoneRootProps = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const centerAspectCrop = (mediaWidth: number, mediaHeight: number) => {
        return makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        );
    };

    const onImageLoaded = (image: HTMLImageElement) => {
        setCrop(centerAspectCrop(image.width, image.height) as Crop);
    };

    useEffect(() => {
        if (image) {
            setPreview((window.URL ? URL : webkitURL).createObjectURL(image));
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                const img = new Image();
                img.src = e.target ? (e.target.result as string) : "";
                if (!!img.src)
                    img.onload = () => {
                        setCrop(centerAspectCrop(img.width, img.height) as Crop);
                    };
            };
        }
    }, [image]);

    return (
        <div className={`image-dropzone-container` + (signature ? " signature" : "")}>
            <section {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {image ? image.name : intl.formatMessage({ id: "dragAndDrop" })}
            </section>
            {image ? (
                <ReactCrop crop={crop} onChange={(_, p) => setCrop(p)} aspect={aspect}>
                    <img src={preview} alt="Crop" onLoad={e => onImageLoaded(e.currentTarget)} />
                </ReactCrop>
            ) : signature && currentImageBase64 ? (
                <img src={`data:image/jpg;base64,${currentImageBase64}`} alt="Signature" />
            ) : currentImageBase64 ? (
                <img src={`data:image/jpg;base64,${currentImageBase64}`} alt="User" />
            ) : null}
        </div>
    );
};

export const getCroppedImg = async (crop: Crop | undefined, signature: boolean) => {
    if (!crop) return undefined;
    const parentDiv = signature
        ? document.querySelector<HTMLDivElement>("div.image-dropzone-container.signature")
        : document.querySelector<HTMLDivElement>("div.image-dropzone-container:not(.signature)");
    if (!parentDiv) return undefined;
    const image = parentDiv.querySelector<HTMLImageElement>("img");
    if (!image) return undefined;
    const canvas = document.createElement("canvas");
    canvas.width = (image.naturalWidth * crop.width) / 100;
    canvas.height = (image.naturalHeight * crop.height) / 100;
    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(
        image,
        (crop.x / 100) * image.naturalWidth,
        (crop.y / 100) * image.naturalHeight,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
    );
    return new Promise<File>(resolve => {
        canvas.toBlob(
            blob => {
                resolve(blob as File);
            },
            "image",
            1
        );
    });
};
