import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { DropdownItemProps, Input, Select, TextArea } from "semantic-ui-react";
import { DateInput } from "../../../components/DateInput/DateInput";
import { TranslateProps } from "../../../i18n/TranslateProps";
import {
    adnExamOptions,
    boatmasterLicenceOptions,
    BoatmasterLicenceType,
    CertificateType,
    certificateTypeOptions,
    extensionBoatmasterOptions,
    ExtensionBoatmasterType,
    highRhineLicenceOptions,
    HighRhineLicenceType,
    ICertificate,
    rhineLicenceOptions,
    RhineLicenceType,
} from "../../../models/certificate";
import { dateInit, getCertificateEndDate, IDate } from "../../../models/common";
import { stretchesRiverRangeProps } from "../../../models/riverRanges";
import "./CertificateDetailsForm.css";

type Props = {
    certificate: ICertificate;
    setCertificate: Dispatch<SetStateAction<ICertificate>>;
    startDate: IDate;
    setStartDate: Dispatch<SetStateAction<IDate>>;
    endDate: IDate;
    setEndDate: Dispatch<SetStateAction<IDate>>;
    dateOfBirth: string;
    fitnessEndDate: IDate;
    setFitnessStartDate: Dispatch<SetStateAction<IDate>>;
    setFitnessEndDate: Dispatch<SetStateAction<IDate>>;
    renew: boolean;
    availableCertificatesTypes: DropdownItemProps[];
    currentStretchesRiverKilometers: number[];
    activeRhineLicences: RhineLicenceType[];
    activeHighRhineLicences: HighRhineLicenceType[];
    activeBoatmasterLicences: BoatmasterLicenceType[];
    activeExtensionLicences: ExtensionBoatmasterType[];
    edit: boolean;
};

export const CertificateDetailsForm = ({
    certificate,
    setCertificate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateOfBirth,
    fitnessEndDate,
    setFitnessStartDate,
    setFitnessEndDate,
    renew,
    availableCertificatesTypes,
    currentStretchesRiverKilometers,
    activeRhineLicences,
    activeHighRhineLicences,
    activeBoatmasterLicences,
    activeExtensionLicences,
    edit,
}: Props) => {
    const intl = useIntl();
    const endDateRef = useRef(endDate);
    const fitnessEndDateRef = useRef(fitnessEndDate);

    useEffect(() => {
        if ((endDateRef.current === dateInit || !certificate.isUnlimited) && !edit) {
            setEndDate(getCertificateEndDate(dateOfBirth, certificate.type, startDate));
            setFitnessEndDate(getCertificateEndDate(dateOfBirth, certificate.type, startDate));
        } else if (endDateRef.current.year === 1) setEndDate(dateInit);
        if (fitnessEndDateRef.current === dateInit || fitnessEndDateRef.current.year === 1) {
            setFitnessEndDate(getCertificateEndDate(dateOfBirth, certificate.type, startDate));
        }
    }, [
        dateOfBirth,
        certificate.type,
        setEndDate,
        setFitnessEndDate,
        startDate,
        certificate.isUnlimited,
        edit,
    ]);

    const handleStretchesRiverKilometers = (newRK: number[]) => {
        if (
            !currentStretchesRiverKilometers.every(r => newRK.includes(r)) ||
            newRK.length > currentStretchesRiverKilometers.length + 1
        )
            setCertificate({
                ...certificate,
                stretchesRiverKilometers: certificate.stretchesRiverKilometers,
            });
        else {
            const diff = newRK.filter(r => !currentStretchesRiverKilometers.includes(r));
            setCertificate({
                ...certificate,
                stretchesRiverKilometers: currentStretchesRiverKilometers.concat(diff),
            });
        }
    };

    return (
        <>
            <div className="form-row four">
                <section id="form-row-span-two-first" className="input-container">
                    <label>
                        {intl.formatMessage({ id: "certificateType" })}
                        <span className="input-required">*</span>
                    </label>
                    <Select
                        selectOnBlur={false}
                        search
                        fluid
                        options={
                            renew
                                ? TranslateProps(intl, certificateTypeOptions)
                                : availableCertificatesTypes
                                      // remove boatmaster on the rhine from the list of available certificates
                                      .filter(
                                          type =>
                                              type.value !== CertificateType.BoatmasterOnTheRhine
                                      )
                        }
                        value={certificate.type}
                        onChange={(e, data: any) => {
                            setCertificate({
                                ...certificate,
                                type: +data.value,
                                isUnlimited: false,
                            });
                        }}
                        disabled={edit || renew}
                    />
                </section>
                <section className="input-container">
                    {certificate.type === CertificateType.StretchesWithSpecificRisks && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "range" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                search
                                multiple
                                options={stretchesRiverRangeProps}
                                value={certificate.stretchesRiverKilometers}
                                onChange={(e, data: any) =>
                                    handleStretchesRiverKilometers(data.value)
                                }
                                closeOnChange
                            />
                        </section>
                    )}
                    {certificate.type === CertificateType.AdnBescheinigung && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "adnExamType" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                options={TranslateProps(intl, adnExamOptions)}
                                value={certificate.adnExamType}
                                onChange={(e, data: any) =>
                                    setCertificate({ ...certificate, adnExamType: data.value })
                                }
                            />
                        </section>
                    )}
                    {certificate.type === CertificateType.BoatmasterOnTheRhine && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "type" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                options={
                                    edit || renew
                                        ? TranslateProps(intl, rhineLicenceOptions)
                                        : TranslateProps(intl, rhineLicenceOptions).filter(
                                              x => !activeRhineLicences.includes(x.value as number)
                                          )
                                }
                                value={certificate.rhineLicenceType}
                                onChange={(e, data: any) =>
                                    setCertificate({ ...certificate, rhineLicenceType: data.value })
                                }
                                disabled={edit || renew}
                            />
                        </section>
                    )}
                    {certificate.type === CertificateType.BoatmasterOnTheHighRhine && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "type" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                options={
                                    edit || renew
                                        ? TranslateProps(intl, highRhineLicenceOptions)
                                        : TranslateProps(intl, highRhineLicenceOptions).filter(
                                              x =>
                                                  !activeHighRhineLicences.includes(
                                                      x.value as number
                                                  )
                                          )
                                }
                                value={certificate.highRhineLicenceType}
                                onChange={(e, data: any) =>
                                    setCertificate({
                                        ...certificate,
                                        highRhineLicenceType: data.value,
                                    })
                                }
                                disabled={edit || renew}
                            />
                        </section>
                    )}
                    {certificate.type === CertificateType.UcqBoatmaster && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "type" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                options={
                                    edit || renew
                                        ? TranslateProps(intl, boatmasterLicenceOptions)
                                        : TranslateProps(intl, boatmasterLicenceOptions).filter(
                                              x =>
                                                  !activeBoatmasterLicences.includes(
                                                      x.value as number
                                                  )
                                          )
                                }
                                value={certificate.boatmasterLicenceType}
                                onChange={(e, data: any) =>
                                    setCertificate({
                                        ...certificate,
                                        boatmasterLicenceType: data.value,
                                    })
                                }
                                disabled={edit || renew}
                            />
                        </section>
                    )}
                    {certificate.type === CertificateType.ExtensionBoatmasterOnTheRhine && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "type" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                options={TranslateProps(intl, extensionBoatmasterOptions)}
                                value={certificate.extensionBoatmasterType}
                                onChange={(e, data: any) =>
                                    setCertificate({
                                        ...certificate,
                                        extensionBoatmasterType: data.value,
                                    })
                                }
                                disabled={edit || renew}
                            />
                        </section>
                    )}
                </section>
                <section className="input-container">
                    <label>
                        {intl.formatMessage({ id: "startDate" })}
                        <span className="input-required">*</span>
                    </label>
                    <DateInput
                        date={startDate}
                        setDate={setStartDate}
                        setAdditionalDate={setFitnessStartDate}
                        extendedYears
                    />
                </section>
                <section className="input-container">
                    <label>
                        {intl.formatMessage({ id: "endDate" })}
                        {certificate.type !== CertificateType.StretchesWithSpecificRisks &&
                            certificate.type !== CertificateType.HighRhineCertificate &&
                            certificate.type !== CertificateType.Radarpatent && (
                                <span className="input-required">*</span>
                            )}
                    </label>
                    <DateInput
                        date={certificate.isUnlimited ? dateInit : endDate}
                        setDate={setEndDate}
                        setAdditionalDate={setFitnessEndDate}
                        extendedYears
                        disabled={certificate.isUnlimited}
                        clearable={
                            certificate.type === CertificateType.StretchesWithSpecificRisks ||
                            certificate.type === CertificateType.HighRhineCertificate ||
                            certificate.type === CertificateType.Radarpatent
                        }
                    />
                </section>
            </div>
            <div className="form-row two">
                <section className="input-container">
                    <label>{intl.formatMessage({ id: "comments" })}</label>
                    <TextArea
                        value={certificate.comments ?? ""}
                        onChange={e => setCertificate({ ...certificate, comments: e.target.value })}
                    />
                </section>
                {certificate.type === CertificateType.UcqBoatmaster && (
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "restriction96" })}</label>
                        <Input
                            value={certificate.restriction96 ?? ""}
                            onChange={e =>
                                setCertificate({ ...certificate, restriction96: e.target.value })
                            }
                        />
                    </section>
                )}
            </div>
        </>
    );
};
