import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Select, DropdownItemProps } from "semantic-ui-react";
import { toast } from "../..";
import { agent } from "../../api/agent";
import { formatDate } from "../../models/common";
import { IIDDataSet } from "../../models/crewMember";

type Props = {
    open: boolean;
    closeModal: () => void;
    idDataSet: IIDDataSet;
};

export const ConnectWithLocalModal = ({ open, closeModal, idDataSet }: Props) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const [idDataSets, setIdDataSets] = useState<DropdownItemProps[]>([]);
    const [selected, setSelected] = useState<string>();
    const [loading, setLoading] = useState(false);

    const fetchCrewMembersWithoutCid = useCallback(async () => {
        const props: DropdownItemProps[] = [];
        const res = await agent.CrewMembers.getWithoutCid();
        res.forEach(x =>
            props.push({
                key: x.crewMemberGuid,
                text: x.firstName + " " + x.lastName + ` (${formatDate(x.dateOfBirth)})`,
                value: x.crewMemberGuid,
            })
        );
        setIdDataSets(props);
    }, []);

    const handleConnectWithLocalCrewMember = async () => {
        setLoading(true);
        try {
            idDataSet.crewMemberGuid = selected ?? "";
            let file: File | undefined = undefined;
            if (idDataSet.imageBase64) {
                const binaryString = window.atob(idDataSet.imageBase64);
                const bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) bytes[i] = binaryString.charCodeAt(i);
                file = new File([new Uint8Array(bytes.buffer)], "profilePicture01.jpg", {
                    type: "image/jpeg",
                });
            }
            const targetGuid = await agent.CrewMembers.connectWithLocal(idDataSet, file);
            toast(intl.formatMessage({ id: "connectWithLocalCrewMemberSuccess" }), true);
            navigate("/crew-members/" + targetGuid);
        } catch {
            toast(intl.formatMessage({ id: "connectWithLocalCrewMemberFail" }), false);
        } finally {
            setLoading(false);
            setSelected(undefined);
            closeModal();
        }
    };

    useEffect(() => {
        fetchCrewMembersWithoutCid();
    }, [fetchCrewMembersWithoutCid]);

    return (
        <Modal
            open={open}
            onClose={() => closeModal()}
            dimmer="blurring"
            size="tiny"
            closeOnDimmerClick={!loading}
        >
            <Modal.Header>{intl.formatMessage({ id: "connectWithLocalCrewMember" })}</Modal.Header>
            <Modal.Content>
                <section className="input-container">
                    <label>{intl.formatMessage({ id: "selectLocalCrewMember" })}</label>
                    <Select
                        selectOnBlur={false}
                        search
                        options={idDataSets}
                        value={selected}
                        onChange={(e, data: any) => setSelected(data.value)}
                    />
                </section>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => {
                        setSelected(undefined);
                        closeModal();
                    }}
                    content={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                />
                <Button
                    primary
                    onClick={() => handleConnectWithLocalCrewMember()}
                    content={intl.formatMessage({ id: "confirm" })}
                    loading={loading}
                    disabled={loading || !selected}
                />
            </Modal.Actions>
        </Modal>
    );
};
