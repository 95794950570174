import { DropdownItemProps } from "semantic-ui-react";

export interface ISRBFunction {
    guid: string;
    functionType: SrbFunctionType;
    validFrom: string;
    dateOfIssue: string;
    placeOfIssue: string;
    srbGuid: string;
}

export enum SrbFunctionType {
    NotSpecified,
    Helmsman,
    Boatman,
    AbleBoatman,
    Deckhand,
    Apprentice,
    Engineer,
}

export const srbFunctionInit: ISRBFunction = {
    guid: "",
    functionType: SrbFunctionType.NotSpecified,
    validFrom: "",
    dateOfIssue: "",
    placeOfIssue: "",
    srbGuid: "",
};

export const srbFunctionOptions: DropdownItemProps[] = [
    { key: SrbFunctionType.NotSpecified, text: "unspecified", value: SrbFunctionType.NotSpecified },
    { key: SrbFunctionType.Helmsman, text: "helmsman", value: SrbFunctionType.Helmsman },
    { key: SrbFunctionType.Boatman, text: "boatman", value: SrbFunctionType.Boatman },
    { key: SrbFunctionType.AbleBoatman, text: "ableBoatman", value: SrbFunctionType.AbleBoatman },
    { key: SrbFunctionType.Deckhand, text: "deckhand", value: SrbFunctionType.Deckhand },
    { key: SrbFunctionType.Apprentice, text: "apprentice", value: SrbFunctionType.Apprentice },
    { key: SrbFunctionType.Engineer, text: "engineer", value: SrbFunctionType.Engineer },
];
