import { useState } from "react";
import { Placeholder } from "semantic-ui-react";
import signature from "../../images/Signature_icon_edit.png";

type Props = {
    signatureBase64: string;
};

export const SignatureImage = ({ signatureBase64 }: Props) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            <Placeholder style={loaded ? { display: "none" } : {}}>
                <Placeholder.Image />
            </Placeholder>
            <img
                id="signature-image"
                src={`data:image/jpg;base64,${signatureBase64}`}
                alt="Signature"
                style={loaded ? {} : { display: "none" }}
                onLoad={() => setLoaded(true)}
                onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = signature;
                    e.target.className = "placeholder-signature";
                }}
            />
        </>
    );
};
