import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Table, Icon, Button } from "semantic-ui-react";
import { formatDate, MessageStatus } from "../../models/common";
import { getTranslatedCleanInboxMessage, IInboxMessage } from "../../models/inboxMessage";

type Props = {
    inboxMessage: IInboxMessage;
    page: number;
};

export const InboxTableRow = ({ inboxMessage, page }: Props) => {
    const intl = useIntl();

    return (
        <Table.Row key={inboxMessage.guid}>
            <Table.Cell
                className={inboxMessage.status === MessageStatus.Unprocessed ? "cell-bolded" : ""}
            >
                {formatDate(inboxMessage.created, true)}
            </Table.Cell>
            <Table.Cell
                className={inboxMessage.status === MessageStatus.Unprocessed ? "cell-bolded" : ""}
            >
                {getTranslatedCleanInboxMessage(inboxMessage, intl)}
            </Table.Cell>
            <Table.Cell
                className={inboxMessage.status === MessageStatus.Unprocessed ? "cell-bolded" : ""}
            >
                {inboxMessage.sender}
            </Table.Cell>
            <Table.Cell>
                <Icon
                    color={
                        inboxMessage.status === MessageStatus.Unprocessed
                            ? "blue"
                            : inboxMessage.status === MessageStatus.Success
                            ? "green"
                            : "red"
                    }
                    name={
                        inboxMessage.status === MessageStatus.Unprocessed
                            ? "question circle"
                            : inboxMessage.status === MessageStatus.Success
                            ? "check circle"
                            : "times circle"
                    }
                    size="big"
                />
            </Table.Cell>
            <Table.Cell singleLine>
                <Button
                    as={Link}
                    to={{ pathname: "/inbox/" + inboxMessage.guid, state: page }}
                    content={intl.formatMessage({ id: "viewDetails" })}
                    primary
                    basic
                    size="small"
                />
            </Table.Cell>
        </Table.Row>
    );
};
