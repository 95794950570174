import { IntlShape } from "react-intl";
import { AuthorizationType, authorizationTypeOptions } from "./authorization";

export interface IChangeLogItem {
    guid: string;
    timestamp: string;
    username: string;
    type: ChangeLogType;
    targetQualificationId: string;
    targetAuthorizationType: AuthorizationType;
    srbSerialNumber: string;
    crewMemberName: string;
    fallbackMessage: string;
    targetUserUsername: string;
    targetUserName: string;
}

export enum ChangeLogType {
    CrewMemberCreated,
    AddedNewDataSet,
    ModifiedDataSet,
    CrewMemberRemoved,
    CertificateCreated,
    CertificateStatusModified,
    CertificateIsRenewed,
    ServiceRecordBookIsAdded,
    AuthorizationIsAdded,
    CertificateSuspended,
    CertificateWithdrawn,
    CertificateWithdrawalRequestSent,
    CertificateSentForPrint,
    CrewMemberAddedToECDB,
    ServiceRecordBookModified,
    UserIsCreated,
    UserIsDeleted,
    UserIsEdited,
    WorkerExpirationMailSent,
    WorkerSetCertificateToExpired,
    WorkerSetCertificateBackToActive,
    PrintFeedbackSuccess,
    PrintFeedbackFail,
    CertificateEdited,
    ServiceRecordBookStatusModified,
    ServiceRecordBookSuspended,
    ServiceRecordBookWithdrawn,
    WorkerSetServiceRecordBookStatusBackToActive,
    AuthorizationSuspended,
    AuthorizationWithdrawn,
    AuthorizationStatusModified,
}

export const getTranslatedCleanChangeLogItem = (changeLog: IChangeLogItem, intl: IntlShape) => {
    const stringEnum = ChangeLogType[changeLog.type];
    const intlType = stringEnum.charAt(0).toLowerCase() + stringEnum.slice(1);
    if (
        changeLog.type === ChangeLogType.CrewMemberRemoved ||
        changeLog.type === ChangeLogType.UserIsDeleted
    ) {
        const split = changeLog.fallbackMessage.split(";");
        let message = intl
            .formatMessage({ id: intlType })
            .replace("USER_USERNAME", changeLog.username)
            .replace("TARGET_USER_NAME", split[0])
            .replace("CREW_MEMBER_NAME", split[0]);
        if (!!split[2] && changeLog.type === ChangeLogType.CrewMemberRemoved)
            message += ` (GUID: ${split[1]}; CID: ${split[2]})`;
        else if (!!split[2] && changeLog.type === ChangeLogType.UserIsDeleted) {
            message = message.replace("TARGET_USER_USERNAME", split[2]);
            message += ` (GUID: ${split[1]})`;
        } else message += ` (GUID: ${split[1]})`;
        return message;
    } else {
        return intl
            .formatMessage({ id: intlType })
            .replace("TARGET_CERTIFICATE_ID", changeLog.targetQualificationId)
            .replace(
                "AUTH_TYPE",
                intl.formatMessage({
                    id: authorizationTypeOptions.find(
                        x => x.value === changeLog.targetAuthorizationType
                    )?.text as string,
                })
            )
            .replace("USER_USERNAME", changeLog.username)
            .replace("CREW_MEMBER_NAME", changeLog.crewMemberName)
            .replace("TARGET_USER_NAME", changeLog.targetUserName)
            .replace("TARGET_USER_USERNAME", changeLog.targetUserUsername)
            .replace("SRB_SERIAL_NUMBER", changeLog.srbSerialNumber)
            .replace(
                "INBOX_NOTES",
                !!changeLog.fallbackMessage ? changeLog.fallbackMessage : "N/A"
            );
    }
};
