import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Divider, Icon, Input, Message, Modal, Radio, Select } from "semantic-ui-react";
import {
    compareDates,
    constructDate,
    dateInit,
    deconstructDate,
    Gender,
    getAge,
    getTodayDate,
} from "../../../models/common";
import {
    adnCourseOptions,
    AdnExamType,
    CertificateStatus,
    CertificateType,
    fitnessDataCertificates,
    getAvailableCertificateTypes,
    HighRhineLicenceType,
    ICertificate,
    newCertificateInit,
    restrictionStatusOptions,
    statePrintingCertificates,
} from "../../../models/certificate";
import { toast } from "../../..";
import { FitnessDataForm } from "./FitnessDataForm";
import { CertificateDetailsForm } from "./CertificateDetailsForm";
import { AuthorizationsForm } from "./AuthorizationsForm";
import { DeliveryInfoForm } from "./DeliveryInfoForm";
import { ICrewMember } from "../../../models/crewMember";
import { TranslateProps } from "../../../i18n/TranslateProps";
import { ViewCertificateModal } from "../ViewCertificateModal";
import { agent } from "../../../api/agent";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { LinkedCertificateInputs } from "./LinkedCertificateInputs";
import {
    getRhineRiverRangeProps,
    highRhineRiverRangeProps,
    rhineRiverRangeProps,
} from "../../../models/riverRanges";
import {
    AuthorizationType,
    IAuthorization,
    newAuthorizationInit,
} from "../../../models/authorization";

type Props = {
    open: boolean;
    close: () => void;
    fetchCrewMember: () => Promise<void>;
    dateOfBirth: string;
    certificates: ICertificate[];
    certificate?: ICertificate;
    crewMember: ICrewMember;
    edit: boolean;
};

export const CertificateFormModal = ({
    open,
    close,
    fetchCrewMember,
    dateOfBirth,
    certificates,
    certificate,
    crewMember,
    edit,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [newCertificate, setNewCertificate] = useState(
        certificate ? certificate : { ...newCertificateInit, issuingAuthority: user.authority }
    );
    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(
        certificate && certificate.endDate !== constructDate(dateInit)
            ? deconstructDate(certificate.endDate)
            : dateInit
    );
    const [fitnessStartDate, setFitnessStartDate] = useState(startDate);
    const [fitnessEndDate, setFitnessEndDate] = useState(
        certificate && certificate.fitnessValidUntil !== constructDate(dateInit)
            ? deconstructDate(certificate.fitnessValidUntil)
            : dateInit
    );
    const [loading, setLoading] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const [fitnessDatesError, setFitnessDatesError] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [showUnlimitedOption, setShowUnlimitedOption] = useState(false);
    const availableTypes = useMemo(
        () => getAvailableCertificateTypes(intl, certificates, crewMember.cid),
        [certificates, crewMember.cid, intl]
    );
    const currentStretchesRiverKilometers = useRef(
        Array.from(
            new Set(
                certificates
                    .filter(
                        c =>
                            c.type === CertificateType.StretchesWithSpecificRisks &&
                            c.qualificationID !== newCertificate.qualificationID
                    )
                    .concat(!!certificate && !edit ? certificate : [])
                    .flatMap(c => c.stretchesRiverKilometers)
            )
        )
    );
    const currentRhineRiverKilometers = useRef(edit ? [] : newCertificate.rhineRiverKilometers);
    const disabled = useMemo(
        () =>
            (newCertificate.type !== CertificateType.StretchesWithSpecificRisks &&
                newCertificate.type !== CertificateType.HighRhineCertificate &&
                newCertificate.type !== CertificateType.Radarpatent &&
                !newCertificate.isUnlimited &&
                Object.values(endDate).some(x => x === "" || x === -1)) ||
            newCertificate.type < CertificateType.UcqBoatmaster ||
            (statePrintingCertificates.includes(newCertificate.type) &&
                (!newCertificate.nameLineOne ||
                    !newCertificate.zipCode ||
                    !newCertificate.city ||
                    !newCertificate.street ||
                    newCertificate.country === -1)) ||
            ((newCertificate.type === CertificateType.StretchesWithSpecificRisks ||
                newCertificate.type === CertificateType.HighRhineCertificate) &&
                (!newCertificate.linkedCertificateIssueDate ||
                    newCertificate.linkedCertificateIssueDate.startsWith("1/") ||
                    newCertificate.linkedCertificateType <= 0 ||
                    newCertificate.linkedCertificateIssuingCountry < 0 ||
                    !newCertificate.linkedCertificateQualificationID)) ||
            (newCertificate.type === CertificateType.StretchesWithSpecificRisks &&
                newCertificate.stretchesRiverKilometers.length === 0) ||
            (newCertificate.type === CertificateType.AdnBescheinigung &&
                (newCertificate.adnExamType < 0 || newCertificate.adnCourses.length === 0)) ||
            (newCertificate.type === CertificateType.BoatmasterOnTheRhine &&
                (newCertificate.rhineLicenceType < 0 ||
                    newCertificate.rhineRiverKilometers.length === 0)) ||
            (newCertificate.type === CertificateType.BoatmasterOnTheHighRhine &&
                (newCertificate.highRhineLicenceType < 0 ||
                    newCertificate.highRhineRiverKilometers.length === 0)) ||
            (newCertificate.type === CertificateType.ExtensionBoatmasterOnTheRhine &&
                (newCertificate.extensionBoatmasterType < 0 ||
                    newCertificate.rhineRiverKilometers.length === 0)) ||
            datesError ||
            fitnessDatesError,
        [
            datesError,
            endDate,
            fitnessDatesError,
            newCertificate.adnCourses.length,
            newCertificate.adnExamType,
            newCertificate.city,
            newCertificate.country,
            newCertificate.extensionBoatmasterType,
            newCertificate.highRhineLicenceType,
            newCertificate.highRhineRiverKilometers.length,
            newCertificate.isUnlimited,
            newCertificate.linkedCertificateIssueDate,
            newCertificate.linkedCertificateIssuingCountry,
            newCertificate.linkedCertificateQualificationID,
            newCertificate.linkedCertificateType,
            newCertificate.nameLineOne,
            newCertificate.rhineLicenceType,
            newCertificate.rhineRiverKilometers.length,
            newCertificate.street,
            newCertificate.stretchesRiverKilometers.length,
            newCertificate.type,
            newCertificate.zipCode,
        ]
    );

    const constructDates = () => {
        newCertificate.startDate = constructDate(startDate);
        newCertificate.endDate = constructDate(endDate);
        newCertificate.fitnessIssueDate = constructDate(fitnessStartDate);
        newCertificate.fitnessValidUntil = constructDate(fitnessEndDate);
    };

    const handleSetPreviewModalOpen = async () => {
        constructDates();
        setPreviewModalOpen(true);
    };

    const handleCloseModal = () => {
        setNewCertificate(certificate ? certificate : newCertificateInit);
        close();
        setPreviewModalOpen(false);
    };

    const handleAddCertificate = async () => {
        setLoading(true);
        try {
            constructDates();
            if (newCertificate.type !== CertificateType.UcqBoatmaster)
                newCertificate.restriction96 = "";
            if (
                newCertificate.type === CertificateType.UcqLngExpert ||
                newCertificate.type === CertificateType.UcqPassengerNavigationExpert
            ) {
                newCertificate.limitedAreaDescription = "";
                newCertificate.limitedTaskDescription = "";
                newCertificate.limitedToOneCraftDescription = "";
                newCertificate.restrictions = [];
            }
            if (newCertificate.type !== CertificateType.UcqBoatmaster)
                newCertificate.gender = Gender.Unspecified;
            newCertificate.crewMemberGuid = crewMember.guid;
            newCertificate.issuingAuthority = user.authority;
            await agent.Certificates.create(newCertificate);
            toast(intl.formatMessage({ id: "createdCertificate" }), true);
            await fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToCreateCertificate" }), false);
        } finally {
            setLoading(false);
            close();
            setPreviewModalOpen(false);
        }
    };

    const handleEditCertificate = async () => {
        setLoading(true);
        try {
            constructDates();
            newCertificate.crewMemberGuid = crewMember.guid;
            newCertificate.issuingAuthority = user.authority;
            await agent.Certificates.edit(newCertificate);
            toast(intl.formatMessage({ id: "certificateEditedMessage" }), true);
            await fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToEditCertificate" }), false);
        } finally {
            setLoading(false);
            close();
            setPreviewModalOpen(false);
        }
    };

    const handleRenewCertificate = async () => {
        setLoading(true);
        try {
            // merge S-type authorizations if any exist
            if (newCertificate.authorizations.some(a => a.type === AuthorizationType.S)) {
                // get all river kilometers from S-type authorizations
                const strecthes = newCertificate.authorizations
                    .filter(a => a.type === AuthorizationType.S)
                    .filter((value, index, array) => array.indexOf(value) === index) // remove duplicates
                    ?.map(x => x.ucqRiverKilometers)
                    .flat();
                // get all manual river kilometers from S-type authorizations
                const manualStretches = newCertificate.authorizations
                    .filter(a => a.type === AuthorizationType.S)
                    .filter((value, index, array) => array.indexOf(value) === index) // remove duplicates
                    ?.map(x => x.ucqManualRiverKilometers.split(";"))
                    .flat();

                // remove S-type authorizations
                newCertificate.authorizations = newCertificate.authorizations.filter(
                    a => a.type !== AuthorizationType.S
                );

                // add new S-type authorization
                const newAuthorization: IAuthorization = {
                    ...newAuthorizationInit,
                    type: AuthorizationType.S,
                    ucqRiverKilometers: strecthes,
                    ucqManualRiverKilometers: manualStretches.join(";"),
                    startDate: newCertificate.startDate,
                    endDate: newCertificate.endDate,
                    issuingAuthority: user.authority,
                    status: newCertificate.status,
                    linkedQualificationID: newCertificate.qualificationID,
                    crewMemberGuid: crewMember.guid,
                };
                newCertificate.authorizations.push(newAuthorization);
            }

            constructDates();
            newCertificate.crewMemberGuid = crewMember.guid;
            newCertificate.issuingAuthority = user.authority;
            newCertificate.lastQualificationID = certificate?.qualificationID ?? "";
            await agent.Certificates.renew(newCertificate);
            toast(intl.formatMessage({ id: "certificateRenewed" }), true);
            await fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToRenewCertificate" }), false);
        } finally {
            setLoading(false);
            close();
            setPreviewModalOpen(false);
        }
    };

    useEffect(() => {
        if (certificate)
            setNewCertificate(c => ({
                ...c,
                lastQualificationID: certificate.qualificationID,
                issuingAuthority: user.authority,
            }));
        else if (certificates.length > 0 && newCertificate.type >= CertificateType.UcqBoatmaster) {
            const filtered = certificates
                .filter(c => c.type === newCertificate.type)
                .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
            if (filtered.length > 0)
                setNewCertificate(c => ({
                    ...c,
                    lastQualificationID: filtered[0].qualificationID,
                }));
            else setNewCertificate(c => ({ ...c, lastQualificationID: "" }));
        } else setNewCertificate(c => ({ ...c, lastQualificationID: "" }));
    }, [certificate, certificates, newCertificate.type, user.authority]);

    useEffect(() => {
        if (endDate.year > 1) {
            const comp1 = compareDates(startDate, endDate);
            const comp2 = compareDates(fitnessStartDate, fitnessEndDate);
            if ((comp1 !== null && !comp1) || (comp2 !== null && !comp2)) setDatesError(true);
            else setDatesError(false);
            if (
                !edit &&
                (certificate?.status === CertificateStatus.Destroyed ||
                    certificate?.status === CertificateStatus.Lost ||
                    certificate?.status === CertificateStatus.Stolen)
            ) {
                setFitnessDatesError(false);
            } else {
                const comp3 = compareDates(endDate, fitnessEndDate, true);
                if (comp3 !== null && !comp3) setFitnessDatesError(true);
                else setFitnessDatesError(false);
            }
        }
    }, [startDate, endDate, fitnessStartDate, fitnessEndDate, certificate, edit]);

    useEffect(() => {
        if (certificate && certificate.type === CertificateType.AdnBescheinigung && !edit) {
            const adnCertificates = certificates.filter(
                c => c.type === CertificateType.AdnBescheinigung
            );
            if (
                certificate.status === CertificateStatus.Active ||
                certificate.status === CertificateStatus.Expired ||
                certificate.status === CertificateStatus.Suspended ||
                certificate.status === CertificateStatus.Withdrawn
            ) {
                setNewCertificate(c => ({ ...c, adnExamType: AdnExamType.RepetitionCourse }));

                if (adnCertificates.length > 0) {
                    const lastAdnCertificate = adnCertificates.sort(
                        (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
                    )[0];
                    const lastAdnEndDate = deconstructDate(lastAdnCertificate.endDate);
                    setEndDate({ ...lastAdnEndDate, year: lastAdnEndDate.year + 5 });
                    setFitnessEndDate({ ...lastAdnEndDate, year: lastAdnEndDate.year + 5 });
                }
            } else {
                const lastAdnCertificate = adnCertificates.sort(
                    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
                )[0];
                setEndDate(deconstructDate(lastAdnCertificate.endDate));
            }
        }
    }, [certificate, certificates, edit]);

    useEffect(() => {
        const age = getAge(new Date(dateOfBirth));
        if (
            age >= 65 &&
            (newCertificate.type === CertificateType.BoatmasterOnTheRhine ||
                newCertificate.type === CertificateType.BoatmasterOnTheHighRhine)
        ) {
            setShowUnlimitedOption(true);
        } else setShowUnlimitedOption(false);
    }, [dateOfBirth, newCertificate.type]);

    useEffect(() => {
        if (newCertificate.isUnlimited) {
            setNewCertificate(prev => ({
                ...prev,
                restrictions: !prev.restrictions.includes(10)
                    ? prev.restrictions.concat(10).sort()
                    : prev.restrictions,
            }));
        }
    }, [newCertificate.isUnlimited, newCertificate.restrictions]);

    return (
        <>
            <Modal
                open={open}
                className="add-certificate-modal"
                onClose={handleCloseModal}
                dimmer="inverted"
                closeOnDimmerClick={!loading}
                closeOnEscape={!loading}
            >
                <Modal.Header>
                    <span>{intl.formatMessage({ id: "addCertificate" })}</span>
                    <Icon name="close" link size="large" onClick={handleCloseModal} />
                </Modal.Header>
                <Modal.Content>
                    <CertificateDetailsForm
                        certificate={newCertificate}
                        setCertificate={setNewCertificate}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        dateOfBirth={dateOfBirth}
                        fitnessEndDate={fitnessEndDate}
                        setFitnessStartDate={setFitnessStartDate}
                        setFitnessEndDate={setFitnessEndDate}
                        renew={certificate !== undefined}
                        availableCertificatesTypes={availableTypes}
                        currentStretchesRiverKilometers={currentStretchesRiverKilometers.current}
                        activeRhineLicences={certificates
                            .filter(
                                c =>
                                    c.type === CertificateType.BoatmasterOnTheRhine &&
                                    (c.status === CertificateStatus.Active ||
                                        c.status === CertificateStatus.Suspended)
                            )
                            .map(c => c.rhineLicenceType)}
                        activeHighRhineLicences={certificates
                            .filter(
                                c =>
                                    c.type === CertificateType.BoatmasterOnTheHighRhine &&
                                    (c.status === CertificateStatus.Active ||
                                        c.status === CertificateStatus.Suspended)
                            )
                            .map(c => c.highRhineLicenceType)}
                        activeBoatmasterLicences={certificates
                            .filter(
                                c =>
                                    c.type === CertificateType.UcqBoatmaster &&
                                    (c.status === CertificateStatus.Active ||
                                        c.status === CertificateStatus.Suspended)
                            )
                            .map(c => c.boatmasterLicenceType)}
                        activeExtensionLicences={certificates
                            .filter(
                                c =>
                                    c.type === CertificateType.ExtensionBoatmasterOnTheRhine &&
                                    (c.status === CertificateStatus.Active ||
                                        c.status === CertificateStatus.Suspended)
                            )
                            .map(c => c.extensionBoatmasterType)}
                        edit={edit}
                    />
                    {newCertificate &&
                        statePrintingCertificates &&
                        statePrintingCertificates.includes(newCertificate.type) && (
                            <>
                                <Divider horizontal>
                                    {intl.formatMessage({ id: "deliveryInfo" })}
                                </Divider>
                                <DeliveryInfoForm
                                    certificate={newCertificate}
                                    setCertificate={setNewCertificate}
                                    idDataSet={crewMember.idDataSets[0]}
                                />
                            </>
                        )}
                    {fitnessDataCertificates.includes(newCertificate.type) && (
                        <>
                            <Divider horizontal>
                                {intl.formatMessage({ id: "fitnessData" })}
                            </Divider>
                            <FitnessDataForm
                                certificate={newCertificate}
                                setCertificate={setNewCertificate}
                                fitnessStartDate={fitnessStartDate}
                                setFitnessStartDate={setFitnessStartDate}
                                fitnessEndDate={fitnessEndDate}
                                setFitnessEndDate={setFitnessEndDate}
                            />
                        </>
                    )}
                    {newCertificate.type === CertificateType.UcqBoatmaster && (
                        <>
                            <Divider horizontal>
                                {intl.formatMessage({ id: "specificAuthorizations" })}
                            </Divider>
                            <AuthorizationsForm
                                certificate={newCertificate}
                                setCertificate={setNewCertificate}
                            />
                        </>
                    )}
                    {(newCertificate.type === CertificateType.StretchesWithSpecificRisks ||
                        newCertificate.type === CertificateType.HighRhineCertificate) && (
                        <LinkedCertificateInputs
                            certificate={newCertificate}
                            setCertificate={setNewCertificate}
                            linkableCertificates={certificates.filter(
                                c =>
                                    (c.status === CertificateStatus.Active ||
                                        c.status === CertificateStatus.Suspended) &&
                                    (c.type === CertificateType.UcqBoatmaster ||
                                        c.type === CertificateType.BoatmasterOnTheRhine)
                            )}
                        />
                    )}
                    {newCertificate.type === CertificateType.AdnBescheinigung && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "adnCourses" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                multiple
                                options={adnCourseOptions}
                                value={newCertificate.adnCourses}
                                onChange={(e, data: any) =>
                                    setNewCertificate({ ...newCertificate, adnCourses: data.value })
                                }
                            />
                        </section>
                    )}
                    {(newCertificate.type === CertificateType.BoatmasterOnTheRhine ||
                        newCertificate.type === CertificateType.BoatmasterOnTheHighRhine) && (
                        <>
                            <div className="form-row">
                                <section className="input-container">
                                    <label>
                                        {intl.formatMessage({ id: "sectionsOfTheRhine" })}
                                        <span className="input-required">*</span>
                                    </label>
                                    {newCertificate.type ===
                                    CertificateType.BoatmasterOnTheRhine ? (
                                        <Select
                                            search
                                            multiple
                                            options={getRhineRiverRangeProps(
                                                currentRhineRiverKilometers.current,
                                                rhineRiverRangeProps
                                            )}
                                            value={newCertificate.rhineRiverKilometers}
                                            onChange={(_e, data: any) => {
                                                setNewCertificate({
                                                    ...newCertificate,
                                                    rhineRiverKilometers: data.value,
                                                });
                                            }}
                                            closeOnChange
                                        />
                                    ) : (
                                        <Select
                                            search
                                            multiple
                                            options={
                                                newCertificate.highRhineLicenceType ===
                                                    HighRhineLicenceType.GovernmentLicence ||
                                                newCertificate.highRhineLicenceType ===
                                                    HighRhineLicenceType.SportsHighRhineLicence
                                                    ? highRhineRiverRangeProps.filter(
                                                          x => (x.value as number) === 1
                                                      )
                                                    : highRhineRiverRangeProps.filter(
                                                          x => (x.value as number) < 2
                                                      )
                                            }
                                            value={newCertificate.highRhineRiverKilometers}
                                            onChange={(e, data: any) => {
                                                setNewCertificate({
                                                    ...newCertificate,
                                                    highRhineRiverKilometers: data.value,
                                                });
                                            }}
                                            closeOnChange
                                        />
                                    )}
                                </section>
                            </div>
                            <div className="form-row">
                                <section className="input-container">
                                    <label>{intl.formatMessage({ id: "restrictions" })}</label>
                                    <Select
                                        search
                                        multiple
                                        closeOnChange
                                        options={TranslateProps(intl, restrictionStatusOptions)}
                                        value={newCertificate.restrictions.sort()}
                                        onChange={(e, data: any) =>
                                            setNewCertificate({
                                                ...newCertificate,
                                                restrictions: data.value.sort(),
                                            })
                                        }
                                    />
                                </section>
                            </div>
                            <div className="form-row three">
                                {newCertificate.restrictions.includes(7) && (
                                    <section className="input-container">
                                        <label>{intl.formatMessage({ id: "craftDesc" })}</label>
                                        <Input
                                            value={newCertificate.limitedToOneCraftDescription}
                                            onChange={e =>
                                                setNewCertificate({
                                                    ...newCertificate,
                                                    limitedToOneCraftDescription: e.target.value,
                                                })
                                            }
                                        />
                                    </section>
                                )}
                                {newCertificate.restrictions.includes(8) && (
                                    <section className="input-container">
                                        <label>{intl.formatMessage({ id: "areaDesc" })}</label>
                                        <Input
                                            value={newCertificate.limitedAreaDescription}
                                            onChange={e =>
                                                setNewCertificate({
                                                    ...newCertificate,
                                                    limitedAreaDescription: e.target.value,
                                                })
                                            }
                                        />
                                    </section>
                                )}
                                {newCertificate.restrictions.includes(9) && (
                                    <section className="input-container">
                                        <label>{intl.formatMessage({ id: "taskDesc" })}</label>
                                        <Input
                                            value={newCertificate.limitedTaskDescription}
                                            onChange={e =>
                                                setNewCertificate({
                                                    ...newCertificate,
                                                    limitedTaskDescription: e.target.value,
                                                })
                                            }
                                        />
                                    </section>
                                )}
                            </div>
                        </>
                    )}
                    {newCertificate.type === CertificateType.ExtensionBoatmasterOnTheRhine && (
                        <section className="input-container">
                            <label>
                                {intl.formatMessage({ id: "sectionsOfTheRhine" })}
                                <span className="input-required">*</span>
                            </label>
                            <Select
                                search
                                multiple
                                options={rhineRiverRangeProps.filter(x => (x.value as number) <= 8)}
                                value={newCertificate.rhineRiverKilometers}
                                onChange={(_e, data: any) =>
                                    setNewCertificate({
                                        ...newCertificate,
                                        rhineRiverKilometers: data.value,
                                    })
                                }
                                closeOnChange
                            />
                        </section>
                    )}
                    {datesError && (
                        <Message
                            className="error-message"
                            icon="exclamation circle"
                            content={intl.formatMessage({ id: "datesCompareError" })}
                        />
                    )}
                    {fitnessDatesError && (
                        <Message
                            className="error-message"
                            icon="exclamation circle"
                            content={intl.formatMessage({ id: "fitnessDatesCompareError" })}
                        />
                    )}
                    {showUnlimitedOption && (
                        <div className="form-row two">
                            <Message
                                icon="info circle"
                                content={intl.formatMessage({ id: "unlimitedCertificeateOption" })}
                            />
                            <section className="input-container">
                                <label>{intl.formatMessage({ id: "noEndDate" })}</label>
                                <Radio
                                    toggle
                                    checked={newCertificate.isUnlimited}
                                    onChange={() =>
                                        setNewCertificate({
                                            ...newCertificate,
                                            isUnlimited: !newCertificate.isUnlimited,
                                        })
                                    }
                                />
                            </section>
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        onClick={handleSetPreviewModalOpen}
                        content={intl.formatMessage({ id: "preview" })}
                        fluid
                        loading={loading}
                        disabled={disabled || loading}
                    />
                </Modal.Actions>
            </Modal>
            {previewModalOpen && (
                <ViewCertificateModal
                    open={previewModalOpen}
                    close={() => setPreviewModalOpen(false)}
                    certificate={newCertificate}
                    idDataSet={crewMember.idDataSets[0]}
                    stretchesCertificates={certificates
                        .filter(
                            c =>
                                c.type === CertificateType.StretchesWithSpecificRisks &&
                                c.qualificationID !== newCertificate.qualificationID
                        )
                        .concat(!edit && !!certificate ? certificate : [])
                        .concat(newCertificate)}
                    radarCertificate={certificates.find(
                        c => c.type === CertificateType.Radarpatent
                    )}
                    renew={certificate !== undefined}
                    actions
                    handleAddCertificate={handleAddCertificate}
                    handleRenewCertificate={handleRenewCertificate}
                    handleEditCertificate={handleEditCertificate}
                    loading={loading}
                    edit={edit}
                    imageBase64={crewMember.idDataSets[0].imageBase64}
                    qrBase64={crewMember.qrBase64 ?? ""}
                />
            )}
        </>
    );
};
