import { useState } from "react";
import Calendar from "react-calendar";
import { CSSTransition } from "react-transition-group";
import { Input, Icon } from "semantic-ui-react";
import { formatDateRangeDisplay } from "./dateHelpers";
import "./CalendarInput.css";
import "react-calendar/dist/Calendar.css";

type Props = {
    dates: Date[];
    handleDateChange: (e: any, manualInput: boolean) => void;
    disabled?: boolean;
};

export const CalendarInputDouble = ({ dates, handleDateChange, disabled }: Props) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const displayValue = formatDateRangeDisplay(dates);

    return (
        <Input
            disabled={disabled}
            tabIndex={0}
            className="calendar-input"
            onBlur={(e: any) => {
                if (e.relatedTarget === null) setShowCalendar(false);
            }}
        >
            <input
                onChange={(e: any) => {
                    handleDateChange(e.target.value, true);
                    setShowCalendar(false);
                }}
                value={displayValue}
                className={`date-input ${showCalendar ? "calendar-open" : ""}`}
                onClick={() => setShowCalendar(!showCalendar)}
            />
            <CSSTransition
                in={showCalendar}
                timeout={300}
                classNames="show-calendar"
                unmountOnExit
                onEnter={() => setShowCalendar(true)}
                onExited={() => setShowCalendar(false)}
            >
                <Calendar
                    className="portal-calendar double-calendar"
                    onChange={(e: any) => {
                        handleDateChange(e, false);
                        setShowCalendar(false);
                    }}
                    nextLabel={<Icon name="angle right" />}
                    prevLabel={<Icon name="angle left" />}
                    next2Label={<Icon name="angle double right" />}
                    prev2Label={<Icon name="angle double left" />}
                    returnValue="range"
                    selectRange={true}
                />
            </CSSTransition>
        </Input>
    );
};
