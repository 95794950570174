import { ChangeEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
    Button,
    DropdownProps,
    Input,
    InputOnChangeData,
    Modal,
    Select,
    TextArea,
} from "semantic-ui-react";
import { toast } from "../..";
import { DateInput } from "../../components/DateInput/DateInput";
import { constructDate, dateInit, deconstructDate, getTodayDate } from "../../models/common";
import { ISRB, newSrbInit, srbActiveOptions } from "../../models/srb";
import { agent } from "../../api/agent";
import { ICrewMember } from "../../models/crewMember";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { TranslateProps } from "../../i18n/TranslateProps";

type Props = {
    open: boolean;
    close: () => void;
    fetchCrewMember: () => Promise<void>;
    crewMember: ICrewMember;
    srb?: ISRB;
    currentActiveSrb?: ISRB;
};

export const EUSRBFormModal = ({
    open,
    close,
    fetchCrewMember,
    crewMember,
    srb,
    currentActiveSrb,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [newSrb, setNewSrb] = useState(
        srb ? { ...srb, serialNumber: srb.serialNumber.slice(-4) } : newSrbInit
    );
    const [loading, setLoading] = useState(false);
    const [paperIssueDate, setPaperIssueDate] = useState(
        !srb || srb.paperSrbLastIssueDate.includes("0001-")
            ? dateInit
            : deconstructDate(srb.paperSrbLastIssueDate)
    );
    const [issueDate, setIssueDate] = useState(
        !srb || srb.issueDate.includes("0001-") ? dateInit : deconstructDate(srb.issueDate)
    );
    const [invalidSerialNumber, setInvalidSerialNumber] = useState(false);

    const handleAddSRB = async () => {
        setLoading(true);
        try {
            newSrb.serialNumber = `${crewMember.cid}SRBRH${user.authority}${newSrb.serialNumber}`;
            newSrb.paperSrbLastIssueDate = constructDate(paperIssueDate);
            newSrb.issueDate = constructDate(issueDate);
            newSrb.crewMemberCid = crewMember.cid;
            newSrb.crewMemberGuid = crewMember.guid;
            if (currentActiveSrb)
                newSrb.currentActiveSrbSerialNumber = currentActiveSrb.serialNumber;
            await agent.ServiceRecordBooks.create(newSrb);
            toast(intl.formatMessage({ id: "addedNewSRB" }), true);
            fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToCreateSRB" }), false);
        } finally {
            close();
            setLoading(false);
        }
    };

    const handleEditSRB = async () => {
        setLoading(true);
        try {
            newSrb.serialNumber = `${crewMember.cid}SRBRH${user.authority}${newSrb.serialNumber}`;
            newSrb.paperSrbLastIssueDate = constructDate(paperIssueDate);
            newSrb.issueDate = constructDate(issueDate);
            newSrb.crewMemberCid = crewMember.cid;
            await agent.ServiceRecordBooks.edit(newSrb);
            toast(intl.formatMessage({ id: "editedSRB" }), true);
            fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToEditSRB" }), false);
        } finally {
            close();
            setLoading(false);
        }
    };

    useEffect(() => {
        setNewSrb(s => ({ ...s, issuingAuthority: user.authority }));
    }, [user.authority]);

    const validateSerialNumber = (value: string) => {
        // value must be all digits, exactly 4
        if (!/^\d+$/.test(value)) {
            return;
        }
        setNewSrb({ ...newSrb, serialNumber: value });
        if (value.length === 4) {
            setInvalidSerialNumber(false);
        } else {
            setInvalidSerialNumber(true);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => close()}
            dimmer="blurring"
            size="small"
            closeOnDimmerClick={!loading}
        >
            <Modal.Header>
                {srb ? intl.formatMessage({ id: "editSrb" }) : intl.formatMessage({ id: "addSrb" })}
            </Modal.Header>
            <Modal.Content>
                {srb && (
                    <section id="topAddCertificate" className="input-container">
                        <label>{intl.formatMessage({ id: "status" })}</label>
                        <Select
                            selectOnBlur={false}
                            options={TranslateProps(intl, srbActiveOptions)}
                            value={newSrb.active}
                            onChange={(
                                _e: React.SyntheticEvent<HTMLElement>,
                                data: DropdownProps
                            ) => {
                                setNewSrb({ ...newSrb, active: data.value as boolean });
                            }}
                        />
                    </section>
                )}
                <form className="add-certificate-form">
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "serialNumber4digit" })}</label>
                        <Input
                            value={newSrb.serialNumber ?? ""}
                            onChange={(
                                _e: ChangeEvent<HTMLInputElement>,
                                data: InputOnChangeData
                            ) => {
                                validateSerialNumber(data.value);
                            }}
                        />
                    </section>
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "issueDate" })}</label>
                        <DateInput
                            date={issueDate !== dateInit ? issueDate : getTodayDate()}
                            setDate={setIssueDate}
                            extendedYears
                        />
                    </section>
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "paperSrbLastIssueAuthority" })}</label>
                        <Input
                            value={newSrb.paperSrbLastIssueAuthority ?? ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setNewSrb({ ...newSrb, paperSrbLastIssueAuthority: e.target.value })
                            }
                        />
                    </section>
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "paperSrbLastIssueDate" })}</label>
                        <DateInput
                            date={paperIssueDate}
                            setDate={setPaperIssueDate}
                            extendedYears
                        />
                    </section>

                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "paperSrbLastNB" })}</label>
                        <Input
                            value={newSrb.paperSrbLastNB ?? ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setNewSrb({ ...newSrb, paperSrbLastNB: e.target.value })
                            }
                        />
                    </section>
                    <section className="input-container">
                        <label>{intl.formatMessage({ id: "paperSrbNavigationDays" })}</label>
                        <Input
                            value={newSrb.paperSrbNavigationDays ?? ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setNewSrb({ ...newSrb, paperSrbNavigationDays: e.target.value })
                            }
                        />
                    </section>
                </form>
                <section id="bottomAddCertificate" className="input-container">
                    <label>{intl.formatMessage({ id: "comments" })}</label>
                    <TextArea
                        value={newSrb.comments ?? ""}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setNewSrb({ ...newSrb, comments: e.target.value })
                        }
                        rows={3}
                    />
                </section>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    fluid
                    primary
                    onClick={() => (srb ? handleEditSRB() : handleAddSRB())}
                    content={
                        srb
                            ? intl.formatMessage({ id: "saveChanges" })
                            : intl.formatMessage({ id: "addSrb" })
                    }
                    loading={loading}
                    disabled={loading || invalidSerialNumber || !newSrb.serialNumber}
                />
            </Modal.Actions>
        </Modal>
    );
};
