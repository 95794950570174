import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Segment, Table, Message, Loader, Icon } from "semantic-ui-react";
import { IEcdbIdentityResponse, IIDDataSet } from "../../models/crewMember";
import { AppState } from "../../store/configureStore";
import { SearchPageResultRow } from "./SearchPageResultRow";

type Props = {
    idDataSets: IIDDataSet[];
    ecdbFetching: boolean;
    ecdbResponse: IEcdbIdentityResponse;
    detailRequestId: string;
};

export const SearchPageResults = ({
    idDataSets,
    ecdbFetching,
    ecdbResponse,
    detailRequestId,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();

    return (
        <Segment>
            {idDataSets.length === 0 ? (
                <Message icon="info circle" header={intl.formatMessage({ id: "noResultsFound" })} />
            ) : (
                <Table basic="very" compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1} singleLine>
                                {intl.formatMessage({ id: "issuingAuthority" })}
                            </Table.HeaderCell>
                            {user.ecdbConnection && (
                                <Table.HeaderCell width={1}>
                                    {intl.formatMessage({ id: "cid" })}
                                </Table.HeaderCell>
                            )}
                            <Table.HeaderCell width={1}>CH ID</Table.HeaderCell>
                            <Table.HeaderCell>
                                {intl.formatMessage({ id: "name" })}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {intl.formatMessage({ id: "dateOfBirth" })}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {intl.formatMessage({ id: "placeOfBirth" })}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1} singleLine>
                                {intl.formatMessage({ id: "viewDetails" })}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {idDataSets.map(x => (
                            <SearchPageResultRow
                                key={x.guid}
                                idDataSet={x}
                                detailRequestId={detailRequestId}
                            />
                        ))}
                    </Table.Body>
                </Table>
            )}
            {user.ecdbConnection && ecdbFetching && (
                <section className="search-page-section">
                    <strong id="ecdb-fetching-message">
                        <Loader active inline size="small" />
                        {intl.formatMessage({ id: "fetchingFromEcdb" })}
                    </strong>
                </section>
            )}
            {user.ecdbConnection && ecdbResponse.offlineNcdbs.length > 0 && (
                <section className="search-page-section">
                    <strong id="ecdb-fetching-message">
                        <Icon name="wait" size="large" />
                        {intl.formatMessage({ id: "offlineNcdbs" })}
                        {ecdbResponse.offlineNcdbs.join(", ")}
                    </strong>
                </section>
            )}
            {user.ecdbConnection && !ecdbFetching && ecdbResponse.ecdbOffline && (
                <section className="search-page-section">
                    <strong id="ecdb-fetching-message">
                        <Icon name="warning circle" size="large" />
                        {intl.formatMessage({ id: "ecdbOffline" })}
                    </strong>
                </section>
            )}
        </Segment>
    );
};
