import { FormattedMessage, IntlProvider } from "react-intl";
import { ICertificate, linkedCertificateOptions } from "../../../models/certificate";
import { formatDateForCertificateView, Gender } from "../../../models/common";
import { IIDDataSet } from "../../../models/crewMember";
import { separateRiverRange, stretchesRiverRangeProps } from "../../../models/riverRanges";
import { CrewMemberImage } from "../../CrewMember/CrewMemberImage";
import { SignatureImage } from "../../CrewMember/SignatureImage";
import deMessages from "../../../i18n/locales/de.json";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/configureStore";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    stretchesCertificates: ICertificate[];
    renew?: boolean;
};

export const StretchesCertificateView = ({
    certificate,
    idDataSet,
    stretchesCertificates,
    renew,
}: Props) => {
    const nationalities = useSelector((state: AppState) => state.nationalities.nationalities);
    const linkedCertificateCountry = nationalities.find(
        c => c.value === certificate.linkedCertificateIssuingCountry
    );
    const linkedCertificateCountryValue =
        linkedCertificateCountry && linkedCertificateCountry.value !== -1
            ? (linkedCertificateCountry.text as string)
            : "";
    const linkedCertificateType = linkedCertificateOptions.find(
        c => c.value === certificate.linkedCertificateType
    );
    const stretches = [...certificate.stretchesRiverKilometers];
    // to always have 4 rows, regardless of stretches length
    for (let i = stretches.length; i < 4; i++) stretches.push(-1);

    const writeRiverKilometersRow = (stretch: number, i: number) => {
        const range = separateRiverRange(stretch, stretchesRiverRangeProps);
        return (
            <>
                <span>{i + 1}.&nbsp;von km/du km/van km</span>
                <strong>{range.from !== 0 ? range.from.toFixed(2) : ""}</strong>
                <span>bis km/au km/tot km</span>
                <strong>{range.from !== 0 ? range.to.toFixed(2) : ""}</strong>
            </>
        );
    };

    return (
        <div id="ch-certificate-view">
            <h5>Streckenzeugnis</h5>
            <div id="upper-part">
                <section className="left-section">
                    <p id="swiss-title">
                        <strong>Schweiz</strong>
                        <sup>1</sup>
                    </p>
                    <p>
                        <strong>Streckenzeugnis</strong>
                    </p>
                    <p>
                        <strong>Attestation de connaissances de secteurs</strong>
                    </p>
                    <p>
                        <strong>Bewijs voor riviergedeelten</strong>
                    </p>
                    <p id="doc-number">
                        <strong>Nr./n°</strong>
                        <span>
                            <strong>{renew ? "" : certificate.qualificationID}</strong>&nbsp;
                            <sup>2</sup>
                        </span>
                    </p>
                </section>
                <section className="right-section">
                    <p>
                        Dieses Streckenzeugnis gilt nur in Verbindung mit dem auf den gleichen Namen
                        lautenden Schifferpatent.
                    </p>
                    <p>
                        Cette attestation de connaissances de secteur n'est valable que lorsqu'elle
                        est présentée avec le certificat de conduite délivrée à la même personne.
                    </p>
                    <p>
                        Dit bewijs voor riviergedeelten is alleen geldig samen met het op dezelfde
                        naam afgegeven schipperspatent.
                    </p>
                    <div id="type-box" className="details">
                        <table style={{ padding: "0px", borderSpacing: "0em" }}>
                            <tr>
                                <td style={{ whiteSpace: "nowrap", verticalAlign: "text-bottom" }}>
                                    <span>
                                        Vom/ Du type/van:&nbsp;<sup>3</sup>
                                    </span>
                                </td>
                                <td style={{ verticalAlign: "text-bottom", lineHeight: "1" }}>
                                    <strong>
                                        {linkedCertificateType && (
                                            <IntlProvider locale="de" messages={deMessages}>
                                                <FormattedMessage
                                                    id={linkedCertificateType.text as string}
                                                />
                                            </IntlProvider>
                                        )}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <strong>
                                        ausgestellt am{" "}
                                        {formatDateForCertificateView(
                                            certificate.linkedCertificateIssueDate
                                        )}{" "}
                                        von{" "}
                                        {!!linkedCertificateCountryValue && (
                                            <IntlProvider locale="de" messages={deMessages}>
                                                <FormattedMessage
                                                    id={linkedCertificateCountryValue}
                                                />
                                            </IntlProvider>
                                        )}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Nr./n°</span>
                                </td>
                                <td>
                                    <strong style={{ verticalAlign: "text-top" }}>
                                        {certificate.linkedCertificateQualificationID}
                                    </strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                </section>
            </div>
            <div id="middle-part">
                <section className="left-section">
                    {idDataSet.gender !== Gender.Female && <p>Herr/Monsieur/De heer</p>}
                    {idDataSet.gender !== Gender.Male && <p>Frau/Madame/Mevrouw</p>}
                    <p>
                        <strong>
                            {!!idDataSet.nonLatinFirstName
                                ? idDataSet.nonLatinFirstName
                                : idDataSet.firstName}
                            &nbsp;
                            {!!idDataSet.nonLatinLastName
                                ? idDataSet.nonLatinLastName
                                : idDataSet.lastName}
                        </strong>
                        <label>(Vor- und Familienname / prénom et nom / voornaam en naam)</label>
                    </p>
                    <p>
                        geboren am / né(e) le / geboren op
                        <strong className="padded-input">
                            {formatDateForCertificateView(idDataSet.dateOfBirth)}
                        </strong>
                    </p>
                    <p>
                        in / à / te
                        <strong className="padded-input">
                            {!!idDataSet.nonLatinPlaceOfBirth
                                ? idDataSet.nonLatinPlaceOfBirth
                                : idDataSet.placeOfBirth}
                        </strong>
                    </p>
                    <p>
                        erhält die Erlaubnis zur Fahrt auf der Strecke von Iffezheim bis Spyksche
                        Fähre (§ 7.05 der Verordnung über das Schiffspersonal auf dem Rhein).
                    </p>
                    <p>
                        est autorisé(e) à conduire un bateau sur la section du Rhin comprise entre
                        Iffezheim et le back de Spijk (art. 7.05 du Règlement relatif au personnel
                        de la navigation sur le Rhin).
                    </p>
                    <p>
                        verkrijgt de toestemming voor de vaart op het riviergedeelte van Iffezheim
                        tot Spijksche Veer (artikel 7.05 van het reglement betreffende het
                        scheepvaartpersoneel op de Rijn).
                    </p>
                </section>
                <section className="right-section">
                    <CrewMemberImage imageBase64={idDataSet.imageBase64} />
                    <div className="details">
                        <p id="sup-right">
                            <span className="stamp-placeholder"></span>
                            <sup>7</sup>
                        </p>
                        <p>
                            <span>
                                in / à / te&nbsp;<sup>4</sup>
                            </span>
                            <strong className="padded-input-right">Basel</strong>
                        </p>
                        <p>
                            <span>
                                den / le / op&nbsp;<sup>5</sup>
                            </span>
                            <strong className="padded-input-right">
                                {writePublishDates(certificate, stretchesCertificates)}
                            </strong>
                        </p>
                    </div>
                </section>
            </div>
            <div id="lower-part">
                <section className="left-section">
                    {stretches.map((s, i) => (
                        <p key={i}>{writeRiverKilometersRow(s, i)}</p>
                    ))}
                </section>
                <section className="right-section">
                    <p>
                        <span>
                            <strong>Schweizerische Rheinhäfen</strong>&nbsp;
                            <sup>6</sup>
                        </span>
                    </p>
                    <p></p>
                    <p>
                        <span>__________________</span>
                        <span>__________________</span>
                        <sup>8</sup>
                    </p>
                    <p>
                        {idDataSet.signatureBase64 && (
                            <SignatureImage signatureBase64={idDataSet.signatureBase64} />
                        )}
                    </p>
                    <p>
                        Unterschrift des Inhabers / Signature du titulaire / Handtekenning van de
                        houder
                    </p>
                </section>
            </div>
            <div id="footer">
                <p id="border"></p>
                <p>
                    <span>1</span>Ausstellerstaat/Pays de délivrance de la patente/Land van afgifte
                    van het patent
                </p>
                <p>
                    <span>2</span>Nummer im Verzeichnis/Numéro du registre/Nummer van het register
                </p>
                <p>
                    <span>3</span>Bezeichnung des Schiffsführerzeugnisses gem. Anlage D5 mit
                    Nationalitätsbezeichnung z.B. „NL“/ Préciser le type de certificat de conduite
                    listé à l'annexe D5 et l' Etat de délivrance ex: „NL“/ Aanduiding van het type
                    vaarbewijs conform Bijlage D5 en van het land van afgifte b.v. "NL"
                </p>
                <p>
                    <span>4</span>Ort der Ausstellung/ lieu de délivrance/Plaats van het afgeving
                </p>
                <p>
                    <span>5</span>Datum der Ausstellung/ date de délivrance/Datum van het afgeving
                </p>
                <p>
                    <span>6</span>Bezeichnung der ausstellenden Behörde/Désignation de l'autorité
                    compétente/Aanduiding van de autoriteit
                </p>
                <p>
                    <span>7</span>Siegel der ausstellenden Behörde/Cachet de l'autorité délivrant
                    l'attestation/Stempel van de autoriteit
                </p>
                <p>
                    <span>8</span>Unterschrift der ausstellenden Behörde/ signature de l'autorité
                    délivrant l'attestation/Handtekening van de autoriteit
                </p>
            </div>
        </div>
    );
};

const writePublishDates = (certificate: ICertificate, certificates: ICertificate[]) => {
    if (certificate.stretchesRiverKilometers.length < 2)
        return formatDateForCertificateView(certificate.startDate);
    else {
        const result: string[] = [];
        const sorted = [...certificates].sort(
            (a, b) => new Date(a.added).getTime() - new Date(b.added).getTime()
        );
        //let indexCounter: number = 0;
        let numberOfCerts: number = certificate.stretchesRiverKilometers.length;
        sorted.forEach((c, i) => {
            if (i < numberOfCerts)
                result.push(`${i + 1}. ${formatDateForCertificateView(c.startDate)}`);
        });
        return result.join(" / ");
    }
};
