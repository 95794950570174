import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Message, Modal, Select, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { agent } from "../../api/agent";
import { DateInput } from "../../components/DateInput/DateInput";
import { TranslateProps } from "../../i18n/TranslateProps";
import {
    getTodayDate,
    deconstructDate,
    dateInit,
    constructDate,
    compareDates,
} from "../../models/common";
import { ISRB, SrbStatus, srbStatusOptions } from "../../models/srb";
import { AppState } from "../../store/configureStore";

type Props = {
    open: boolean;
    close: () => void;
    srb: ISRB;
    fetchCrewMember: () => Promise<void>;
};

export const ModifyCHSRBForm = ({ open, close, srb, fetchCrewMember }: Props) => {
    const intl = useIntl();
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(srb.status);
    const [reason, setReason] = useState(srb.reason ?? "");
    const [datesError, setDatesError] = useState(false);
    const [startDate, setStartDate] = useState(
        !srb.suspensionStartDate || srb.suspensionStartDate.includes("0001-")
            ? getTodayDate()
            : deconstructDate(srb.suspensionStartDate)
    );
    const [endDate, setEndDate] = useState(
        !srb.suspensionEndDate || srb.suspensionEndDate.includes("0001-")
            ? dateInit
            : deconstructDate(srb.suspensionEndDate)
    );

    useEffect(() => {
        if (endDate.year > 0 && startDate.year > 0 && !compareDates(startDate, endDate))
            setDatesError(true);
        else setDatesError(false);
    }, [endDate, startDate]);

    const handleModifySrb = async () => {
        setLoading(true);
        try {
            const dto: Partial<ISRB> = {
                status: status,
                suspensionStartDate: constructDate(startDate),
                suspensionEndDate: constructDate(endDate),
                reason: reason,
                guid: srb.guid,
            };
            await agent.ServiceRecordBooks.modify(dto);

            toast(intl.formatMessage({ id: "editedSRB" }), true);
            await fetchCrewMember();
        } catch {
            toast(intl.formatMessage({ id: "unableToEditSRB" }), false);
        } finally {
            setLoading(false);
            close();
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => close()}
            dimmer="blurring"
            size="small"
            className="modify-srb-modal"
        >
            <Modal.Header>{intl.formatMessage({ id: "modifySrb" })}</Modal.Header>
            <Modal.Content>
                <section className="input-container" id="topAddCertificate">
                    <label>{intl.formatMessage({ id: "srbStatus" })}</label>
                    <Select
                        search
                        fluid
                        options={TranslateProps(intl, srbStatusOptions)}
                        value={status}
                        onChange={(e, data: any) => setStatus(+data.value)}
                        disabled={user.role === UserRole.EnforcementAuthorityUser}
                    />
                </section>
                {(status === SrbStatus.Suspended || status === SrbStatus.Withdrawn) && (
                    <>
                        <form className="add-certificate-form">
                            <section className="input-container">
                                <label>
                                    {intl.formatMessage({ id: "startDate" })}
                                    <span className="input-required">*</span>
                                </label>
                                <DateInput date={startDate} setDate={setStartDate} extendedYears />
                            </section>
                            {status === SrbStatus.Suspended && (
                                <section className="input-container">
                                    <label>
                                        {intl.formatMessage({ id: "endDate" })}
                                        <span className="input-required">*</span>
                                    </label>
                                    <DateInput date={endDate} setDate={setEndDate} extendedYears />
                                </section>
                            )}
                        </form>
                        <section id="bottomAddCertificate" className="input-container">
                            <label>
                                {intl.formatMessage({ id: "comments" })}
                                <span className="input-required">*</span>
                            </label>
                            <TextArea
                                value={reason}
                                onChange={e => setReason(e.target.value)}
                                rows={5}
                            />
                        </section>
                        {status === SrbStatus.Suspended && datesError && (
                            <Message
                                className="error-message"
                                icon="exclamation circle"
                                content={intl.formatMessage({
                                    id: "datesCompareError",
                                })}
                            />
                        )}
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={handleModifySrb}
                    content={intl.formatMessage({ id: "saveChanges" })}
                    fluid
                    loading={loading}
                    disabled={
                        loading ||
                        (status === SrbStatus.Suspended && datesError) ||
                        (status === SrbStatus.Withdrawn && !reason) ||
                        (status === SrbStatus.Suspended && !reason) ||
                        (status === SrbStatus.Suspended && endDate.year < 0) ||
                        ((status === SrbStatus.Suspended || status === SrbStatus.Withdrawn) &&
                            startDate.year < 0)
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
