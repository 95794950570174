import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { INewUser, UserData } from "../actions/authentificationActions";
import { IAuthorization, ISsoSettings } from "../models/authorization";
import { ICertificate } from "../models/certificate";
import { IPaginationWrapper } from "../models/common";
import {
    ICrewMember,
    IEcdbIdentityResponse,
    IIDDataSet,
    IIDDataSetFilter,
} from "../models/crewMember";
import { IInboxMessage } from "../models/inboxMessage";
import { ISharepointSettings } from "../models/sharepointSettings";
import { ISRB } from "../models/srb";
import { IStatistics, IStatisticsResult } from "../models/statistics";
import { CountriesAndNationalitiesState } from "../store/nationalitiesReducer";

axios.defaults.baseURL = window.location.origin + "/api";

axios.interceptors.request.use(config => {
    const userData = sessionStorage.getItem("user");
    if (userData) {
        const user = JSON.parse(userData) as UserData;
        config.headers!.Authorization = "Bearer " + user.token;
    }
    return config;
});

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig<any> | undefined) =>
        axios.get<T>(url, config).then(responseBody),
    post: <T>(url: string, body: {}, config?: AxiosRequestConfig<any> | undefined) =>
        axios.post<T>(url, body, config).then(responseBody),
    patch: <T>(url: string, body: {}, config?: AxiosRequestConfig<any> | undefined) =>
        axios.patch<T>(url, body, config).then(responseBody),
    del: <T>(url: string, body?: {}) => axios.delete<T>(url, { data: body }).then(responseBody),
};

const handleFileDownload = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
};

const handleLoginError = (err: AxiosError) => {
    if (err.response?.status === 401) {
        sessionStorage.removeItem("user");
        sessionStorage.setItem("loggedIn", JSON.stringify(false));
        localStorage.setItem("language", "de");
    }

    if (err.response?.data) {
        return Promise.reject(err.response.data);
    }
};

const handleLoginResponse = async (res: AxiosResponse) => {
    const data = res.data;
    if (res.status !== 200) {
        if (res.status === 401) {
            sessionStorage.removeItem("user");
            sessionStorage.setItem("loggedIn", JSON.stringify(false));
            localStorage.setItem("language", "de");
        }
        if (res.status === 403) return Promise.reject("403");
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
    }
    return data || res.status === 200;
};

const buildFormData = (dto: IIDDataSet, image: File | undefined, signature: File | undefined) => {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(dto));
    image && formData.append("image", image, image.name);
    signature && formData.append("signature", signature, signature.name);
    return formData;
};

const Certificates = {
    create: (certificate: ICertificate) => requests.post<void>("/certificates", certificate),
    edit: (certificate: Partial<ICertificate>) =>
        requests.patch<void>(`/certificates/edit`, certificate),
    addAuth: (auth: IAuthorization) => requests.post<void>(`/certificates/auth`, auth),
    modify: (certificate: Partial<ICertificate>) =>
        requests.patch<void>(`/certificates/modify`, certificate),
    renew: (certificate: ICertificate) => requests.patch<void>(`/certificates/renew`, certificate),
    sendWithdrawalRequest: (qualId: string, reason: string) =>
        requests.patch<void>(`/certificates/withdraw`, {}, { params: { qualId, reason } }),
    downloadAsPdf: (dto: ICertificate, filename: string, isA4: boolean) =>
        requests
            .post<Blob>(`/certificates/download`, dto, {
                params: { isA4: isA4 },
                responseType: "blob",
            })
            .then(blob => handleFileDownload(blob, filename)),
    downloadAsCoverPage: (certificate: ICertificate, filename: string) =>
        requests
            .post<Blob>(`/certificates/download-cover`, certificate, {
                responseType: "blob",
            })
            .then(blob => handleFileDownload(blob, filename)),
    delete: (certificate: ICertificate) => requests.del<void>(`/certificates`, certificate),
};

const ChangeLogs = {
    get: (page: number, query: string) =>
        requests.get<IPaginationWrapper>("/changeLog", { params: { page: page, query: query } }),
    export: () =>
        requests.get<Blob>("/changeLog/download", { responseType: "blob" }).then(blob => {
            handleFileDownload(blob, "NCDB_ChangeLog.csv");
        }),
};

const CrewMembers = {
    getIdDataSets: (filter: IIDDataSetFilter, searchByCid: boolean) =>
        requests.get<IIDDataSet[]>("/crewMembers/query", {
            params: { ...filter, searchByCid: searchByCid },
        }),
    sendIdentityRequest: (filter: IIDDataSetFilter, searchByCid: boolean) =>
        requests.post<{ identityRequestId: string; detailRequestId: string }>(
            "/crewMembers/query/ecdb",
            {},
            {
                params: { ...filter, searchByCid: searchByCid },
            }
        ),
    getIdentityResponses: (requestId: string, current: string[]) =>
        requests.get<IEcdbIdentityResponse>("/crewMembers/query/ecdb/" + requestId, {
            params: { current: current },
        }),
    getByGuid: (guid: string) => requests.get<ICrewMember>("/crewMembers/" + guid),
    getByCid: (cid: string) => requests.get<ICrewMember>("/crewMembers/cid/" + cid),
    create: (
        dto: IIDDataSet,
        image: File | undefined,
        signature: File | undefined,
        copy: boolean
    ) =>
        requests.post<string>("/crewMembers", buildFormData(dto, image, signature), {
            params: { copy },
        }),
    connectWithEcdb: (guid: string) => requests.post<void>("/crewMembers/" + guid, {}),
    edit: (dto: IIDDataSet, image: File | undefined, signature: File | undefined) =>
        requests.patch<void>(
            "/crewMembers/" + dto.crewMemberGuid,
            buildFormData(dto, image, signature)
        ),
    delete: (guid: string) => requests.del<void>("/crewMembers/" + guid),
    sendDetailRequest: (cid: string) =>
        requests.post<string>("/crewMembers/detail/ecdb", {}, { params: { cid } }),
    getDetailResponse: (requestId: string) =>
        requests.get<ICrewMember>("/crewMembers/detail/ecdb/" + requestId),
    getWithoutCid: () => requests.get<IIDDataSet[]>("/crewMembers/local"),
    connectWithLocal: (dto: IIDDataSet, image: File | undefined) =>
        requests.patch<string>(
            `/crewMembers/${dto.crewMemberGuid}/local`,
            buildFormData(dto, image, undefined)
        ),
};

const Inbox = {
    get: (page: number) => requests.get<IPaginationWrapper>("/inbox", { params: { page: page } }),
    details: (guid: string) => requests.get<IInboxMessage>("/inbox/" + guid),
    process: (guid: string, status: number) =>
        requests.patch<void>("/inbox/" + guid, {}, { params: { status: status } }),
    getUnread: () => requests.get<number>("/inbox/unread"),
};

const ServiceRecordBooks = {
    create: (srb: ISRB) => requests.post<ISRB>("/servicerecordbook", srb),
    edit: (srb: ISRB) => requests.patch<void>("/servicerecordbook/" + srb.guid, srb),
    modify: (srb: Partial<ISRB>) => requests.patch<void>(`/servicerecordbook/modify`, srb),
    download: (guid: string, isCombined: boolean, filename: string) =>
        requests
            .get<Blob>(`/servicerecordbook/${guid}/download`, {
                params: { isCombined: isCombined },
                responseType: "blob",
            })
            .then(blob => handleFileDownload(blob, filename)),
    delete: (guid: string) => requests.del<void>("/servicerecordbook/" + guid),
    checkSerialNumber: (serial: string) =>
        requests.get<boolean>(`/servicerecordbook/checkserial/${serial}`),
    downloadQr: (guid: string, filename: string) =>
        requests
            .get<Blob>(`/servicerecordbook/${guid}/downloadQr`, {
                responseType: "blob",
            })
            .then(blob => handleFileDownload(blob, filename)),
};

const Statistics = {
    get: (startDate: string, endDate: string) =>
        requests.get<IStatisticsResult>("/statistics", {
            params: { startDate: startDate, endDate: endDate },
        }),
    getCurrentActiveCertificates: () => requests.get<IStatistics>("/statistics/active"),
    export: (startDate: string, endDate: string) =>
        requests
            .get<Blob>("/statistics/download", {
                params: { startDate: startDate, endDate: endDate },
                responseType: "blob",
            })
            .then(blob => {
                handleFileDownload(blob, "NCDB_Statistics.csv");
            }),
};

const Users = {
    login: (token: string) =>
        axios
            .post<AxiosResponse>("/users/authenticate", { token })
            .then(handleLoginResponse)
            .then(user => {
                if (user.token) {
                    sessionStorage.setItem("user", JSON.stringify(user));
                    sessionStorage.setItem("loggedIn", JSON.stringify(true));
                }

                return user;
            })
            .catch(handleLoginError),
    get: () => requests.get<UserData[]>("/users"),
    create: (userDto: INewUser) => requests.post<UserData>("/users", userDto),
    edit: (userDto: UserData) => requests.patch<void>("/users/" + userDto.guid, userDto),
    delete: (guid: string) => requests.del<void>("/users/" + guid),
    getCountriesAndNationalities: () =>
        requests.get<CountriesAndNationalitiesState>("/users/countries"),
    forgotPassword: (username: string) => requests.post<void>("/users/forgotPassword", username),
    checkPasswordResetToken: (token: string) =>
        requests.get<void>("/users/resetPassword", { params: { token: token } }),
    resetPassword: (resetPasswordDto: any) =>
        requests.post<void>("/users/resetPassword", resetPasswordDto),
    ssoSettings: () => requests.get<ISsoSettings>("/users/sso"),
};

const Sharepoint = {
    getSettings: () => requests.get<ISharepointSettings>("/sharepoint"),
};

const Authorizations = {
    downloadAsPdf: (guid: string, filename: string, boatmasterLicenceType?: number) =>
        requests
            .post<Blob>(
                "/certificates/downloadAuthorization",
                {},
                {
                    params: { guid: guid, boatmasterLicenceType: boatmasterLicenceType },
                    responseType: "blob",
                }
            )
            .then(blob => handleFileDownload(blob, filename)),
};

export const agent = {
    Certificates,
    ChangeLogs,
    CrewMembers,
    Inbox,
    ServiceRecordBooks,
    Statistics,
    Users,
    Sharepoint,
    Authorizations,
};
