import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Table, Popup, Icon, Dropdown } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { agent } from "../../api/agent";
import { TranslateProps } from "../../i18n/TranslateProps";
import {
    adnExamOptions,
    boatmasterLicenceOptions,
    CertificateStatus,
    certificateStatusOptions,
    CertificateType,
    certificateTypeOptions,
    downloadableCertificates,
    downloadableCoverPages,
    extensionBoatmasterOptions,
    highRhineLicenceOptions,
    ICertificate,
    rhineLicenceOptions,
    unionCertificates,
} from "../../models/certificate";
import { formatDate } from "../../models/common";
import { ICrewMember } from "../../models/crewMember";
import { AppState } from "../../store/configureStore";
import { CertificateFormModal } from "./CertificateForm/CertificateFormModal";
import { ModifyCertificateForm } from "./CertificateForm/ModifyCertificateForm";
import { ViewCertificateModal } from "./ViewCertificateModal";
import { IssuingAuthorityIndicator } from "../../components/IssuingAuthorityIndicator/IssuingAuthorityIndicator";
import { RequestFeedbackIndicator } from "../../components/RequestFeedbackIndicator/RequestFeedbackIndicator";
import {
    getBoatmasterMaxRiverRange,
    getStretchesMaxRiverRange as getStretchesRiverRanges,
    ucqRiverRangeProps,
} from "../../models/riverRanges";
import { DeleteCertificateModal } from "./DeleteCertificateModal";
import { AuthorizationType, IAuthorization } from "../../models/authorization";

enum DownloadType {
    Certificate,
    A4Certificate,
    CoverPage,
}

type Props = {
    certificate: ICertificate;
    fetchCrewMember: () => Promise<void>;
    dateOfBirth: string;
    crewMember: ICrewMember;
    renewable: boolean;
    certificates: ICertificate[];
    authorizations: IAuthorization[];
    qrBase64: string;
};

export const CertificatesRow = ({
    certificate,
    fetchCrewMember,
    dateOfBirth,
    crewMember,
    renewable,
    certificates,
    authorizations,
    qrBase64,
}: Props) => {
    const user = useSelector((state: AppState) => state.user);
    const intl = useIntl();
    const [modifyModalOpen, setModifyModalOpen] = useState(false);
    const [renewModalOpen, setRenewModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const subType = useMemo(() => {
        switch (certificate.type) {
            case CertificateType.BoatmasterOnTheRhine:
                return TranslateProps(intl, rhineLicenceOptions).find(
                    x => x.value === certificate.rhineLicenceType
                )?.text as string;
            case CertificateType.BoatmasterOnTheHighRhine:
                return TranslateProps(intl, highRhineLicenceOptions).find(
                    x => x.value === certificate.highRhineLicenceType
                )?.text as string;
            case CertificateType.AdnBescheinigung:
                return TranslateProps(intl, adnExamOptions).find(
                    x => x.value === certificate.adnExamType
                )?.text as string;
            case CertificateType.ExtensionBoatmasterOnTheRhine:
                return TranslateProps(intl, extensionBoatmasterOptions).find(
                    x => x.value === certificate.extensionBoatmasterType
                )?.text as string;
            case CertificateType.UcqBoatmaster:
                return TranslateProps(intl, boatmasterLicenceOptions).find(
                    x => x.value === certificate.boatmasterLicenceType
                )?.text as string;
            default:
                return "/";
        }
    }, [
        certificate.adnExamType,
        certificate.boatmasterLicenceType,
        certificate.extensionBoatmasterType,
        certificate.highRhineLicenceType,
        certificate.rhineLicenceType,
        certificate.type,
        intl,
    ]);
    const fileName = useMemo(() => {
        switch (certificate.type) {
            case CertificateType.UcqBoatmaster:
                return "UCQBoatmaster_" + crewMember.cid + ".pdf";
            case CertificateType.UcqLngExpert:
                return "UCQLNGExpert_" + crewMember.cid + ".pdf";
            case CertificateType.UcqPassengerNavigationExpert:
                return "UCQPassengerNavigationExpert_" + crewMember.cid + ".pdf";
            default:
                return "CH_" + certificate.qualificationID + ".pdf";
        }
    }, [certificate.qualificationID, certificate.type, crewMember.cid]);
    const editOrDeleteLimit = useMemo(
        () => new Date(certificate.added).setDate(new Date(certificate.added).getDate() + 1),
        [certificate.added]
    );
    const commentsAndReason = useMemo(() => {
        return (
            <div className="comments-reason-tooltip">
                {!!certificate.comments && (
                    <>
                        <p>{intl.formatMessage({ id: "comments" })}:</p>
                        <span>{certificate.comments}</span>
                    </>
                )}

                {!!certificate.reason && (
                    <>
                        <p>{intl.formatMessage({ id: "reason" })}:</p>
                        <span>{certificate.reason}</span>
                    </>
                )}
            </div>
        );
    }, [certificate.comments, certificate.reason, intl]);

    const handleDownload = async (downloadType: DownloadType) => {
        setLoading(true);
        try {
            if (downloadType === DownloadType.CoverPage) {
                await agent.Certificates.downloadAsCoverPage(
                    certificate,
                    "Personaldatei_" + certificate.qualificationID + ".pdf"
                );
            } else if (downloadType === DownloadType.Certificate) {
                await agent.Certificates.downloadAsPdf(certificate, fileName, false);
            } else {
                await agent.Certificates.downloadAsPdf(certificate, fileName, true);
            }
        } catch {
            toast(intl.formatMessage({ id: "failedToDownload" }), false);
        } finally {
            setLoading(false);
        }
    };

    const formatRanges = () => {
        if (certificate.type === CertificateType.StretchesWithSpecificRisks) {
            const ranges = getStretchesRiverRanges(certificate);
            if (ranges.length > 1) {
                return (
                    <span>
                        {intl.formatMessage({ id: "multiple" })}
                        <Popup
                            content={ranges.map(r => (
                                <p key={r}>{r}</p>
                            ))}
                            position="top center"
                            flowing
                            trigger={<Icon name="info circle" size="large" />}
                            hoverable
                        />
                    </span>
                );
            } else if (ranges.length === 1) {
                return <span>{ranges[0]}</span>;
            } else {
                return "";
            }
        } else if (certificate.type === CertificateType.HighRhineCertificate) {
            return <span>km 155.85 - km 155.50</span>;
        } else if (certificate.type === CertificateType.UcqBoatmaster) {
            // get all enum stretches from authorizations
            const stretches = authorizations
                .filter(a => a.linkedQualificationID === certificate.qualificationID)
                .filter(a => a.type === AuthorizationType.S)
                .filter(a => a.ucqRiverKilometers.length > 0)
                .reduce((urk, auth) => {
                    auth.ucqRiverKilometers.forEach(s => {
                        urk.push(
                            `${
                                ucqRiverRangeProps.find(r => r.key === s)?.text?.toString() ?? ""
                            } - ${auth.qualificationID.slice(-7)}`
                        );
                    });
                    return urk;
                }, [] as string[])
                .sort((a, b) => a.localeCompare(b));
            // get all manual stretches from authorizations
            const manualStretches = authorizations
                .filter(a => a.linkedQualificationID === certificate.qualificationID)
                .filter(a => a.type === AuthorizationType.S)
                .filter(a => a.ucqManualRiverKilometers.length > 0)
                .reduce((urk, auth) => {
                    auth.ucqManualRiverKilometers
                        .split(";")
                        // remove last empty string
                        .filter(s => s !== "")
                        .forEach(s => {
                            urk.push(`${s} - ${auth.qualificationID.slice(-7)}`);
                        });
                    return urk;
                }, [] as string[]);
            // merge enum stretches and manual stretches
            stretches.push(...manualStretches);

            // show multiple stretches with info icon, show single stretch in line, show slash if no stretches
            if (stretches.length > 1) {
                return (
                    <span>
                        {intl.formatMessage({ id: "multiple" })}
                        <Popup
                            content={stretches.map(r => (
                                <p key={r}>{r}</p>
                            ))}
                            position="top center"
                            flowing
                            trigger={<Icon name="info circle" size="large" />}
                            hoverable
                        />
                    </span>
                );
            } else if (stretches.length === 1) {
                return <span>{stretches[0]}</span>;
            } else {
                return "/";
            }
        } else {
            return <span>{getBoatmasterMaxRiverRange(certificate)}</span>;
        }
    };

    return (
        <>
            <Table.Row>
                <Table.Cell>
                    <IssuingAuthorityIndicator issuingAuthority={certificate.issuingAuthority} />
                </Table.Cell>
                <Table.Cell>
                    {
                        TranslateProps(
                            intl,
                            certificateTypeOptions,
                            false,
                            certificate.type === 1 && certificate.qualificationID.includes("QEU")
                        )?.find(x => x.value === certificate.type)?.text as string
                    }
                </Table.Cell>
                <Table.Cell>{subType}</Table.Cell>
                <Table.Cell>{formatRanges()}</Table.Cell>
                <Table.Cell>{certificate.qualificationID}</Table.Cell>
                <Table.Cell>
                    {certificate.endDate.includes("0001-") ? "/" : formatDate(certificate.endDate)}
                </Table.Cell>
                <Table.Cell singleLine>
                    {
                        TranslateProps(intl, certificateStatusOptions).find(
                            x => x.value === certificate.status
                        )?.text
                    }
                    {(!!certificate.comments || !!certificate.reason) && (
                        <Popup
                            content={commentsAndReason}
                            position="top center"
                            wide
                            trigger={<Icon name="info circle" size="large" />}
                            hoverable
                        />
                    )}
                </Table.Cell>
                <Table.Cell collapsing>
                    <Dropdown
                        id="certificates-options"
                        floating
                        text={intl.formatMessage({ id: "options" })}
                        loading={loading}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={intl.formatMessage({ id: "view" })}
                                onClick={() => setViewModalOpen(true)}
                            />
                            {user.role !== UserRole.EnforcementAuthorityUser &&
                                new Date().getTime() < editOrDeleteLimit &&
                                certificate.issuingAuthority.includes("CH") &&
                                !certificate.ecdbAcknowledged && (
                                    <>
                                        <Dropdown.Item
                                            text={intl.formatMessage({ id: "edit" })}
                                            onClick={() => setEditModalOpen(true)}
                                        />
                                        <Dropdown.Item
                                            text={intl.formatMessage({ id: "delete" })}
                                            onClick={() => setDeleteModalOpen(true)}
                                        />
                                    </>
                                )}
                            {user.role === UserRole.EnforcementAuthorityUser &&
                            (certificate.status === CertificateStatus.Active ||
                                certificate.status === CertificateStatus.Suspended) ? (
                                <Dropdown.Item
                                    text={intl.formatMessage({ id: "suspend" })}
                                    onClick={() => setModifyModalOpen(true)}
                                />
                            ) : (
                                user.role !== UserRole.EnforcementAuthorityUser && (
                                    <Dropdown.Item
                                        text={intl.formatMessage({ id: "modify" })}
                                        onClick={() => setModifyModalOpen(true)}
                                    />
                                )
                            )}
                            {user.role !== UserRole.EnforcementAuthorityUser &&
                                // do not show renew button for boatmaster on the rhine
                                certificate.type !== CertificateType.BoatmasterOnTheRhine && (
                                    <Dropdown.Item
                                        text={intl.formatMessage({ id: "renew" })}
                                        onClick={() => setRenewModalOpen(true)}
                                        disabled={
                                            !renewable ||
                                            certificate.issuingAuthority !== user.authority ||
                                            certificate.status === CertificateStatus.Suspended ||
                                            certificate.status === CertificateStatus.Withdrawn
                                        }
                                    />
                                )}
                            {unionCertificates.includes(certificate.type) && !crewMember.cid ? (
                                <Popup
                                    content={intl.formatMessage({ id: "cidNotAssigned" })}
                                    position="top center"
                                    trigger={
                                        <Dropdown.Item
                                            text={`${intl.formatMessage({
                                                id: "download",
                                            })} (${intl.formatMessage({ id: "certificate" })})`}
                                            disabled={
                                                user.role === UserRole.EnforcementAuthorityUser ||
                                                !downloadableCertificates.includes(
                                                    certificate.type
                                                ) ||
                                                loading ||
                                                certificate.issuingAuthority !== user.authority
                                            }
                                        />
                                    }
                                />
                            ) : (
                                <>
                                    {certificate.type !== CertificateType.BoatmasterOnTheRhine &&
                                        certificate.type !==
                                            CertificateType.BoatmasterOnTheHighRhine && (
                                            <Dropdown.Item
                                                text={`${intl.formatMessage({
                                                    id: "download",
                                                })} (${intl.formatMessage({
                                                    id: "certificate",
                                                })} PDF)`}
                                                disabled={
                                                    user.role ===
                                                        UserRole.EnforcementAuthorityUser ||
                                                    !downloadableCertificates.includes(
                                                        certificate.type
                                                    ) ||
                                                    loading ||
                                                    certificate.issuingAuthority !== user.authority
                                                }
                                                onClick={() =>
                                                    handleDownload(DownloadType.A4Certificate)
                                                }
                                            />
                                        )}
                                    {(certificate.type === CertificateType.BoatmasterOnTheRhine ||
                                        certificate.type ===
                                            CertificateType.BoatmasterOnTheHighRhine ||
                                        (certificate.type === CertificateType.UcqBoatmaster &&
                                            certificate.issuingAuthority === "CH01")) && (
                                        <Dropdown.Item
                                            text={`${intl.formatMessage({
                                                id: "download",
                                            })} (${intl.formatMessage({ id: "card" })} PDF)`}
                                            disabled={
                                                user.role === UserRole.EnforcementAuthorityUser ||
                                                !downloadableCertificates.includes(
                                                    certificate.type
                                                ) ||
                                                loading ||
                                                certificate.issuingAuthority !== user.authority
                                            }
                                            onClick={() => handleDownload(DownloadType.Certificate)}
                                        />
                                    )}
                                </>
                            )}
                            {downloadableCoverPages.includes(certificate.type) && (
                                <Dropdown.Item
                                    text={`${intl.formatMessage({
                                        id: "download",
                                    })} (${intl.formatMessage({ id: "coverPage" })})`}
                                    disabled={
                                        user.role === UserRole.EnforcementAuthorityUser ||
                                        loading ||
                                        certificate.issuingAuthority !== user.authority
                                    }
                                    onClick={() => handleDownload(DownloadType.CoverPage)}
                                />
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    {certificate.type <= CertificateType.UcqPassengerNavigationExpert &&
                    certificate.requestFeedbacks.length > 0 ? (
                        <RequestFeedbackIndicator feedbacks={certificate.requestFeedbacks} />
                    ) : certificate.type <= CertificateType.UcqPassengerNavigationExpert &&
                      certificate.ecdbAcknowledged ? (
                        <Icon name="check circle" size="big" />
                    ) : null}
                </Table.Cell>
            </Table.Row>
            {editModalOpen && (
                <CertificateFormModal
                    open={editModalOpen}
                    close={() => setEditModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    dateOfBirth={dateOfBirth}
                    certificates={certificates}
                    certificate={certificate}
                    crewMember={crewMember}
                    edit={true}
                />
            )}
            {renewModalOpen && (
                <CertificateFormModal
                    open={renewModalOpen}
                    close={() => setRenewModalOpen(false)}
                    fetchCrewMember={fetchCrewMember}
                    dateOfBirth={dateOfBirth}
                    certificates={certificates}
                    certificate={certificate}
                    crewMember={crewMember}
                    edit={false}
                />
            )}
            {modifyModalOpen && (
                <ModifyCertificateForm
                    open={modifyModalOpen}
                    close={() => setModifyModalOpen(false)}
                    certificate={certificate}
                    fetchCrewMember={fetchCrewMember}
                />
            )}
            {viewModalOpen && (
                <ViewCertificateModal
                    open={viewModalOpen}
                    close={() => setViewModalOpen(false)}
                    certificate={certificate}
                    idDataSet={crewMember.idDataSets[0]}
                    stretchesCertificates={certificates.filter(
                        s => s.type === CertificateType.StretchesWithSpecificRisks
                    )}
                    radarCertificate={certificates.find(
                        c => c.type === CertificateType.Radarpatent
                    )}
                    qrBase64={qrBase64}
                    imageBase64={crewMember.idDataSets[0].imageBase64}
                />
            )}
            {deleteModalOpen && (
                <DeleteCertificateModal
                    open={deleteModalOpen}
                    closeModal={() => setDeleteModalOpen(false)}
                    certificate={certificate}
                    fetchCrewMember={fetchCrewMember}
                />
            )}
        </>
    );
};
