import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { CSSTransition } from "react-transition-group";
import { Message } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IInboxMessage } from "../../models/inboxMessage";
import { agent } from "../../api/agent";
import { AppState } from "../../store/configureStore";
import { InboxTable } from "./InboxTable";
import "./Inbox.css";

export const Inbox = () => {
    const intl = useIntl();
    const loc = useLocation();
    const user = useSelector((state: AppState) => state.user);
    const [inboxMessages, setInboxMessages] = useState<IInboxMessage[]>([]);
    const [totalRows, setTotalRows] = useState(-1);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);

    const fetchInboxMessages = useCallback(async () => {
        try {
            setInboxMessages([]);
            setTotalRows(-1);
            await new Promise(r => setTimeout(r, 100));
            const res = await agent.Inbox.get(page);
            setInboxMessages(res.result);
            setTotalRows(res.totalRows);
        } catch {
            toast("Unable to fetch inbox messages", false);
        }
    }, [page]);

    useEffect(() => {
        fetchInboxMessages();
    }, [fetchInboxMessages]);

    return user.role !== UserRole.PowerUser && user.role !== UserRole.OfficeUser ? (
        <Navigate to="/unauthorized" />
    ) : (
        <div className="inbox-container">
            <h1>{intl.formatMessage({ id: "inbox" })}</h1>
            <CSSTransition in={totalRows > -1} timeout={100} classNames="fade" unmountOnExit>
                {totalRows > 0 ? (
                    <InboxTable
                        inboxMessages={inboxMessages}
                        page={page}
                        setPage={setPage}
                        totalRows={totalRows}
                    />
                ) : (
                    <Message icon="info circle" header={intl.formatMessage({ id: "noMessages" })} />
                )}
            </CSSTransition>
        </div>
    );
};
