import { ICertificate } from "../../../models/certificate";
import { formatDateForCertificateView, Gender } from "../../../models/common";
import { IIDDataSet } from "../../../models/crewMember";
import { CrewMemberImage } from "../../CrewMember/CrewMemberImage";
import { SignatureImage } from "../../CrewMember/SignatureImage";

type Props = {
    certificate: ICertificate;
    idDataSet: IIDDataSet;
    renew?: boolean;
};

export const RadarpatentView = ({ certificate, idDataSet, renew }: Props) => {
    return (
        <div id="ch-certificate-view" className="radar-view">
            <h4>Radarpatent</h4>
            <div id="upper-part">
                <section className="left-section">
                    <p id="swiss-title">
                        <strong>Schweiz</strong>
                    </p>
                    <span>
                        Ausstellerstaat/Pays de délivrance de la patente/Land van afgifte van het
                        patent.
                    </span>
                </section>
                <section id="radarpatent-id" className="right-section">
                    <p id="doc-number">
                        <strong>Nr./n°</strong>
                        <span>
                            <strong>{renew ? "" : certificate.qualificationID}</strong>
                        </span>
                        <sup>1</sup>
                    </p>
                </section>
            </div>
            <h4>Radarpatent / Patente radar / Radarpatent</h4>
            <div id="middle-part">
                <section className="left-section">
                    {idDataSet.gender !== Gender.Female && <p>Herr/Monsieur/De heer</p>}
                    {idDataSet.gender !== Gender.Male && <p>Frau/Madame/Mevrouw</p>}
                    <p>
                        <strong>
                            {!!idDataSet.nonLatinFirstName
                                ? idDataSet.nonLatinFirstName
                                : idDataSet.firstName}
                            &nbsp;
                            {!!idDataSet.nonLatinLastName
                                ? idDataSet.nonLatinLastName
                                : idDataSet.lastName}
                        </strong>
                        <label>(Vor- und Familienname / prénom et nom / voornaam en naam)</label>
                    </p>
                    <p>
                        geboren am / né(e) le / geboren op
                        <strong className="padded-input">
                            {formatDateForCertificateView(idDataSet.dateOfBirth)}
                        </strong>
                    </p>
                    <p>
                        in / à / te
                        <strong className="padded-input">
                            {!!idDataSet.nonLatinPlaceOfBirth
                                ? idDataSet.nonLatinPlaceOfBirth
                                : idDataSet.placeOfBirth}
                        </strong>
                    </p>
                    <p>ist berechtigt eine Radaranlage zum Führen eines Fahrzeuges zu benutzen.</p>
                    <p>
                        est autorisé(e) à utiliser une installation de radar pour conduire un
                        bateau.
                    </p>
                    <p>
                        heeft recht op het gebruik van een radarinstallatie voor het voeren van een
                        schip.
                    </p>
                </section>
                <section className="right-section">
                    <CrewMemberImage imageBase64={idDataSet.imageBase64} />
                    <div className="details">
                        <p>
                            <span>
                                in / à / te&nbsp;<sup>2</sup>
                            </span>
                            <strong className="padded-input">Basel</strong>
                        </p>
                        <p>
                            <span>
                                den / le / op&nbsp;<sup>3</sup>
                            </span>
                            <strong className="padded-input">
                                {formatDateForCertificateView(certificate.startDate)}
                            </strong>
                        </p>
                    </div>
                </section>
            </div>
            <div id="lower-part">
                <section className="left-section"></section>
                <section className="right-section">
                    <p>
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <span>
                                <strong>Schweizerische Rheinhäfen</strong>&nbsp;
                                <sup>4</sup>
                            </span>
                            <span className="stamp-placeholder"></span>
                        </span>
                        <sup>5</sup>
                    </p>
                    <br />
                    <p>
                        _________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_________________
                        <sup>6</sup>
                    </p>
                    <br />
                    <p>
                        {idDataSet.signatureBase64 && (
                            <SignatureImage signatureBase64={idDataSet.signatureBase64} />
                        )}
                    </p>
                    <p>
                        Unterschrift des Inhabers / Signature du titulaire / Handtekenning van de
                        houder
                    </p>
                </section>
            </div>
            <div id="footer">
                <p id="border"></p>
                <p>
                    <span>1</span>Nummer im Verzeichnis/ Numéro du registre/ Nummer van het register
                </p>
                <p>
                    <span>2</span>Ausstellungsort des Patentes/ Lieu de délivrance de la patente/
                    Plaats van afgifte van het patent
                </p>
                <p>
                    <span>3</span>Ausstellungsdatum des Patentes/ Date de délivrance de la patente/
                    Datum van afgifte van het patent
                </p>
                <p>
                    <span>4</span>Bezeichnung der ausstellenden Behörde/ Désignation de l'autorité
                    compétente/ Aanduiding van de autoriteit
                </p>
                <p>
                    <span>5</span>Siegel der ausstellenden Behörde/ Cachet de l'autorité délivrant
                    la patente/ Stempel van de autoriteit
                </p>
                <p>
                    <span>6</span>Unterschrift der ausstellenden Behörde/Signature de l'autorité
                    délivrant la patente/Handtekening van de autoriteit
                </p>
            </div>
        </div>
    );
};
