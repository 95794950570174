import { DropdownItemProps } from "semantic-ui-react";
import { CertificateStatus } from "./certificate";
import { IRequestFeedback } from "./requestFeedback";

export interface IAuthorization {
    guid: string;
    added: string;
    lastUpdated: string;
    qualificationID: string;
    linkedQualificationID: string;
    type: AuthorizationType;
    status: CertificateStatus;
    startDate: string;
    endDate: string;
    issuingAuthority: string;
    ucqRiverKilometers: number[];
    ucqManualRiverKilometers: string;
    linkedCertificateGuid: string;
    crewMemberGuid: string;
    requestFeedbacks: IRequestFeedback[];
    ecdbAcknowledged: boolean;
    certificateGuid: string;
}

export const newAuthorizationInit: IAuthorization = {
    guid: "",
    added: "",
    lastUpdated: "",
    qualificationID: "",
    type: -1,
    linkedQualificationID: "",
    status: -1,
    startDate: "",
    endDate: "",
    issuingAuthority: "",
    ucqRiverKilometers: [],
    ucqManualRiverKilometers: "",
    linkedCertificateGuid: "",
    crewMemberGuid: "",
    requestFeedbacks: [],
    ecdbAcknowledged: false,
    certificateGuid: "",
};

export interface IAuthorizationProps extends DropdownItemProps {
    code: string;
}

export enum AuthorizationType {
    Unknown = -1,
    R, // Sailing in conditions of reduced visibility (with the aid of radar)
    M, // Sailing on inland waterways with a maritime character
    C, // Sailing large convoys
    S, // Sailing on (stretches of) waterways that present specific risks for navigation
}

export interface ISsoSettings {
    clientId: string;
    tenantId: string;
}

export const authorizationTypeOptions: IAuthorizationProps[] = [
    {
        key: AuthorizationType.R,
        text: "sailingReducedVisibility",
        value: AuthorizationType.R,
        code: "R",
    },
    {
        key: AuthorizationType.M,
        text: "sailingInlandWaters",
        value: AuthorizationType.M,
        code: "M",
    },
    {
        key: AuthorizationType.C,
        text: "sailingLargeConvoys",
        value: AuthorizationType.C,
        code: "C",
    },
    {
        key: AuthorizationType.S,
        text: "sailingStretches",
        value: AuthorizationType.S,
        code: "S",
    },
];
